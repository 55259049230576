import { StarFilled } from '@ant-design/icons'
import CustomToolTip from 'src/components/CustomToolTip'
import styles from '../solarsystemslistingsv2.module.scss'
import PropTypes from 'prop-types'

const AfterCareRating = ({ score }) => {
    return (
        <>
            {score ? (
                <CustomToolTip
                    text="The aftercare rating of this system provide solid local support and are committed to providing quick response times"
                    toolTipPopupWidth="180px"
                    toolTipFontSize="12px"
                    lineHeight="18px"
                    toolTipMarginLeft="-118px"
                >
                    <div className={styles.SolarSystemAfterCare}>
                        <span>{score}</span>
                        <StarFilled />
                    </div>
                </CustomToolTip>
            ) : null}
        </>
    )
}

AfterCareRating.propTypes = {
    score: PropTypes.string,
}

export default AfterCareRating
