import { useEffect } from 'react'
import { Provider } from 'react-redux'
import AppContextProvider from '../appContext/AppContext'
import { store } from 'src/redux/store'
import Routes from '../routes'
import hotjar from 'src/utils/hotjar'
import gtmUtils from 'src/utils/gtmUtils'
import gtagUtils from 'src/utils/gtagUtils'
import { Toaster } from 'react-hot-toast'
import CustomerChat from 'src/components/CustomerChat'
import { npsInitialization } from 'src/utils/handleNPSValues'
import useAutoLogout from 'src/utils/Hooks/useAutoLogout'

const App = () => {
    useEffect(() => {
        hotjar()
    }, [])

    useEffect(() => {
        gtmUtils()
    }, [])

    useEffect(() => {
        gtagUtils()
    }, [])

    // nps rating display tracking
    useEffect(() => {
        npsInitialization()
    }, [])

    useAutoLogout()

    return (
        <Provider store={store}>
            <AppContextProvider>
                <Routes />
            </AppContextProvider>
            {<CustomerChat />}
            <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
        </Provider>
    )
}

export default App
