import { useEffect, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import cloneDeep from 'lodash.clonedeep'
import { validateCreditVariablesSchema } from 'src/utils/validationSchema'
import { errorHandler } from 'src/utils/errorHandler'
import { handleBulkUpdateCreditVariablesApi } from 'src/api/admin/credit-variables/listing'
import { reverseMapPaymentModel } from 'src/utils/Data/paymentModel'

const useEditAndPreviewModal = (
    closeModal,
    editAndPreviewModalAction,
    setEditAndPreviewModalAction,
    handleSuccess,
    editVariablesMethod,
    editVariablesInputData,
) => {
    const originalInputDataRef = useRef(cloneDeep(editVariablesInputData))
    const [inputs, setInputs] = useState(cloneDeep(editVariablesInputData))
    const [isOpen, setIsOpen] = useState(Array(inputs?.length).fill(false))
    const [errors, setErrors] = useState({})
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [toast, setToast] = useState(toastData)
    const queryClient = useQueryClient()

    const onCancel = () => {
        setEditAndPreviewModalAction('edit')
        closeModal()
    }

    const resetToast = () => {
        setTimeout(() => {
            setToast(toastData)
        }, 2000)
    }
    const titles = ['Variables', 'Payment Model', 'Lowest Interest']

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }

    const handleBack = () => {
        setEditAndPreviewModalAction('edit')
    }

    const formatInputValue = (name, value) => {
        const formatters = {
            paymentModel: value => value,
            debtCost: formatNumberInput,
            insuranceRate: formatNumberInput,
            minimumUpfrontDeposit: formatNumberInput,
            debtToEquityInProject: formatNumberInput,
            lowestTenure: formatIntegerInput,
            monthlyRateJump: formatNumberInput,
            startDecliningBalanceRate: formatNumberInput,
            paymentPlans: formatIntegerInput,
            costOfCapital: formatNumberInput,
            reInvestmentRate: formatNumberInput,
            tenureIncrement: formatNumberInput,
            debtMonths: formatIntegerInput,
        }

        const formatter = formatters[name]
        return formatter ? formatter(value) : value
    }

    const formatNumberInput = value => {
        // allow numbers and decimal points
        return value.replace(/[^\d.]/g, '')
    }

    const formatIntegerInput = value => {
        // allow only digits
        return value.replace(/[^\d]/g, '')
    }

    const removeErrors = (itemIndex, name) => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            if (newErrors[itemIndex]) {
                delete newErrors[itemIndex][name]
                if (Object.keys(newErrors[itemIndex]).length === 0) {
                    delete newErrors[itemIndex]
                }
            }
            return newErrors
        })
    }

    const handleChange = (e, itemIndex, detailIndex) => {
        const { name, value } = e.target

        setInputs(prevInputs => {
            const newInputs = [...prevInputs]
            const formattedValue = formatInputValue(name, value)
            newInputs[itemIndex].details[detailIndex].value = formattedValue
            return newInputs
        })

        removeErrors(itemIndex, name)
    }

    const handleOnBlur = async (e, itemIndex) => {
        const { name } = e.target

        const item = inputs[itemIndex]
        const itemValues = {}
        item.details.forEach(detail => {
            itemValues[detail.name] = detail.value
        })

        await validateField({
            name,
            schema: validateCreditVariablesSchema,
            setErrors,
            itemValues,
            itemIndex,
        })
    }

    const handleSelectOnBlur = async (name, itemIndex) => {
        const item = inputs[itemIndex]
        const itemValues = {}
        item.details.forEach(detail => {
            itemValues[detail.name] = detail.value
        })

        await validateField({
            name,
            schema: validateCreditVariablesSchema,
            setErrors,
            itemValues,
            itemIndex,
        })
    }

    const handleSelectChange = (name, value, itemIndex, detailIndex) => {
        setInputs(prevInputs => {
            const newInputs = [...prevInputs]
            const formattedValue = formatInputValue(name, value)
            newInputs[itemIndex].details[detailIndex].value = formattedValue
            return newInputs
        })

        removeErrors(itemIndex, name)
    }

    const validateField = async ({
        name,
        schema,
        setErrors,
        itemValues,
        itemIndex,
    }) => {
        try {
            await schema.validateAt(name, itemValues)
            // remove errors if passed
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors }
                if (newErrors[itemIndex]) {
                    delete newErrors[itemIndex][name]
                    if (Object.keys(newErrors[itemIndex]).length === 0) {
                        delete newErrors[itemIndex]
                    }
                }
                return newErrors
            })
        } catch (err) {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors }
                if (!newErrors[itemIndex]) {
                    newErrors[itemIndex] = {}
                }
                newErrors[itemIndex][name] = err.message
                return newErrors
            })
        }
    }

    const fieldMapping = {
        paymentModel: 'payment_model',
        debtCost: 'debt_cost',
        insuranceRate: 'insurance',
        minimumUpfrontDeposit: 'upfront_deposit',
        debtToEquityInProject: 'debt_to_equity',
        lowestTenure: 'lowest_tenure',
        monthlyRateJump: 'monthly_interest_jump',
        startDecliningBalanceRate: 'lowest_tenure_annual_interest',
        paymentPlans: 'payment_plans_count',
        costOfCapital: 'cost_of_capital',
        reInvestmentRate: 'reinvestment_rate',
        tenureIncrement: 'tenure_increment',
        debtMonths: 'debt_months',
    }

    const buildPayload = () => {
        return inputs
            .map((item, index) => {
                const originalItem = originalInputDataRef.current[index]
                const changes = {}

                item.details.forEach(detail => {
                    const fieldName = detail.name
                    let newValue = detail.value
                    const originalDetail = originalItem.details.find(
                        d => d.name === fieldName,
                    )
                    const originalValue = originalDetail
                        ? originalDetail.value
                        : undefined

                    if (
                        newValue !== originalValue ||
                        editVariablesMethod === 'one-change-to-multiple'
                    ) {
                        const apiFieldName = fieldMapping[fieldName]

                        if (apiFieldName) {
                            if (fieldName === 'paymentModel') {
                                newValue = reverseMapPaymentModel(newValue)
                            }

                            // convert values to appropriate types
                            if (
                                [
                                    'debtCost',
                                    'insuranceRate',
                                    'minimumUpfrontDeposit',
                                    'debtToEquityInProject',
                                    'monthlyRateJump',
                                    'startDecliningBalanceRate',
                                    'costOfCapital',
                                    'reInvestmentRate',
                                    'tenureIncrement',
                                ].includes(fieldName)
                            ) {
                                newValue = parseFloat(newValue)
                            } else if (
                                [
                                    'lowestTenure',
                                    'paymentPlans',
                                    'debtMonths',
                                ].includes(fieldName)
                            ) {
                                newValue = parseInt(newValue, 10)
                            }

                            changes[apiFieldName] = newValue
                        }
                    }
                })

                changes.id = item.id

                if (item.provider_id) {
                    changes.provider_id = item.provider_id
                } else if (item.provider && item.provider.id) {
                    changes.provider_id = item.provider.id
                }

                if (Object.keys(changes).length > 1) {
                    return changes
                } else {
                    return null
                }
            })
            .filter(item => item !== null)
    }

    const handleContinue = async () => {
        if (editAndPreviewModalAction === 'edit') {
            let allValid = true
            let allErrors = {}

            await Promise.all(
                inputs.map(async (item, itemIndex) => {
                    const itemValues = {}
                    item.details.forEach(detail => {
                        itemValues[detail.name] = detail.value
                    })
                    try {
                        await validateCreditVariablesSchema.validate(
                            itemValues,
                            { abortEarly: false },
                        )
                    } catch (err) {
                        allValid = false
                        let errList = {}
                        err.inner.forEach(e => {
                            errList[e.path] = e.message
                        })
                        allErrors[itemIndex] = errList
                    }
                }),
            )

            if (allValid) {
                setEditAndPreviewModalAction('preview')
            } else {
                setErrors(allErrors)
                setToast({
                    showToast: true,
                    toastMessage:
                        'Please complete all required fields before proceeding.',
                    messageType: 'error-secondary',
                })
                resetToast()
            }
        } else {
            const payload = buildPayload()

            if (payload.length > 0) {
                updateVariablesMutate(payload)
            } else {
                setToast({
                    showToast: true,
                    toastMessage: 'No changes detected to update.',
                    messageType: 'success-secondary',
                })
                resetToast()
            }
        }
    }

    const { mutate: updateVariablesMutate, isLoading } = useMutation({
        mutationKey: ['bulk-update-credit-variables'],
        mutationFn: payload => handleBulkUpdateCreditVariablesApi(payload),
        onSuccess: () => {
            handleSuccess()
            queryClient.invalidateQueries('admin-credit-variables-list')
        },
        onError: error => {
            setToast({
                showToast: true,
                toastMessage: errorHandler(
                    error?.response?.data?.message ||
                        'An error occurred, please try again later.',
                ),
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    useEffect(() => {
        setInputs(cloneDeep(editVariablesInputData))
        originalInputDataRef.current = cloneDeep(editVariablesInputData)
    }, [editVariablesInputData])

    useEffect(() => {
        setIsOpen(Array(inputs?.length).fill(false))
    }, [inputs?.length])

    return {
        onCancel,
        titles,
        isOpen,
        toggleBox,
        handleBack,
        handleContinue,
        inputs,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
        toast,
        updateVariablesLoading: isLoading,
    }
}

export default useEditAndPreviewModal
