import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import s from '../styles.module.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'
import { SelectButtons } from 'src/components/SelectButtons'
import {
    registeredBusinessOptions,
    registeredBusinessOptionsDynamicKYC,
} from '../../../utils'
import { businessIndustry } from 'src/utils/business'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import UploadField from 'src/components/InputFields/UploadField'
import UploadModal from 'src/components/UploadModal'
import RemoveFileModal from '../../../../_components/RemoveFileModal'
import { memorandumOfAssociationFileTypes } from 'src/utils/supportedFileTypes'
import { extractFileNameFromUrl } from 'src/utils/formatting'

const BusinessDetails = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    isAdminUser,
    handleBusinessRegisteredChange,
    toggleShowCacVerifyModal,
    cacVerificationInProgress,
    isCustomerApproved,
    page,
    useType,
    showUploadModal,
    handleCloseUploadModal,
    showRemoveFileModal,
    fileToBeRemoved,
    closeRemoveFileModal,
    closeRemoveFileModalAfterSuccess,
    moa,
    setMoa,
    successMOAUpload,
    setSuccessMOAUpload,
    setShowUploadModal,
    handleRemoveFile,
}) => {
    const { isMobile } = useMediaQueries()
    const isOnePageDynamicKyc = page === 'one-page-dynamic-kyc'
    const fieldMarginBottom = isOnePageDynamicKyc ? '16px' : '20px'
    const validRegisteredBusinessOptions = isOnePageDynamicKyc
        ? registeredBusinessOptionsDynamicKYC
        : registeredBusinessOptions
    const isBusinessUseType = isOnePageDynamicKyc && useType === 'BUSINESS'
    const disableFieldForAdmins = isAdminUser && page !== 'one-page-dynamic-kyc'

    return (
        <>
            <UploadModal
                showModal={showUploadModal}
                onCancel={handleCloseUploadModal}
                title={'Memorandum of association'}
                primaryBtnText={'Confirm'}
                successUpload={successMOAUpload}
                setSuccessUpload={setSuccessMOAUpload}
                acceptableFileTypes={memorandumOfAssociationFileTypes}
                setFileToState={setMoa}
            />

            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />

            {/* form items */}
            {!isOnePageDynamicKyc && (
                <Label
                    title={'Employment details'}
                    subTitle={'Provide us with more details about your work'}
                />
            )}

            {!isBusinessUseType && (
                <div className="ContactAddress_Confirmation-Wrapper">
                    <p>Is your business registered?</p>
                    <SelectButtons
                        selectedValue={inputs.registeredBusiness.title}
                        values={validRegisteredBusinessOptions?.map(
                            o => o.title,
                        )}
                        handleChange={value =>
                            handleBusinessRegisteredChange(value)
                        }
                        width={isOnePageDynamicKyc ? 'fit-content' : 163}
                        height={isOnePageDynamicKyc ? 46 : 41}
                        padding={isOnePageDynamicKyc ? '10px 22px' : ''}
                        disabled={disableFieldForAdmins || isCustomerApproved}
                        margin="0 0 24px 0"
                        type={isOnePageDynamicKyc ? 'secondary' : 'primary'}
                    />
                </div>
            )}
            <div className={isOnePageDynamicKyc ? s.businessDetails : ''}>
                <InputFields
                    name="businessName"
                    title={`${
                        isOnePageDynamicKyc ? 'Registered ' : ''
                    }Business Name`}
                    value={inputs.businessName}
                    handleChange={handleChange}
                    errorMessage={errors?.businessName}
                    onBlur={handleOnBlur}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />

                <SelectField
                    floatLabelOnSelectedValue
                    name="businessIndustry"
                    floatingLabel="Business Industry"
                    initialOption="Business Industry"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('businessIndustry')}
                    values={businessIndustry.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.businessIndustry}
                    errorMessage={errors?.businessIndustry}
                    showSearch
                    dropdownPositionRelative
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    withCheckBox
                    currentSelected={inputs?.businessIndustry}
                    selectedValue={inputs?.businessIndustry}
                    marginBottom={fieldMarginBottom}
                />
            </div>

            <div
                className={
                    isBusinessUseType
                        ? s.businessDetailsRowTwo
                        : isOnePageDynamicKyc
                        ? s.businessDetails
                        : ''
                }
            >
                {inputs.registeredBusiness.value && (
                    <div className="VerifyInputWrapper">
                        <InputFields
                            name="cacNumber"
                            title="CAC Number"
                            value={inputs.cacNumber}
                            handleChange={handleChange}
                            onBlur={handleOnBlur}
                            disabled={
                                inputs?.cacNumberVerified ||
                                isAdminUser ||
                                cacVerificationInProgress ||
                                isCustomerApproved
                            }
                            marginBottom={
                                inputs?.cacNumberVerified ? '16px' : '8px'
                            }
                            errorMessage={
                                errors?.cacNumber || errors?.cacNumberVerified
                            }
                            inputWidth={'100%'}
                            verified={inputs?.cacNumberVerified}
                        />
                        {cacVerificationInProgress && (
                            <span className="verificationInProgressStyle">
                                Verification In Progress
                            </span>
                        )}
                        {!isAdminUser &&
                            !cacVerificationInProgress &&
                            !inputs?.cacNumberVerified && (
                                <div className="VerifyCTA">
                                    <button
                                        style={{ width: '123px' }}
                                        onClick={() =>
                                            toggleShowCacVerifyModal()
                                        }
                                        disabled={
                                            inputs?.businessName === '' ||
                                            inputs?.cacNumber === '' ||
                                            errors.cacNumber ||
                                            isCustomerApproved
                                        }
                                    >
                                        {isMobile
                                            ? 'Verify'
                                            : 'Verify CAC number'}
                                    </button>
                                </div>
                            )}
                    </div>
                )}

                {isBusinessUseType &&
                    inputs?.businessType !==
                        'Non-governmental Organisation' && (
                        <UploadField
                            btnText={'Memorandum of Association'}
                            handleUploadClick={() => setShowUploadModal(true)}
                            handleRemoveClick={() => {
                                if (inputs?.moaUrl?.length > 0) {
                                    handleRemoveFile(
                                        'moa',
                                        'memorandum of association',
                                        inputs?.moaUrl,
                                    )
                                } else {
                                    setMoa([])
                                    handleSelectChange('moaSuccessUpload', {
                                        name: '',
                                        originFileObj: '',
                                        type: '',
                                    })
                                    if (inputs?.moaUrl?.length > 0) {
                                        handleChange({
                                            name: 'moaUrl',
                                            value: '',
                                        })
                                    }
                                }
                            }}
                            fileUploaded={
                                inputs?.moaUrl?.length > 0 || moa?.length > 0
                            }
                            fileName={
                                extractFileNameFromUrl(inputs?.moaUrl) ||
                                moa?.[0]?.name
                            }
                            fileUrl={inputs?.moaUrl}
                            margin={`0 0 ${fieldMarginBottom} 0`}
                            disabled={
                                disableFieldForAdmins || isCustomerApproved
                            }
                            downloadable={isAdminUser}
                            errorMessage={errors?.moaUrl}
                        />
                    )}

                {!isOnePageDynamicKyc && (
                    <InputFields
                        name="numberOfYearsInOperation"
                        title="Number of years in operation"
                        value={inputs.numberOfYearsInOperation}
                        handleChange={handleChange}
                        onBlur={handleOnBlur}
                        errorMessage={errors?.numberOfYearsInOperation}
                        disabled={disableFieldForAdmins || isCustomerApproved}
                        marginBottom={fieldMarginBottom}
                    />
                )}

                {!isOnePageDynamicKyc && (
                    <>
                        <IconInputField
                            icon={LinkIcon}
                            name="businessWebsite"
                            title="Website"
                            width={'100%'}
                            value={inputs?.businessWebsite}
                            handleChange={handleChange}
                            onBlur={handleOnBlur}
                            errorMessage={errors?.businessWebsite}
                            disabled={
                                disableFieldForAdmins || isCustomerApproved
                            }
                            marginBottom={fieldMarginBottom}
                        />
                        {!errors?.businessWebsite && (
                            <p className="OptionalTypoGraphy">Optional</p>
                        )}
                    </>
                )}

                <TextArea
                    title={
                        inputs.businessDescription === ''
                            ? ''
                            : 'Description of business'
                    }
                    placeholder="Tell us about your business (example: how many years of operations, if you have a website, etc.)"
                    name="businessDescription"
                    value={inputs.businessDescription}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors?.businessDescription}
                    rows={5}
                    height="162px"
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />
            </div>
        </>
    )
}

BusinessDetails.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default BusinessDetails
