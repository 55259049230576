import PropTypes from 'prop-types'
import FormTitleBar from 'src/components/FormTitleBar'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from './editandpreview.module.scss'
import ButtonComponent from 'src/components/Button'
import { InputFields, SelectField } from 'src/components/InputFields'
import arrow from 'src/assets/images/blue-arrow.svg'
import useEditAndPreviewModal from './hook'
import { creditVariablePaymentModels } from 'src/utils/Data/paymentModel'
import Toast from 'src/components/Toast'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const EditAndPreviewModal = ({
    showModal,
    closeModal,
    editAndPreviewModalAction,
    setEditAndPreviewModalAction,
    handleSuccess,
    editVariablesInputData,
    editVariablesMethod,
}) => {
    const {
        onCancel,
        titles,
        isOpen,
        toggleBox,
        handleBack,
        handleContinue,
        inputs,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
        toast,
        updateVariablesLoading,
    } = useEditAndPreviewModal(
        closeModal,
        editAndPreviewModalAction,
        setEditAndPreviewModalAction,
        handleSuccess,
        editVariablesMethod,
        editVariablesInputData,
    )

    const { isMobile } = useMediaQueries()

    return (
        <SecondaryModal
            closable
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'624px'}
            modalHeight={isMobile ? '100%' : '717px'}
            content={
                <div
                    className={`${s.wrapper} ${
                        editAndPreviewModalAction === 'edit'
                            ? s.editWrapper
                            : s.previewWrapper
                    }`}
                >
                    {toast?.showToast && (
                        <Toast
                            message={toast?.toastMessage}
                            messageType={toast?.messageType}
                            position={'absolute'}
                            top={'30px'}
                            left={'0px'}
                        />
                    )}
                    <FormTitleBar
                        type={'M20'}
                        title={'Bulk Edit'}
                        subtitle={
                            'We’ve solved the problems that make it difficult for'
                        }
                    />
                    <div
                        className={`${
                            editAndPreviewModalAction === 'edit'
                                ? s.editContentWrapper
                                : s.previewContentWrapper
                        } `}
                    >
                        <div className={s.tableTitles}>
                            {titles?.map((title, i) => (
                                <h5 key={i}>{title}</h5>
                            ))}
                        </div>
                        <div className={s.editVariableSections}>
                            {inputs?.map((variable, itemIndex) => {
                                const paymentModel = variable?.details?.find(
                                    detail => detail?.name === 'paymentModel',
                                )?.value
                                return (
                                    <div
                                        key={variable?.id}
                                        className={`${s.variableSection} ${
                                            isOpen[itemIndex] &&
                                            variable.details?.length > 4
                                                ? s.variableSectionScrollable
                                                : ''
                                        }`}
                                    >
                                        <div className={s.variableHeading}>
                                            <h4>{variable?.name}</h4>
                                            <h5>{paymentModel}</h5>
                                            <p>3%</p>
                                            <div
                                                onClick={() =>
                                                    toggleBox(itemIndex)
                                                }
                                                className={
                                                    'CRESTopArrowWrapper'
                                                }
                                            >
                                                <img
                                                    src={arrow}
                                                    alt="arrow"
                                                    className={
                                                        isOpen[itemIndex]
                                                            ? 'CRESTopArrowOpen'
                                                            : 'CRESTopArrowClosed'
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                isOpen[itemIndex]
                                                    ? 'CustomRecommendationEnergySolutionBottomOpen'
                                                    : 'CustomRecommendationEnergySolutionBottomClosed'
                                            }
                                            style={{ margin: 0 }}
                                        >
                                            {editAndPreviewModalAction ===
                                            'edit' ? (
                                                <div
                                                    className={
                                                        s.editVariablesValuesGrid
                                                    }
                                                >
                                                    {variable?.details?.map(
                                                        (detail, detailIndex) =>
                                                            detail?.name ===
                                                            'paymentModel' ? (
                                                                <SelectField
                                                                    key={
                                                                        detailIndex
                                                                    }
                                                                    name={
                                                                        detail?.name
                                                                    }
                                                                    initialOption={
                                                                        detail?.value
                                                                    }
                                                                    values={creditVariablePaymentModels?.map(
                                                                        option => ({
                                                                            value: option,
                                                                        }),
                                                                    )}
                                                                    value={
                                                                        detail?.value
                                                                    }
                                                                    selectWidth="100%"
                                                                    marginBottom={
                                                                        '0px'
                                                                    }
                                                                    currentSelected={
                                                                        detail?.value
                                                                    }
                                                                    handleChange={(
                                                                        name,
                                                                        value,
                                                                    ) =>
                                                                        handleSelectChange(
                                                                            name,
                                                                            value,
                                                                            itemIndex,
                                                                            detailIndex,
                                                                        )
                                                                    }
                                                                    onBlur={() =>
                                                                        handleSelectOnBlur(
                                                                            detail?.name,
                                                                            itemIndex,
                                                                            detailIndex,
                                                                        )
                                                                    }
                                                                    dropdownPositionRelative
                                                                    floatingLabel={
                                                                        detail?.value !==
                                                                            '' &&
                                                                        detail?.label
                                                                    }
                                                                    errorMessage={
                                                                        errors[
                                                                            itemIndex
                                                                        ] &&
                                                                        errors[
                                                                            itemIndex
                                                                        ][
                                                                            detail
                                                                                ?.name
                                                                        ]
                                                                            ? errors[
                                                                                  itemIndex
                                                                              ][
                                                                                  detail
                                                                                      .name
                                                                              ]
                                                                            : ''
                                                                    }
                                                                />
                                                            ) : (
                                                                <InputFields
                                                                    key={
                                                                        detailIndex
                                                                    }
                                                                    title={
                                                                        detail?.label
                                                                    }
                                                                    name={
                                                                        detail?.name
                                                                    }
                                                                    value={
                                                                        detail.value
                                                                    }
                                                                    inputValue={
                                                                        detail?.value
                                                                    }
                                                                    inputWidth="100%"
                                                                    marginBottom={
                                                                        '0px'
                                                                    }
                                                                    errorMessage={
                                                                        errors[
                                                                            itemIndex
                                                                        ] &&
                                                                        errors[
                                                                            itemIndex
                                                                        ][
                                                                            detail
                                                                                .name
                                                                        ]
                                                                            ? errors[
                                                                                  itemIndex
                                                                              ][
                                                                                  detail
                                                                                      .name
                                                                              ]
                                                                            : ''
                                                                    }
                                                                    handleChange={e =>
                                                                        handleChange(
                                                                            e,
                                                                            itemIndex,
                                                                            detailIndex,
                                                                        )
                                                                    }
                                                                    onBlur={e =>
                                                                        handleOnBlur(
                                                                            e,
                                                                            itemIndex,
                                                                            detailIndex,
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                    )}
                                                </div>
                                            ) : (
                                                <div className={s.detailsGrid}>
                                                    {variable?.details?.map(
                                                        (detail, i) => (
                                                            <div key={i}>
                                                                <h5>
                                                                    {
                                                                        detail?.label
                                                                    }
                                                                </h5>
                                                                <p>
                                                                    {
                                                                        detail?.value
                                                                    }
                                                                </p>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={s.btn}>
                        {editAndPreviewModalAction === 'preview' && (
                            <ButtonComponent
                                btnBgColor={'#E2EEFF'}
                                btnTextColor={'#004AAD'}
                                btnWidth={'124px'}
                                btnHeight={'50px'}
                                marginTop={'32px'}
                                handleClick={handleBack}
                            >
                                Back
                            </ButtonComponent>
                        )}
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            btnWidth={
                                editAndPreviewModalAction === 'edit'
                                    ? '160px'
                                    : '125px'
                            }
                            btnHeight={
                                editAndPreviewModalAction === 'edit'
                                    ? '56px'
                                    : '50px'
                            }
                            marginTop={'32px'}
                            handleClick={handleContinue}
                            loading={updateVariablesLoading}
                        >
                            {editAndPreviewModalAction === 'edit'
                                ? 'Continue'
                                : 'Yes, proceed'}
                        </ButtonComponent>
                    </div>
                </div>
            }
        />
    )
}

EditAndPreviewModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    editAndPreviewModalAction: PropTypes.string,
    setEditAndPreviewModalAction: PropTypes.func,
    handleSuccess: PropTypes.func,
    editVariablesInputData: PropTypes.array,
    editVariablesMethod: PropTypes.string,
}

export default EditAndPreviewModal
