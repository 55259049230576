import AccountSetupNav from 'src/pages/Consumer/SmartShoppingExperience/AccountSetupOverview/components/Nav'
import { useDynamicInformationV2Context } from '../../context'

const DynamicInformationV2Nav = () => {
    const {
        handleSubmit,
        setShowSimulateCreditDecision,
        showSimulateCreditDecision,
        isSaving,
        isSubmitting,
        submit,
        paymentPlanType,
        disablePageButtons,
        inputs,
        useType,
        offer,
        isCustomerApproved,
        ranCreditDecision,
        handleSaveProgress,
        kycData,
        kycInfoFormik,
        requiredKycSections,
        page,
        allRequiredFieldsPersisted,
        onAdminKycSaveSuccessCallback,
    } = useDynamicInformationV2Context()

    return (
        <AccountSetupNav
            handleSubmit={handleSubmit}
            canSubmit={true}
            handleSaveProgress={handleSaveProgress}
            estimationId={offer?.id}
            paymentPlanType={paymentPlanType}
            consumerName={kycData?.consumer?.user?.first_name}
            showSimulateCreditDecision={showSimulateCreditDecision}
            setShowSimulateCreditDecision={setShowSimulateCreditDecision}
            page={page}
            isSaving={isSaving}
            isSubmitting={submit && isSubmitting}
            completed={allRequiredFieldsPersisted}
            disablePageButtons={disablePageButtons}
            inputs={inputs}
            useType={useType}
            estimation={offer}
            isCustomerApproved={isCustomerApproved}
            ranCreditDecision={ranCreditDecision}
            hasPaidOutrightly={false}
            kycInfoFormik={kycInfoFormik}
            requiredKycSections={requiredKycSections}
            onAdminKycSaveSuccessCallback={onAdminKycSaveSuccessCallback}
        />
    )
}

export default DynamicInformationV2Nav
