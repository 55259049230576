import { useEffect, useRef } from 'react'
import useLogOut from 'src/utils/Hooks/useLogOut'

const useAutoLogout = () => {
    const timeout = 60 * 60 * 1000 // 1hr in milliseconds
    const timeoutRef = useRef(null)
    const { logOut } = useLogOut()

    const resetTimer = () => {
        // Clear the existing timer
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        // Set a new timer
        timeoutRef.current = setTimeout(() => {
            logOut()
        }, timeout)
    }

    useEffect(() => {
        // Reset the timer on any user activity
        const activityEvents = [
            'click',
            'mousemove',
            'keypress',
            'scroll',
            'touchstart',
        ]
        activityEvents.forEach(event =>
            window.addEventListener(event, resetTimer),
        )

        // Set the initial timer
        resetTimer()

        // Cleanup event listeners on component unmount
        return () => {
            activityEvents.forEach(event =>
                window.removeEventListener(event, resetTimer),
            )
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
        // eslint-disable-next-line
    }, [])
}

export default useAutoLogout
