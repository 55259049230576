import PropTypes from 'prop-types'
import FormTitleBar from 'src/components/FormTitleBar'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from './onetomultiplebulkeditmodal.module.scss'
import ButtonComponent from 'src/components/Button'
import { InputFields, SelectField } from 'src/components/InputFields'
import useOneToMultipleBulkEditModal from './hook'
import { creditVariableTypeOptions } from './data'
import { ReactComponent as CloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import { ReactComponent as PlusIcon } from 'src/assets/images/Plus-Blue.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const OneToMultipleBulkEditModal = ({
    showModal,
    closeModal,
    openDrawer,
    setBulkUpdate,
    goBack,
}) => {
    const {
        onCancel,
        handleContinue,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
        variableInputs,
        selectedVariables,
        handleAddVariableInput,
        handleRemoveVariableInput,
        isFormValid,
    } = useOneToMultipleBulkEditModal(closeModal, openDrawer, setBulkUpdate)

    const { isMobile } = useMediaQueries()

    return (
        <SecondaryModal
            closable
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'624px'}
            modalHeight={
                isMobile
                    ? '100%'
                    : variableInputs.length > 1
                    ? '717px'
                    : '547px'
            }
            content={
                <div className={`${s.wrapper}`}>
                    <FormTitleBar
                        type={'M20'}
                        title={'Bulk Edit'}
                        subtitle={
                            'We’ve solved the problems that make it difficult for'
                        }
                    />
                    <div className={`${s.editContentWrapper} `}>
                        {variableInputs.map((variable, index) => (
                            <div className={s.editVariableSection} key={index}>
                                <div className={s.editSectionTitleWrapper}>
                                    <p className={s.sectionTitle}>
                                        Variable Type {index + 1}
                                    </p>
                                    {index > 0 && (
                                        <button
                                            className={
                                                s.manageVariableInputsButton
                                            }
                                            onClick={() =>
                                                handleRemoveVariableInput(index)
                                            }
                                        >
                                            <CloseIcon />
                                            <span>
                                                Remove this variable type
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <SelectField
                                    name={`variable-type-${index}`}
                                    initialOption={'Select Variable Type'}
                                    values={creditVariableTypeOptions.filter(
                                        variableOption =>
                                            selectedVariables[
                                                variableOption.value
                                            ] === undefined ||
                                            variableOption.value ===
                                                variable.type,
                                    )}
                                    value={variable.type}
                                    selectWidth="100%"
                                    marginBottom={'0px'}
                                    currentSelected={variable.type}
                                    handleChange={(name, value) =>
                                        handleSelectChange(name, value, index)
                                    }
                                    onBlur={() =>
                                        handleSelectOnBlur(
                                            `variable-type-${index}`,
                                            index,
                                        )
                                    }
                                    dropdownPositionRelative
                                    floatingLabel={
                                        variable.type
                                            ? 'Select Variable Type'
                                            : ''
                                    }
                                    errorMessage={
                                        errors[`variable-type-${index}`]
                                            ? errors[`variable-type-${index}`]
                                            : ''
                                    }
                                    contentField="label"
                                />

                                <InputFields
                                    title={'Enter Variable Value'}
                                    name={`variable-value-${index}`}
                                    value={variable.value}
                                    inputValue={variable.value}
                                    inputWidth="100%"
                                    marginBottom={'0px'}
                                    errorMessage={
                                        errors[`variable-value-${index}`]
                                            ? errors[`variable-value-${index}`]
                                            : ''
                                    }
                                    handleChange={e => handleChange(e, index)}
                                    onBlur={e => handleOnBlur(e, index)}
                                />
                            </div>
                        ))}
                        <div className={s.addNewVariableButtonWrapper}>
                            <button
                                className={s.manageVariableInputsButton}
                                onClick={handleAddVariableInput}
                            >
                                <PlusIcon />
                                <span>Add a new variable type</span>
                            </button>
                        </div>
                    </div>

                    <div className={s.btn}>
                        <ButtonComponent
                            btnBgColor={'#E2EEFF'}
                            btnTextColor={'#004AAD'}
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            marginTop={'32px'}
                            handleClick={goBack}
                        >
                            <span className={s.infoButtonContent}
                            >
                                Go back</span>
                        </ButtonComponent>
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            marginTop={'32px'}
                            handleClick={handleContinue}
                            disabled={!isFormValid()}
                        >
                            Continue
                        </ButtonComponent>
                    </div>
                </div>
            }
        />
    )
}

OneToMultipleBulkEditModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    openDrawer: PropTypes.func,
    setBulkUpdate: PropTypes.func,
    goBack: PropTypes.func,
}

export default OneToMultipleBulkEditModal
