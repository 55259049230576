import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getKycInfo } from 'src/api/create-account'
import { useAppContext } from 'src/appContext/AppContext'
import { errorHandler } from 'src/utils/errorHandler'
import { decodeUserInfo } from 'src/utils/auth'
import { getConsumerKYCInfoForAdmin } from 'src/api/shoppingExperience/account-overview'

const useConsumerKyc = () => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    // estimation_id from params used by admin
    const { id } = useParams()
    const { businessInfoFormik, businessContactFormik } = useAppContext()
    const { values: businessValues } = businessInfoFormik
    const { values: contactValues } = businessContactFormik

    const {
        businessInformationData: data,
        setBusinessInformationData: setData,
        setGetKycError,
    } = useAppContext()

    const { refetch, isFetching } = useQuery(
        ['kyc'],
        () => (isAdminUser ? getConsumerKYCInfoForAdmin(id) : getKycInfo()),
        {
            enabled: false,
            retry: false,
            onSuccess: response => {
                const newData = response.data?.data?.consumer

                setData(prevState => ({
                    ...prevState,
                    businessInfo: {
                        ...prevState.businessInfo,
                        businessName:
                            newData?.business?.business_name ||
                            businessValues.businessName,
                        businessType:
                            newData?.business?.business_type ||
                            businessValues.businessType,
                        businessIndustry:
                            newData?.business?.business_industry ||
                            businessValues.businessIndustry,
                        cacNumber:
                            newData?.business?.business_registration_number ||
                            businessValues.cacNumber,
                        businessRegistrationNumberVerified:
                            newData?.business
                                ?.business_registration_number_verified ||
                            false,
                        businessNameVerified:
                            newData?.business?.business_name_verified || false,
                        businessDescription:
                            newData?.business.business_description ||
                            businessValues.businessDescription,
                        website:
                            newData?.business?.business_website ||
                            businessValues.website,
                        numberOfYearsInOperation:
                            newData?.business.years_in_operation ||
                            businessValues.numberOfYearsInOperation,
                    },
                    contactInfo: {
                        ...prevState.contactInfo,
                        firstName:
                            newData?.user?.first_name ||
                            contactValues.firstName,
                        lastName:
                            newData?.user?.last_name || contactValues.lastName,
                        phone: newData?.user?.phone_number?.startsWith('+234')
                            ? newData?.user?.phone_number.slice(4)
                            : newData?.user?.phone_number ||
                              contactValues.phone,
                        phone_verified: newData?.user?.phone_verified,
                        email: newData?.user?.email || contactValues.email,
                        email_verified: newData?.user?.email_verified,
                        directorsFirstName:
                            newData?.director_details?.first_name ||
                            contactValues.directorsFirstName,
                        directorsLastName:
                            newData?.director_details?.last_name ||
                            contactValues.directorsLastName,
                        directorsPhoneNumber:
                            newData?.director_details?.phone_number?.startsWith(
                                '+234',
                            )
                                ? newData?.director_details?.phone_number.slice(
                                      4,
                                  )
                                : newData?.director_details?.phone_number ||
                                  contactValues.directorsPhoneNumber,
                        directorsEmail:
                            newData?.director_details?.email ||
                            contactValues.directorsEmail,
                        businessAddress:
                            newData?.user?.street_address ||
                            contactValues.businessAddress,
                        businessCity:
                            newData?.user?.city || contactValues.businessCity,
                        businessState:
                            newData?.user?.state_of_residence ||
                            contactValues.businessState,
                        businessCountry:
                            newData?.user?.country ||
                            contactValues.businessCountry,
                        installationAddress:
                            newData?.installation_details?.street_address ||
                            contactValues.installationAddress,
                        installationCity:
                            newData?.installation_details?.city ||
                            contactValues.installationCity,
                        installationState:
                            newData?.installation_details?.state ||
                            contactValues.installationState,
                        installationCountry:
                            newData?.installation_details?.country ||
                            contactValues.installationCountry,
                        confirmAddress:
                            newData?.user?.street_address ===
                                newData?.installation_details?.street_address &&
                            newData?.user?.city ===
                                newData?.installation_details?.city &&
                            newData?.user?.state_of_residence ===
                                newData?.installation_details?.state &&
                            newData?.user?.country ===
                                newData?.installation_details?.country &&
                            newData?.user?.street_address &&
                            newData?.user?.city &&
                            newData?.user?.state_of_residence &&
                            newData?.user?.country
                                ? 'yes'
                                : 'no',
                    },
                }))
            },
            onError: error => {
                setGetKycError(
                    errorHandler(error?.response?.data) ||
                        'Unable to get Kyc data, please try again later',
                )
            },
        },
    )

    useEffect(() => {
        refetch()
    }, [refetch])

    return {
        data,
        setData,
        refetch,
        isFetching,
    }
}

export default useConsumerKyc
