import '../../consumerkyc.scss'
import InputFieldWithButton from 'src/components/InputFields/InputFieldWithButton'
import { useConsumerKYCContext } from '../../context'
import VerifyBVNModal from '../../../CreateAccount/Verification/components/VerifyBVNModal'
import BankStatementSection from '../../../CreateAccount/DynamicInformation/components/Form/components/BankStatementSection'

const FinancialInformationSection = () => {
    const {
        useType,
        inputs,
        handleBlur,
        errors,
        showBVNModal,
        closeBVNModal,
        bvnVerifiedSuccessfully,
        handleBvnVerifyLater,
        setVerificationInProgress,
        verificationInProgress,
        handleInputChange,
        handleBvnVerification,
        estimation,
        kycData,
        removeErrors,
        startMonoLoading,
        stopMonoLoading,
        connectToMonoLoading,
        isAdminUser,
        isCustomerApproved,
        bankStatementRef,
        onBankStatementUploadSuccess,
    } = useConsumerKYCContext()

    return (
        <>
            <div className="FinancialKYC">
                {useType === 'residential' && (
                    <div
                        className={` ${
                            useType === 'business' ? 'GroupItems' : ''
                        }`}
                    >
                        <InputFieldWithButton
                            title="Bank Verification Number"
                            type={'text'}
                            name="bvn"
                            btnText="Verify your BVN"
                            value={inputs?.bvn}
                            mobileBtnText="Verify"
                            handleChange={handleInputChange}
                            verified={inputs?.bvnVerified}
                            disabled={
                                inputs?.bvnVerified ||
                                verificationInProgress ||
                                isAdminUser ||
                                isCustomerApproved
                            }
                            handleBlur={handleBlur}
                            errorMessage={errors?.bvn}
                            showBtn={
                                !inputs?.bvnVerified && !verificationInProgress
                            }
                            inprogress={verificationInProgress}
                            progressText="Verification in Progress"
                            btnDisabled={
                                inputs?.bvn === undefined ||
                                inputs?.bvn?.length === 0 ||
                                inputs?.bvn?.length < 11 ||
                                inputs?.bvn?.length > 11 ||
                                inputs?.bvnVerified ||
                                isAdminUser ||
                                isCustomerApproved
                            }
                            onBtnClick={handleBvnVerification}
                        />
                    </div>
                )}

                <div ref={bankStatementRef}>
                    <BankStatementSection
                        errors={errors}
                        offer={estimation}
                        kycData={kycData}
                        removeErrors={removeErrors}
                        isOnePageKyc
                        showOnlyMono
                        startMonoLoading={startMonoLoading}
                        stopMonoLoading={stopMonoLoading}
                        connectToMonoLoading={connectToMonoLoading}
                        onSuccess={onBankStatementUploadSuccess}
                    />
                </div>
            </div>

            <VerifyBVNModal
                showModal={showBVNModal}
                closeBVNModal={closeBVNModal}
                storedBvn={inputs?.bvn}
                bvnVerifiedSuccessfully={bvnVerifiedSuccessfully}
                userType={useType}
                storedFirstName={
                    useType === 'residential'
                        ? inputs?.firstName
                        : inputs?.directorFirstName
                }
                storedLastName={
                    useType === 'residential'
                        ? inputs?.lastName
                        : inputs?.directorLastName
                }
                storedEmail={
                    useType === 'residential'
                        ? inputs.email
                        : inputs?.directorEmail
                }
                storedPhoneNumber={
                    useType === 'residential'
                        ? inputs.phoneNumber
                        : inputs?.directorPhone
                }
                handleBvnVerifyLater={handleBvnVerifyLater}
                setVerificationInProgress={setVerificationInProgress}
            />
        </>
    )
}

export default FinancialInformationSection
