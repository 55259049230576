import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { InputFields } from 'src/components/InputFields'
import s from '../styles.module.scss'

const WhereYouWork = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
    page,
}) => {
    const isOnePageDynamicKyc = page === 'one-page-dynamic-kyc'
    const fieldMarginBottom = isOnePageDynamicKyc ? '16px' : '20px'
    const disableFieldForAdmins = isAdminUser && page !== 'one-page-dynamic-kyc'

    return (
        <>
            {!isOnePageDynamicKyc && (
                <Label
                    title={'Where do you work?'}
                    subTitle={
                        'Provide us with your employer’s details and what you do'
                    }
                />
            )}
            <div className={isOnePageDynamicKyc ? s.whereYouWork : ''}>
                <InputFields
                    name="companyName"
                    title={
                        isOnePageDynamicKyc
                            ? 'Where do you work?'
                            : 'Company Name'
                    }
                    value={inputs?.companyName}
                    handleChange={handleChange}
                    errorMessage={errors?.companyName}
                    onBlur={handleOnBlur}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />
                <InputFields
                    name="jobTitle"
                    title="Job Title"
                    value={inputs?.jobTitle}
                    handleChange={handleChange}
                    errorMessage={errors?.jobTitle}
                    onBlur={handleOnBlur}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />
            </div>
            {!isOnePageDynamicKyc && <VerticalSpacer top={12} />}
        </>
    )
}

WhereYouWork.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default WhereYouWork
