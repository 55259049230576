import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import badge from 'src/assets/lottie/badge.json'
import { getAfterCareRatingNotes } from '../../utils'

const AfterCareRatingModalContent = ({ afterCareRatingDetails }) => {
    const notes = getAfterCareRatingNotes(afterCareRatingDetails)
    return (
        <div className="ViewSystemAfterCareRatingModal">
            <div>
                <Lottie
                    options={{
                        animationData: badge,
                        loop: false,
                        autoplay: true,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                />
                <p>{afterCareRatingDetails?.aftercare_score}</p>
            </div>

            <div className="ViewSystemAfterCareRatingNotes">
                {notes?.map(({ name, note }) => (
                    <div key={name}>
                        <p> OEM {name}</p>
                        <p>{note}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

AfterCareRatingModalContent.propTypes = {
    afterCareRatingDetails: PropTypes.string,
}

export default AfterCareRatingModalContent
