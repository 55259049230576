import helpIcon from 'src/assets/images/help-circle-black.svg'
import PropTypes from 'prop-types'
import './kycAnalysis.scss'
import { Tooltip, Skeleton } from 'antd'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useEffect, useState } from 'react'
import failIcon from 'src/assets/images/circle-close-red.svg'
import passIcon from 'src/assets/images/check-green.svg'
import { useSSEAdminIdentityCompare } from 'src/api/shoppingExperience/identity-verification/queries'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const KycAnalysisSummary = ({ title, handleOverride, page, inputs }) => {
    const { sseBasicInfoData, sseBasicInfoFetching } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const bvnInfo =
        sseBasicInfoData?.data?.data?.financial_verification?.bvn?.context
    const bvnID = sseBasicInfoData?.data?.data?.financial_verification?.bvn?.id
    const customerSelfie =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_url
    const imageAnalysis =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_meta_data
    const isFaceDetected =
        imageAnalysis?.facial_recognition?.single_face_detected
    const isFaceRecognized = bvnInfo?.comparison_data?.find(
        e => e.criteria === 'photo',
    )?.match
    const [showBvnImageModal, setShowBvnImageModal] = useState(false)
    const [showUserImageModal, setShowUserImageModal] = useState(false)
    const allChecksPassed = bvnInfo?.all_checks_passed
    const { errorAlert, successAlert } = useCustomToast()

    const { submitKycResidentialIdentityCompare } = useSSEAdminIdentityCompare({
        onSuccess: res => {
            successAlert(res?.message || 'Image comparison successful')
        },
        onError: error => {
            errorAlert(
                errorHandler(
                    error?.response?.data ||
                        'An error occurred, please try again later.',
                ),
            )
        },
    })

    useEffect(() => {
        if (!sseBasicInfoFetching) {
            if (
                bvnInfo?.comparison_data.length < 1 &&
                inputs?.selfieImageUrl !== ''
            ) {
                submitKycResidentialIdentityCompare(bvnID)
            }
        }
    }, [
        sseBasicInfoFetching,
        bvnID,
        bvnInfo,
        submitKycResidentialIdentityCompare,
        inputs?.selfieImageUrl,
    ])

    const PassedCheck = () => {
        return (
            <div className="checkContainer">
                <span>
                    <img src={passIcon} />
                </span>
                <p>Passed</p>
            </div>
        )
    }

    const NoMatchCheck = () => {
        return (
            <div className="checkContainer">
                <span>
                    <img src={failIcon} />
                </span>
                <p>No Match</p>
            </div>
        )
    }

    return (
        <>
            <SecondaryModal
                v2
                showModal={showBvnImageModal}
                onCancel={() => setShowBvnImageModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className="imageModalContainer">
                            <div className="imageModalContainer">
                                <img
                                    src={
                                        'data:image/png;base64,' +
                                        bvnInfo?.verified_data?.photoId
                                    }
                                    alt="Image returned from BVN"
                                />
                            </div>
                        </div>
                    </>
                }
            />
            <SecondaryModal
                v2
                showModal={showUserImageModal}
                onCancel={() => setShowUserImageModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className="imageModalContainer">
                            <img
                                src={customerSelfie}
                                alt="Image returned from BVN"
                            />
                        </div>
                    </>
                }
            />
            <div>
                {bvnInfo && (
                    <div
                        className="bvnDataContainer"
                        style={{
                            margin:
                                page === 'business-verification'
                                    ? '10px 0'
                                    : '',
                            border:
                                page === 'business-verification'
                                    ? '1px solid #e2eeff'
                                    : '',
                        }}
                    >
                        <div className="titleSection">
                            <div className="title">
                                <h3>{title}</h3>
                                {page !== 'business-verification' && (
                                    <Tooltip
                                        overlayStyle={{
                                            padding: '9px 8px',
                                            maxWidth: '178px',
                                        }}
                                        overlayInnerStyle={{
                                            fontSize: '12px',
                                            fontFamily: 'SF-Pro-Display',
                                        }}
                                        color="#070C18"
                                        placement="bottom"
                                        title="This data  is being matched  with the data result from the selfie /photo the consumer added on their KYC"
                                    >
                                        <img src={helpIcon} />
                                    </Tooltip>
                                )}
                            </div>
                            {page !== 'business-verification' &&
                                allChecksPassed && (
                                    <div className={`tag success`}>PASSED</div>
                                )}
                            {page !== 'business-verification' &&
                                !allChecksPassed &&
                                inputs?.selfieImageUrl !== '' && (
                                    <button
                                        className="override_btn"
                                        onClick={handleOverride}
                                    >
                                        Override
                                    </button>
                                )}
                        </div>
                        {sseBasicInfoFetching ? (
                            <Skeleton active size="small" />
                        ) : (
                            <>
                                <div className="identityMetaData">
                                    <div>
                                        {bvnInfo?.comparison_data?.find(
                                            rep => rep?.criteria === 'gender',
                                        )?.match === false &&
                                        page !== 'business-verification' ? (
                                            <div className="">
                                                <h4>Gender</h4> <NoMatchCheck />
                                            </div>
                                        ) : (
                                            <div className="">
                                                <h4>Gender</h4> <PassedCheck />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {bvnInfo?.comparison_data?.find(
                                            rep => rep?.criteria === 'dob',
                                        )?.match === false &&
                                        page !== 'business-verification' ? (
                                            <div className="">
                                                <h4>Age</h4> <NoMatchCheck />
                                            </div>
                                        ) : (
                                            <div className="">
                                                <h4>Age</h4> <PassedCheck />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {page !== 'business-verification' && (
                                            <div className="">
                                                <h4>Date of birth</h4>{' '}
                                                <p>
                                                    {bvnInfo?.verified_data
                                                        ?.dob || '-'}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {page !== 'business-verification' && (
                                    <div className="identityMetaData">
                                        <div>
                                            <h4>Face Detection</h4>
                                            <div>
                                                {isFaceDetected ? (
                                                    <div className="">
                                                        <PassedCheck />
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <NoMatchCheck />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Face recognition</h4>
                                            <div>
                                                {isFaceRecognized ? (
                                                    <div className="">
                                                        <PassedCheck />
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <NoMatchCheck />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

KycAnalysisSummary.propTypes = {
    title: PropTypes.string,
    status: PropTypes.string,
    page: PropTypes.string,
    handleOverride: PropTypes.func,
    inputs: PropTypes.object,
}

export default KycAnalysisSummary
