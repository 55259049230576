import PropTypes from 'prop-types'
import styles from './selectbuttons.module.scss'

const selectedStyle = type => {
    switch (type) {
        case 'primary': {
            return {
                activeBackground: '#011A3C',
                activeColor: '#FFF',
                activeBorder: '1px solid #e5e5e5',
                font: '14px/24px SF-Pro-Display-Medium',
            }
        }
        case 'secondary': {
            return {
                activeBackground: '#E2EEFF',
                activeColor: '#011A3CE0',
                activeBorder: '0.5px solid #004AAD',
                font: '14px/24px SF-Pro-Display',
            }
        }
    }
}

export const SelectButtons = ({
    values,
    selectedValue,
    handleChange,
    margin,
    width,
    height,
    disabled,
    type = 'primary', // primary | secondary
    padding = 0,
}) => {
    const { activeBackground, activeColor, activeBorder, font } =
        selectedStyle(type)
    return (
        <div className={styles.wrapper} style={{ margin }}>
            {values?.map((value, i) => (
                <div
                    key={i}
                    style={{
                        background:
                            value === selectedValue ? activeBackground : '#FFF',
                        color:
                            value === selectedValue ? activeColor : '#011A3C',
                        border: value === selectedValue ? activeBorder : '',
                        font: font,
                        width: width,
                        height: height,
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        padding: padding,
                    }}
                    className={styles.btn}
                    role="button"
                    onClick={disabled ? () => {} : () => handleChange(value)}
                    aria-disabled={disabled}
                    data-testid={`select-button-${value}`}
                >
                    {value}
                </div>
            ))}
        </div>
    )
}

export const MultiSelectButtons = ({
    values,
    selectedValues,
    handleChange,
    margin,
    width,
    flexWrap,
}) => {
    return (
        <div
            className={styles.wrapper}
            style={{ margin, flexWrap: flexWrap && flexWrap }}
        >
            {values?.map((value, i) => (
                <div
                    key={i}
                    style={{
                        background: selectedValues?.includes(value)
                            ? '#011A3C'
                            : '#FFF',
                        color: selectedValues?.includes(value)
                            ? '#FFF'
                            : '#011A3C',
                        width: width && width,
                    }}
                    className={styles.btn}
                    role="button"
                    onClick={() => handleChange(value)}
                >
                    {value}
                </div>
            ))}
        </div>
    )
}

const sharedPropTypes = {
    values: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    margin: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SelectButtons.propTypes = {
    ...sharedPropTypes,
    selectedValue: PropTypes.string.isRequired,
}

MultiSelectButtons.propTypes = {
    ...sharedPropTypes,
    selectedValues: PropTypes.array.isRequired,
}
