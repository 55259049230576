import 'src/pages/Admin/PaymentPlans/Details/components/AlternativePackages/alternativepackages.scss'
import { Drawer, Pagination } from 'antd'
import PropTypes from 'prop-types'
import BackNav from 'src/components/BackNav'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon.svg'
import s from './selectvariables.module.scss'
import ButtonComponent from 'src/components/Button'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import arrow from 'src/assets/images/blue-arrow.svg'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import useSelectVariableDrawer from './hook'
import { NewSolutionSkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'

const SelectVariablesDrawer = ({
    drawerVisible,
    handleDrawerClose,
    openEditAndPreviewModal,
}) => {
    const {
        onClose,
        allSelected,
        toggleSelectAll,
        selectedVariables,
        checkSelected,
        handleSelectVariable,
        toggleBox,
        isOpen,
        creditVariablesSummaryData,
        setSelectedVariables,
        handlePage,
        handleInputChange,
        handleBlur,
        handleSearch,
        searchText,
        listTotal,
        currentPage,
        handleKeyPress,
        creditVariablesFetching,
    } = useSelectVariableDrawer(handleDrawerClose, drawerVisible)

    const { isMobile } = useMediaQueries()

    return (
        <Drawer
            placement="right"
            width={isMobile ? '100%' : '480px'}
            visible={drawerVisible}
            onClose={onClose}
            closable={false}
            className="SelectVariablesDrawer_Wrapper"
        >
            <div className={s.goBackBtns}>
                <BackNav title="Back to Credit System" onClick={onClose} />
                <CloseIcon role={'button'} onClick={onClose} />
            </div>
            <div className={s.titleAndBtns}>
                <h2>Select Credit Variables</h2>
                <div>
                    <ButtonComponent
                        type={'outline'}
                        btnBgColorOutline={'#F9FCFF'}
                        border={'1px solid #004AAD'}
                        btnTextColorOutline={'#004AAD'}
                        btnWidth={allSelected ? '95px' : '84px'}
                        btnHeight={isMobile ? '32px' : '44px'}
                        btnPadding={isMobile ? '7px' : '10px'}
                        handleClick={toggleSelectAll}
                    >
                        {allSelected ? 'Unselect all' : 'Select all'}
                    </ButtonComponent>
                    <ButtonComponent
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        btnWidth={isMobile ? '84px' : '91px'}
                        btnHeight={isMobile ? '32px' : '44px'}
                        btnPadding={isMobile ? '7px' : '10px'}
                        disabled={!selectedVariables?.length}
                        handleClick={() => {
                            openEditAndPreviewModal(selectedVariables)
                            setSelectedVariables([])
                        }}
                    >
                        Apply edit
                    </ButtonComponent>
                </div>
            </div>
            <NoFLoatingLabelInputFields
                TextPrefilled={searchText ? true : false}
                borderRadius="7px"
                inputWidth="100%"
                inputHeight="45px"
                value={searchText}
                type="text"
                iconType="search"
                prefilled
                inputPlaceholder="Search credit variables"
                style={{ paddingBottom: 7 }}
                handleChange={handleInputChange}
                onIconClick={handleSearch}
                onKeyUp={handleKeyPress}
                onBlur={handleBlur}
            />
            <VerticalSpacer top={44} />
            <div>
                {creditVariablesFetching ? (
                    <NewSolutionSkeletalCards total={10} />
                ) : creditVariablesSummaryData?.length === 0 ? (
                    <EmptyState />
                ) : (
                    creditVariablesSummaryData?.map((each, i) => {
                        const isSelected = checkSelected(each)
                        return (
                            <div
                                key={i}
                                className="CustomRecommendationEnergySolutionWrapper"
                            >
                                <div className={s.dropdownTop}>
                                    <h4>{each?.name}</h4>
                                    <div className={s.flagAndBtn}>
                                        {isSelected ? (
                                            <span className={s.selectedFlag}>
                                                SELECTED
                                            </span>
                                        ) : null}
                                        <ButtonComponent
                                            btnBgColor={
                                                isSelected
                                                    ? '#E2EEFF'
                                                    : '#004AAD'
                                            }
                                            btnTextColor={
                                                isSelected
                                                    ? '#004AAD'
                                                    : '#FFFFFF'
                                            }
                                            btnWidth={'70px'}
                                            btnHeight={'35.58px'}
                                            handleClick={() =>
                                                handleSelectVariable(each)
                                            }
                                        >
                                            {isSelected ? 'Unselect' : 'Select'}
                                        </ButtonComponent>
                                    </div>
                                    <div
                                        onClick={() => toggleBox(i)}
                                        className={'CRESTopArrowWrapper'}
                                        style={{
                                            justifySelf: 'flex-end',
                                            marginLeft: 19,
                                        }}
                                    >
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className={
                                                isOpen[i]
                                                    ? 'CRESTopArrowOpen'
                                                    : 'CRESTopArrowClosed'
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        isOpen[i]
                                            ? 'CustomRecommendationEnergySolutionBottomOpen'
                                            : 'CustomRecommendationEnergySolutionBottomClosed'
                                    }
                                >
                                    <div className={s.detailsGrid}>
                                        {each?.details?.map((detail, i) => (
                                            <div key={i}>
                                                <h5>{detail.label}</h5>
                                                <p>{detail.value}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
            {creditVariablesSummaryData?.length > 0 && (
                <div className="ACLPaginationWrapper">
                    <Pagination
                        defaultCurrent={currentPage}
                        current={currentPage}
                        defaultPageSize={1}
                        total={parseInt(listTotal, 10)}
                        showSizeChanger={false}
                        showTitle={false}
                        showLessItems
                        onChange={value => {
                            handlePage(value)
                        }}
                    />
                </div>
            )}
        </Drawer>
    )
}

SelectVariablesDrawer.propTypes = {
    drawerVisible: PropTypes.bool,
    handleDrawerClose: PropTypes.func,
    openEditAndPreviewModal: PropTypes.func,
}

export default SelectVariablesDrawer
