import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { IconInputField } from 'src/components/InputFields/CostField'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { InputFields } from 'src/components/InputFields'
import s from '../styles.module.scss'

const EmployeeData = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
    page,
}) => {
    const isOnePageDynamicKyc = page === 'one-page-dynamic-kyc'
    const fieldMarginBottom = isOnePageDynamicKyc ? '16px' : '20px'
    const disableFieldForAdmins = isAdminUser && page !== 'one-page-dynamic-kyc'

    return (
        <>
            {!isOnePageDynamicKyc && (
                <Label
                    title={'Employee data'}
                    subTitle={'Provide us with your data as an employee'}
                />
            )}

            <div className={s.employeeData}>
                <IconInputField
                    icon={NairaIcon}
                    name="netMonthlySalary"
                    title="Net monthly salary"
                    width={'100%'}
                    value={inputs?.netMonthlySalary}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors?.netMonthlySalary}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />

                <InputFields
                    name="employeeId"
                    title="Employee ID"
                    value={inputs.employeeId}
                    handleChange={handleChange}
                    errorMessage={errors?.employeeId}
                    onBlur={handleOnBlur}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />
            </div>

            {!isOnePageDynamicKyc && <VerticalSpacer top={12} />}
        </>
    )
}

EmployeeData.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default EmployeeData
