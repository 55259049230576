import { createContext, useState, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { initialBusinessInfoData } from './utils'
import useBusinessInfoSubmit from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useBusinessInfoSubmit'
import {
    extractFileNameFromUrl,
    formatPhoneNumber,
    includeHttps,
} from 'src/utils/formatting'
import {
    validateBusinessContactInfoKyc,
    validateBusinessInfoKyc,
    validateFinancialInfoKyc,
} from 'src/utils/validationSchema'
import {
    removeEmptyValues,
    removeEmptyObjectsV2,
} from 'src/utils/removeEmptyValues'

import { useUploadAuditReport } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/FinancialInformation/FinancialInfoForm/api'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import {
    financialInfoReducer,
    initialFinancialInfoValues,
    SHOW_AUDITREPORT_PASSWORD_MODAL,
    SET_BVN_ERROR,
    SHOW_AUDIT_MODAL,
    SET_AUDIT_REPORT_PASSWORD,
    SET_AUDIT_FILE,
} from './appReducer'
import { useGetEstimation } from 'src/api/shoppingExperience/account-overview'
import { useHistory } from 'react-router-dom'
import {
    CREATE_BUSINESS_ACCOUNT,
    CREATE_RESIDENTIAL_ACCOUNT,
} from 'src/routes/pathnames'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { decodeUserInfo } from 'src/utils/auth'
import { useCustomFormik } from 'src/utils/Hooks/useCustomFormik'
import { formatYupError } from 'src/utils/errorHandler'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { useGetKycInfo } from 'src/api/shoppingExperience/account-overview'
import { handleAppTrackingOnKYC } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils/utils'

const appContextDefaultValues = {
    handleSubmitKyc: () => {},
    setSuccessActions: () => {},
    submitBusinessInfo: () => {},
    submitBusinessContactInfo: () => {},
    handleSubmitBusinessFinancialInfo: () => {},
    errorAlert: () => {},
    paymentMethod: {},
    state: {},
    isAuditReportUploading: false,
    dispatch: () => {},
    estimationId: '',
    overviewInfo: {
        estimationId: '',
        solutionId: '',
        authUserId: '',
        paymentPlanType: '',
        firstName: '',
    },
    businessInfoFormik: {},
    businessContactFormik: {},
    finanicalInfoFormik: {},
    handleUploadAuditStatement: () => {},
    isCustomerApproved: false,
    applicationStatus: '',
    simulatedCreditDecision: '',
    setSimulatedCreditDecision: () => {},
    customerType: '',
}

export const AppContext = createContext(appContextDefaultValues)
const repaymentMethod = {
    'Card Debit': 'CARD_DEBIT',
    'Direct Debit': 'DIRECT_DEBIT',
}
const AppContextProvider = ({ children }) => {
    const { location } = useHistory()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const pageTitle = 'KYC | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    // id from params used by admin
    const id = location.pathname.includes('admin')
        ? location.pathname.split('/')[3]
        : null

    const [state, dispatch] = useReducer(
        financialInfoReducer,
        initialFinancialInfoValues,
    )
    const {
        uploadToS3Mutate: postDocumentToS3,
        uploadToS3Loading: isUploadingFileUrl,
    } = useUploadToS3()
    const {
        mutate: updateConsumerEstimation,
        isLoading: isAuditReportUploading,
    } = useUploadAuditReport()

    const consumerEstimationId =
        process.env
            .REACT_APP_FLAG_SHOW_KYC_DETAILS_FOR_ASSOCIATED_ACTIVE_PLAN ===
            'true' && location?.state?.estimationId
            ? location.state.estimationId
            : null

    const history = useHistory()
    const { data: estimation } = useGetEstimation(
        isAdminUser,
        id ?? consumerEstimationId,
        {
            enabled:
                location.pathname === CREATE_BUSINESS_ACCOUNT.FINANCIAL_INFO ||
                location.pathname ===
                    CREATE_RESIDENTIAL_ACCOUNT.FINANCIAL_INFO ||
                location.pathname === CREATE_BUSINESS_ACCOUNT.BUSINESS_INFO ||
                location.pathname ===
                    CREATE_BUSINESS_ACCOUNT.BUSINESS_VERIFICATION ||
                location.pathname ===
                    CREATE_RESIDENTIAL_ACCOUNT.IDENTITY_INFO ||
                location.pathname ===
                    `/admin/plans/${id}/kyc/business-verification` ||
                location.pathname ===
                    `/admin/plans/${id}/kyc/identity-information` ||
                location.pathname ===
                    `/admin/plans/${id}/kyc/residential-financial-information`,
        },
    )

    const isCustomerApproved =
        estimation?.data?.data?.approval_status === 'APPROVED'
    const applicationStatus = estimation?.data?.data?.application_status

    const estimationId =
        estimation?.data?.data?.id ||
        history.location?.state?.estimationId ||
        ''
    const customerType = estimation?.data?.data?.customer_type || ''
    const formId = estimation?.data?.data?.form_id || ''
    const { successAlert, errorAlert } = useCustomToast()
    const [paymentMethod, setPaymentMethod] = useState('')

    const [formResponse, setFormResponse] = useState()
    const [recommendations, setRecommendations] = useState()
    const [currentForm, setCurrentForm] = useState('businessInfo')
    const [businessInformationData, setBusinessInformationData] = useState(
        initialBusinessInfoData,
    )
    const [getKycError, setGetKycError] = useState('')
    const [fieldErrors, setFieldErrors] = useState({})
    const [successActions, setSuccessActions] = useState({})
    const [showBankAnalysisModal, setShowBankAnalysisModal] = useState(false)
    const [disableCompletedKYCBtn, setDisableCompletedKYCBtn] = useState(false)
    const [verificationInProgress, setVerificationInProgress] = useState(false)
    const [businessUpdateJustCompleted, setBusinessUpdateJustCompleted] =
        useState(false)
    const [businessConsumerName, setBusinessConsumerName] = useState('')

    const {
        submitBusinessInfo,
        submitBusinessContactInfo,
        submitToastMessage,
        setSubmitToastMessage,
        submitToastType,
        submitBusinessInfoByAdmin,
        submitBusinessContactInfoByAdmin,
    } = useBusinessInfoSubmit({
        successActions,
    })
    const [overviewInfo, setOverviewInfo] = useState({})
    const [simulatedCreditDecision, setSimulatedCreditDecision] = useState('')
    const [activeTab, setActiveTab] = useState('1')
    const businessInfoFormik = useCustomFormik({
        initialValues: businessInformationData.businessInfo,
        validationSchema: validateBusinessInfoKyc,
    })
    const businessContactFormik = useCustomFormik({
        initialValues: businessInformationData.contactInfo,
        validationSchema: validateBusinessContactInfoKyc,
    })
    const finanicalInfoFormik = useCustomFormik({
        initialValues: {
            bankName: '',
            monthlyRevenue: '',
            paymentMethod: '',
            auditReport: { fileName: '', fileOriginObj: '' },
            bankStatement: { fileName: '', fileOriginObj: '' },
        },
        validationSchema: validateFinancialInfoKyc,
    })
    const financialInfoValues = finanicalInfoFormik.values
    const businessValues = businessContactFormik.values

    const { data: customerKycInfo } = useGetKycInfo(isAdminUser, estimationId, {
        enabled:
            location.pathname === CREATE_BUSINESS_ACCOUNT.FINANCIAL_INFO ||
            location.pathname === CREATE_RESIDENTIAL_ACCOUNT.FINANCIAL_INFO,
    })

    const handleUploadAuditStatement = () => {
        postDocumentToS3(
            {
                category: 'FINANCIAL_STATEMENT_ANALYSIS',
                file: state.auditReportSuccessUpload[0],
            },
            {
                onSuccess: res => {
                    const docUrl = res?.url + res?.fields?.key || ''
                    dispatch({
                        type: SET_AUDIT_FILE,
                        payload: {
                            url: docUrl,
                            name: extractFileNameFromUrl(docUrl),
                        },
                    })
                    successAlert('Audit report uploaded successfully')
                    dispatch({
                        type: SHOW_AUDIT_MODAL,
                        payload: false,
                    })
                },
                onError: err => {
                    successActions?.setActionModalOpen(false)
                    errorAlert(
                        err?.response?.data?.message ||
                            'Audit report upload failed',
                    )
                },
            },
        )
    }

    const handleSubmitBusinessFinancialInfo = userType => {
        if (!financialInfoValues.monthlyRevenue && userType === 'business') {
            finanicalInfoFormik.setFieldTouched('monthlyRevenue', true)
            finanicalInfoFormik.setFieldError(
                'monthlyRevenue',
                'Please provide your monthly revenue.',
            )
            errorAlert('Monthly revenue is required')
            successActions?.setActionModalOpen(false)
            return
        }

        if (!state.bvn && userType === 'residential') {
            dispatch({
                type: SET_BVN_ERROR,
                payload: 'BVN is required',
            })
            errorAlert('BVN is required')
            successActions?.setActionModalOpen(false)
            return
        }

        if (!state.isBvnVerified && userType === 'residential') {
            dispatch({
                type: SET_BVN_ERROR,
                payload: 'BVN is not verified',
            })
            errorAlert('BVN is not verified')
            successActions?.setActionModalOpen(false)
            return
        }

        if (
            process.env
                .REACT_APP_FLAG_SHOW_CUSTOMER_PREFERRED_PAYMENT_METHOD ===
                'true' &&
            !financialInfoValues.paymentMethod
        ) {
            finanicalInfoFormik.setFieldTouched('paymentMethod', true)
            finanicalInfoFormik.setFieldError(
                'Please provide your payment method',
            )
            errorAlert('Please provide your payment method')
            successActions?.setActionModalOpen(false)
            return
        }
        if (state.auditReportSuccessUpload.length === 0) {
            const payload = {
                ...(state.auditFile.url && {
                    financial_analysis_statement: state.auditFile.url,
                }),
                repayment_method:
                    repaymentMethod[financialInfoValues.paymentMethod],
                estimationId,
            }

            if (userType === 'business') {
                payload.monthly_revenue = String(
                    financialInfoValues.monthlyRevenue,
                ).replaceAll(',', '')
            } else if (userType === 'residential' && !state.isBvnVerified) {
                payload.bvn = state.bvn
            }

            const latestConsumerBankStatementAnalysis =
                customerKycInfo?.data?.data?.financial_verification
                    ?.bank_statements[0]
            const banksStatementAnalysisInReview =
                latestConsumerBankStatementAnalysis?.analysis_context
                    ?.status === 'IN_PROGRESS' ||
                latestConsumerBankStatementAnalysis?.analysis_context
                    ?.status === 'RETRYING' ||
                latestConsumerBankStatementAnalysis?.analysis_context
                    ?.status === 'EMPTY'

            updateConsumerEstimation(payload, {
                onSuccess: () => {
                    successAlert(
                        'Financial information submitted successfully.',
                    )
                    dispatch({
                        type: SHOW_AUDITREPORT_PASSWORD_MODAL,
                        payload: false,
                    })
                    dispatch({
                        type: SHOW_AUDIT_MODAL,
                        payload: false,
                    })
                    if (
                        !customerKycInfo?.data?.data?.identity_verification
                            ?.verified
                    ) {
                        setVerificationInProgress(true)
                    } else if (banksStatementAnalysisInReview) {
                        setShowBankAnalysisModal(true)
                    } else {
                        setTimeout(() => {
                            history.push({
                                pathname: '/consumer/account-setup/overview',
                                state: {
                                    justCompletedKYC: true,
                                    estimationId,
                                },
                            })
                        }, 3000)
                    }
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        eventTrackers['sseSubmitFinancialInfoSuccessfully']
                            .action,
                        eventTrackers['sseSubmitFinancialInfoSuccessfully']
                            .label,
                        eventTrackers['sseSubmitFinancialInfoSuccessfully']
                            .category,
                        ['MP', 'GA'],
                        'event',
                        { payload },
                    )

                    handleAppTrackingOnKYC('completedKYCCategory', pageTitle)
                    handleAppTrackingOnKYC(
                        userType === 'business'
                            ? 'kybCompleted'
                            : 'kycCompleted',
                        pageTitle,
                    )
                },
                onError: err => {
                    errorAlert(
                        err.message || 'An error occurred, please try again!',
                    )
                },
            })
            return
        }
        const payload = state.auditReportPassword
            ? {
                  financial_analysis_statement: state.auditFile.url,
                  repayment_method:
                      repaymentMethod[financialInfoValues.paymentMethod],
                  estimationId,
                  password: state.auditReportPassword,
              }
            : {
                  financial_analysis_statement: state.auditFile.url,
                  repayment_method:
                      repaymentMethod[financialInfoValues.paymentMethod],
                  estimationId,
              }

        if (userType === 'business') {
            payload.monthly_revenue = String(
                financialInfoValues.monthlyRevenue,
            ).replaceAll(',', '')
        } else if (userType === 'residential') {
            payload.bvn = state.bvn
        }

        updateConsumerEstimation(payload, {
            onSuccess: res => {
                successAlert(res?.data?.message)
                dispatch({
                    type: SHOW_AUDITREPORT_PASSWORD_MODAL,
                    payload: false,
                })
                dispatch({
                    type: SHOW_AUDIT_MODAL,
                    payload: false,
                })
                dispatch({
                    type: SET_AUDIT_REPORT_PASSWORD,
                    payload: '',
                })
                setTimeout(() => {
                    history.push({
                        pathname: '/consumer/account-setup/overview',
                        state: {
                            justCompletedKYC: true,
                            estimationId,
                        },
                    })
                }, 3000)
            },
            onError: err => {
                errorAlert(
                    err.message || 'An error occurred, please try again!',
                )
            },
        })
    }
    const handleUploadBasicBusinessInfo = (
        payload,
        businessData,
        buttonClick = 'Submit',
    ) => {
        submitBusinessContactInfo.mutate(payload, {
            onSuccess: () => {
                submitBusinessInfo.mutate(businessData, {
                    onSuccess: () => {
                        if (buttonClick === 'Submit') {
                            successAlert(`Business info uploaded successfully.`)
                            setTimeout(() => {
                                history.push(
                                    '/consumer/account-setup/business-verification',
                                )
                            }, 3000)
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                eventTrackers[
                                    'sseSubmitBusinessInfoSuccessfully'
                                ].action,
                                eventTrackers[
                                    'sseSubmitBusinessInfoSuccessfully'
                                ].label,
                                eventTrackers[
                                    'sseSubmitBusinessInfoSuccessfully'
                                ].category,
                            )
                            handleAppTrackingOnKYC(
                                'completedKYCCategory',
                                pageTitle,
                            )
                        } else {
                            successAlert(`Business info saved successfully.`)
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                eventTrackers['sseSaveBusinessInfoSuccessfully']
                                    .action,
                                eventTrackers['sseSaveBusinessInfoSuccessfully']
                                    .label,
                                eventTrackers['sseSaveBusinessInfoSuccessfully']
                                    .category,
                            )
                        }
                    },
                })
            },
        })
    }

    const handleAssistedKycSave = (businessInfoPayload, payload) => {
        delete businessInfoPayload.button
        delete payload.button

        const updatedPayload = removeEmptyObjectsV2(payload)

        const handleSubmitBusinessInfo = () => {
            submitBusinessInfoByAdmin.mutate({
                estimationId: id,
                payload: businessInfoPayload,
                handleSuccess: () => {
                    setBusinessUpdateJustCompleted(true)
                    businessInfoFormik.setTouched({})
                },
            })
        }

        if (Object.keys(updatedPayload).length) {
            submitBusinessContactInfoByAdmin.mutate({
                estimationId: id,
                payload: updatedPayload,
                handleSuccess: () => {
                    setBusinessConsumerName(
                        `${payload.first_name || ''} ${
                            payload.last_name || ''
                        }`,
                    )

                    businessContactFormik.setTouched({})

                    if (Object.keys(businessInfoPayload).length) {
                        handleSubmitBusinessInfo()
                    } else {
                        setBusinessUpdateJustCompleted(true)
                    }
                },
            })
        } else if (Object.keys(businessInfoPayload).length) {
            handleSubmitBusinessInfo()
        }
    }

    function handleSubmitKyc(buttonClicked) {
        let businessInfoData = businessInfoFormik.values
        let contactInfoData = businessContactFormik.values

        if (isAdminUser) {
            const businessInfoUpdateData = {}
            const contactInfoUpdateData = {}
            let changedAddress = false

            const addressKeys = [
                'businessAddress',
                'businessCity',
                'businessState',
                'businessCountry',
                'installationAddress',
                'installationCity',
                'installationState',
                'installationCountry',
            ]

            for (const key of Object.keys(businessInfoFormik.touched)) {
                businessInfoUpdateData[key] = businessInfoFormik.values[key]
            }

            for (const key of Object.keys(businessContactFormik.touched)) {
                contactInfoUpdateData[key] = businessContactFormik.values[key]

                if (addressKeys.includes(key)) {
                    changedAddress = true
                }
            }

            if (changedAddress) {
                for (const addressKey of addressKeys) {
                    contactInfoUpdateData[addressKey] =
                        businessContactFormik.values[addressKey]
                }
            }

            businessInfoData = businessInfoUpdateData
            contactInfoData = contactInfoUpdateData
        }

        const businessInfoPayload = removeEmptyValues({
            button: buttonClicked,
            business_name: businessInfoData.businessName,
            business_type: businessInfoData.businessType,
            business_industry: businessInfoData.businessIndustry,
            years_in_operation: businessInfoData.numberOfYearsInOperation,
            business_website: includeHttps(businessInfoData.website),
            business_description: businessInfoData.businessDescription,
        })

        const payload = removeEmptyValues({
            button: buttonClicked,
            first_name: contactInfoData.firstName,
            last_name: contactInfoData.lastName,
            phone: formatPhoneNumber(contactInfoData.phone),
        })
        // Include the director_details only if any of its fields have a value
        const directorDetails = removeEmptyValues({
            first_name: contactInfoData.directorsFirstName,
            last_name: contactInfoData.directorsLastName,
            phone_number: contactInfoData.directorsPhoneNumber
                ? contactInfoData.directorsPhoneNumber.startsWith('+234')
                    ? contactInfoData.directorsPhoneNumber
                    : formatPhoneNumber(
                          contactInfoData.directorsPhoneNumber,
                          '+234',
                      )
                : '',
            email: contactInfoData.directorsEmail,
        })
        payload.director_details = directorDetails

        // Include the contact_address only if any of its fields have a value
        const contactAddress = removeEmptyValues({
            street_address: contactInfoData.businessAddress,
            city: contactInfoData.businessCity,
            state_of_residence: contactInfoData.businessState,
            country: contactInfoData.businessCountry,
        })

        payload.contact_address = contactAddress

        // Include the installation_address only if any of its fields have a value
        const installationAddress = removeEmptyValues({
            street_address: contactInfoData.installationAddress,
            city: contactInfoData.installationCity,
            state: contactInfoData.installationState,
            country: contactInfoData.installationCountry,
        })
        payload.installation_address = installationAddress

        if (currentForm === 'businessInfo') {
            if (submitBusinessInfo.isLoading) return

            if (buttonClicked === 'Submit') {
                validateBusinessInfoKyc
                    .validate(businessInfoFormik.values, {
                        abortEarly: false,
                    })
                    .then(() => {
                        if (isAdminUser) {
                            handleAssistedKycSave(businessInfoPayload, payload)
                        } else {
                            setCurrentForm('contactInfo')
                            setActiveTab('2')
                        }
                    })
                    .catch(err => {
                        const errList = formatYupError(err)
                        businessInfoFormik.setTouched(errList)
                        businessInfoFormik.setErrors(errList)
                        errorAlert(Object.values(errList)[0])
                    })
            } else {
                submitBusinessInfo.mutate(businessInfoPayload)
            }
        } else if (currentForm === 'contactInfo') {
            if (submitBusinessContactInfo.isLoading) return

            if (buttonClicked === 'Submit') {
                validateBusinessContactInfoKyc
                    .validate(businessValues, {
                        abortEarly: false,
                    })
                    .then(() => {
                        setFieldErrors({})

                        if (isAdminUser) {
                            handleAssistedKycSave(businessInfoPayload, payload)
                        } else {
                            handleUploadBasicBusinessInfo(
                                payload,
                                businessInfoPayload,
                            )
                        }
                    })
                    .catch(err => {
                        const errList = formatYupError(err)
                        businessContactFormik.setTouched(errList)
                        businessContactFormik.setErrors(errList)
                        errorAlert(Object.values(errList)[0])
                    })
            } else {
                handleUploadBasicBusinessInfo(
                    payload,
                    businessInfoPayload,
                    'Save',
                )
            }
        }
    }

    const value = {
        formResponse,
        setFormResponse,
        recommendations,
        setRecommendations,
        businessInformationData,
        setBusinessInformationData,
        currentForm,
        setCurrentForm,
        handleSubmitKyc,
        getKycError,
        setGetKycError,
        submitToastMessage,
        setSubmitToastMessage,
        submitToastType,
        fieldErrors,
        setFieldErrors,
        successActions,
        setSuccessActions,
        submitBusinessInfo,
        submitBusinessContactInfo,
        state,
        dispatch,
        handleSubmitBusinessFinancialInfo,
        setPaymentMethod,
        paymentMethod,
        postDocumentToS3,
        isUploadingFileUrl,
        isAuditReportUploading,
        successAlert,
        errorAlert,
        estimationId,
        overviewInfo,
        setOverviewInfo,
        activeTab,
        setActiveTab,
        formId,
        businessInfoFormik,
        businessContactFormik,
        finanicalInfoFormik,
        handleUploadAuditStatement,
        isCustomerApproved,
        simulatedCreditDecision,
        setSimulatedCreditDecision,
        customerType,
        showBankAnalysisModal,
        setShowBankAnalysisModal,
        disableCompletedKYCBtn,
        setDisableCompletedKYCBtn,
        applicationStatus,
        verificationInProgress,
        setVerificationInProgress,
        assistedKycBusinessUpdateLoading:
            submitBusinessInfoByAdmin.isLoading ||
            submitBusinessContactInfoByAdmin.isLoading,
        businessUpdateJustCompleted,
        businessConsumerName,
        setBusinessUpdateJustCompleted,
        setBusinessConsumerName,
    }

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const useAppContext = () => useContext(AppContext)
export default AppContextProvider
