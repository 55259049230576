import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/BusinessForm/businessform.scss'
import s from './formfields.module.scss'
import { sharedFormComponentPropTypes } from '../../../DynamicInformation/components/Form/utils'
import { SelectButtons } from 'src/components/SelectButtons'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { businessTypes } from 'src/utils/Data/businessType'

const BusinessType = ({
    inputs,
    errors,
    handleSelectChange,
    isCustomerApproved,
}) => {
    return (
        <>
            <p className={s.sectionTitle}>Select Your Business Type</p>
            <SelectButtons
                selectedValue={inputs?.businessType}
                values={businessTypes}
                handleChange={value =>
                    handleSelectChange('businessType', value)
                }
                width={'fit-content'}
                height={41}
                padding={'10px 12px'}
                disabled={isCustomerApproved}
                type="secondary"
            />
            {errors?.businessType && (
                <span className="fieldError">{errors?.businessType}</span>
            )}
            <VerticalSpacer top={16} />
        </>
    )
}

BusinessType.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default BusinessType
