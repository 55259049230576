import { useEffect, useState } from 'react'
import useGetCreditVariables from '../../hooks/useGetCreditVariables'

const useSelectVariableDrawer = (handleDrawerClose, drawerVisible) => {
    const [selectedVariables, setSelectedVariables] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchText, setSearchText] = useState('')
    const [listTotal, setListTotal] = useState(1)

    const onRefetchSuccess = data => {
        setListTotal(data?.data?.total_pages)
    }
    const {
        creditVariablesSummaryData,
        creditVariablesRefetch,
        creditVariablesFetching,
    } = useGetCreditVariables({
        filterParams: {
            currentPage: currentPage,
            searchText: searchText,
        },
        onSuccess: onRefetchSuccess,
    })
    const [isOpen, setIsOpen] = useState(
        Array(creditVariablesSummaryData?.length).fill(false),
    )

    const onClose = () => {
        setSearchText('')
        setCurrentPage(1)
        setListTotal(1)
        setSelectedVariables([])
        handleDrawerClose()
    }

    const checkSelected = data => {
        return selectedVariables?.find(sol => sol?.id === data.id)
    }

    const handleSelectVariable = data => {
        const currentVariable = selectedVariables.find(
            sol => sol?.id === data.id,
        )
        if (!currentVariable) {
            setSelectedVariables(prev => [...prev, data])
        } else {
            const otherSelectedVariables = selectedVariables.filter(
                sol => sol?.id !== data.id,
            )
            setSelectedVariables(otherSelectedVariables)
        }
    }

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }

    const allSelected =
        selectedVariables?.length === creditVariablesSummaryData?.length

    const toggleSelectAll = () => {
        if (allSelected) {
            setSelectedVariables([])
        } else setSelectedVariables(creditVariablesSummaryData)
    }

    const handlePage = pageNumber => {
        setSearchText('')
        setCurrentPage(pageNumber)
    }

    const handleInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleSearch = () => {
        if (currentPage > 1) {
            setCurrentPage(1)
        } else {
            creditVariablesRefetch()
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    useEffect(() => {
        setIsOpen(Array(creditVariablesSummaryData?.length).fill(false))
    }, [creditVariablesSummaryData?.length])

    useEffect(() => {
        if (drawerVisible) {
            creditVariablesRefetch()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, drawerVisible])

    useEffect(() => {
        if (searchText === '') {
            handleSearch()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])

    return {
        onClose,
        allSelected,
        toggleSelectAll,
        selectedVariables,
        checkSelected,
        handleSelectVariable,
        toggleBox,
        isOpen,
        creditVariablesSummaryData,
        setSelectedVariables,
        handlePage,
        handleInputChange,
        handleBlur,
        handleSearch,
        searchText,
        listTotal,
        currentPage,
        handleKeyPress,
        creditVariablesFetching,
    }
}
export default useSelectVariableDrawer
