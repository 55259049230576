import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import InfoIcon from 'src/assets/images/infoIcon.svg'
import SuccessIcon from 'src/assets/images/approved.svg'
import { format, parseISO } from 'date-fns'
import RecommendedSolutionUpdate from 'src/pages/Consumer/ConsumerWorkspace/components/RecentUpdate/RecommendedSolutionUpdate'
import overviewNoActivity from 'src/assets/images/overviewNoActivity.svg'
import SkeletalView from 'src/pages/Consumer/ConsumerWorkspace/components/RecentUpdate/SkeletalView'
import { categories } from '../data'
import IdentityUpdate from 'src/pages/Consumer/ConsumerWorkspace/components/RecentUpdate/IdentityUpdate'
import styles from './recentupdate.module.scss'

const RecentUpdates = ({
    data,
    loading,
    handleSubscriptionPlan,
    activePlans,
    handleShowPaymentPlan,
    handlePaystackModal,
    handleLeaseRepaymentSetup,
    handleSendAgreementReminder,
    btnLoading,
}) => {
    const {
        RowWrapper,
        StatusImageWrapper,
        StatusImageWrapperV2,
        ImgStyle,
        RecentActivityColumn,
        RecentActivityWrapper,
        RecentActivityV2,
        RecentActivityDate,
        NoActivityWrapper,
        NoActivityHeading,
        NoActivitySubheading,
        VerticalLine,
    } = styles
    const history = useHistory()

    const EmptyUpdate = () => {
        return (
            <div className={NoActivityWrapper}>
                <img src={overviewNoActivity} alt="no activity" />
                <p className={NoActivityHeading}>No recent update</p>
                <p className={NoActivitySubheading}>
                    Notifications that require your action will be displayed
                    here.
                </p>
            </div>
        )
    }

    const filterPlanType = estimation_id => {
        const result = activePlans?.find(active => {
            return active.id === estimation_id
        })

        return {
            payment_plan_type: result?.packageType,
            plan: result?.plan,
        }
    }

    return (
        <>
            {loading ? (
                <SkeletalView />
            ) : data.length === 0 ? (
                <EmptyUpdate />
            ) : (
                <div className={RowWrapper}>
                    <div
                        className={`${StatusImageWrapper} ${StatusImageWrapperV2}`}
                    >
                        {data?.map((update, id) => {
                            return (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                        key={id}
                                    >
                                        <div key={id}>
                                            <img
                                                src={
                                                    [
                                                        categories?.personalInformation,
                                                        categories?.creditInformation,
                                                        categories?.energyInformation,
                                                        categories?.recommendedSolutions,
                                                        categories?.upfrontDeposit,
                                                        categories?.repaymentSetup,
                                                        categories?.subscriptionPayment,
                                                        categories?.selectPaymentPlan,
                                                        categories?.agreementSigning,
                                                    ]?.includes(
                                                        update?.category,
                                                    )
                                                        ? InfoIcon
                                                        : SuccessIcon
                                                }
                                                className={ImgStyle}
                                                style={{
                                                    backgroundColor: [
                                                        categories?.personalInformation,
                                                        categories?.creditInformation,
                                                        categories?.energyInformation,
                                                        categories?.recommendedSolutions,
                                                        categories?.upfrontDeposit,
                                                        categories?.repaymentSetup,
                                                        categories?.subscriptionPayment,
                                                        categories?.selectPaymentPlan,
                                                        categories?.agreementSigning,
                                                    ]?.includes(
                                                        update?.category,
                                                    )
                                                        ? '#FFFAEA'
                                                        : '#F1FFF6',
                                                }}
                                            />

                                            <div
                                                className={
                                                    data?.length > 1
                                                        ? VerticalLine
                                                        : ''
                                                }
                                                style={{
                                                    height: [
                                                        categories?.personalInformation,
                                                        categories?.creditInformation,
                                                        categories?.energyInformation,
                                                        categories?.recommendedSolutions,
                                                        categories?.upfrontDeposit,
                                                        categories?.repaymentSetup,
                                                        categories?.subscriptionPayment,
                                                        categories?.selectPaymentPlan,
                                                        categories?.agreementSigning,
                                                    ]?.includes(
                                                        update?.category,
                                                    )
                                                        ? '102px'
                                                        : '35px',
                                                }}
                                            ></div>
                                        </div>
                                        <div className={RecentActivityColumn}>
                                            <>
                                                <div
                                                    className={
                                                        RecentActivityWrapper
                                                    }
                                                    key={id}
                                                >
                                                    <p
                                                        className={
                                                            RecentActivityV2
                                                        }
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    'SF-Pro-Display-Medium',
                                                            }}
                                                        >
                                                            {[
                                                                categories?.personalInformation,
                                                                categories?.creditInformation,
                                                                categories?.energyInformation,
                                                                categories?.recommendedSolutions,
                                                                categories?.upfrontDeposit,
                                                                categories?.repaymentSetup,
                                                                categories?.subscriptionPayment,
                                                                categories?.selectPaymentPlan,
                                                                categories?.agreementSigning,
                                                            ]?.includes(
                                                                update?.category,
                                                            )
                                                                ? 'Action Required: '
                                                                : 'Update: '}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    'SF-Pro-Display-Medium',
                                                            }}
                                                        >
                                                            {update.category ===
                                                            'upfront_deposit'
                                                                ? `${
                                                                      update?.title
                                                                  } For ${
                                                                      filterPlanType(
                                                                          update?.estimation_id,
                                                                      )?.plan
                                                                  }`
                                                                : update?.title}
                                                        </span>
                                                    </p>

                                                    <p
                                                        className={
                                                            RecentActivityDate
                                                        }
                                                    >
                                                        {update?.created_at !==
                                                            undefined &&
                                                            format(
                                                                parseISO(
                                                                    update?.created_at,
                                                                ),
                                                                'MMM d, yyyy',
                                                            )}
                                                    </p>
                                                </div>
                                                {update?.category ===
                                                'recommended_solutions' ? (
                                                    <RecommendedSolutionUpdate
                                                        note={update?.note}
                                                        handleShowModal={() =>
                                                            history.push(
                                                                `/consumer/update-application?id=${update?.estimation_id}`,
                                                            )
                                                        }
                                                        btnText="unified_workspace"
                                                        v2
                                                    />
                                                ) : update?.category ===
                                                  'signed_agreement' ? (
                                                    <RecommendedSolutionUpdate
                                                        page="agreementSigning"
                                                        handleShowModal={() => {
                                                            handleSendAgreementReminder()
                                                        }}
                                                        btnText={
                                                            'agreementSigning'
                                                        }
                                                        note={
                                                            'To continue, you’ll need to sign your agreement'
                                                        }
                                                        v2
                                                    />
                                                ) : update?.category ===
                                                  'upfront_deposit' ? (
                                                    <RecommendedSolutionUpdate
                                                        page="upfrontDeposit"
                                                        handleShowModal={() => {
                                                            handlePaystackModal()
                                                        }}
                                                        btnText={
                                                            'upfrontDeposit'
                                                        }
                                                        isFetching={btnLoading}
                                                        note={`${update.amount}`}
                                                        isAmount={true}
                                                        v2
                                                    />
                                                ) : update?.category ===
                                                      'repayment_setup' &&
                                                  [
                                                      'Subscription',
                                                      'Lease to Own',
                                                  ].includes(
                                                      filterPlanType(
                                                          update?.estimation_id,
                                                      )?.payment_plan_type,
                                                  ) ? (
                                                    <RecommendedSolutionUpdate
                                                        page="subscription_payment"
                                                        handleShowModal={() => {
                                                            filterPlanType(
                                                                update?.estimation_id,
                                                            )
                                                                ?.payment_plan_type ===
                                                            'Lease to Own'
                                                                ? handleLeaseRepaymentSetup()
                                                                : handleSubscriptionPlan()
                                                        }}
                                                        isFetching={btnLoading}
                                                        note={`Set up repayment for ${filterPlanType(
                                                            update?.estimation_id,
                                                        )?.plan.toLowerCase()}`}
                                                        v2
                                                    />
                                                ) : update?.category ===
                                                  'select_payment_plan' ? (
                                                    <RecommendedSolutionUpdate
                                                        page="select_payment_plan"
                                                        handleShowModal={() =>
                                                            handleShowPaymentPlan()
                                                        }
                                                        note={`${update.number_of_plans} plans available`}
                                                        v2
                                                    />
                                                ) : update?.category ===
                                                  'identification' ? (
                                                    <IdentityUpdate />
                                                ) : [
                                                      categories?.personalInformation,
                                                  ]?.includes(
                                                      update?.category,
                                                  ) ? (
                                                    <RecommendedSolutionUpdate
                                                        page="personalInformation"
                                                        handleShowModal={() =>
                                                            history.push(
                                                                `/consumer/update-application?id=${update?.estimation_id}`,
                                                            )
                                                        }
                                                        btnText="unified_workspace"
                                                        note=""
                                                        v2
                                                    />
                                                ) : [
                                                      categories?.creditInformation,
                                                  ]?.includes(
                                                      update?.category,
                                                  ) ? (
                                                    <RecommendedSolutionUpdate
                                                        page="creditInformation"
                                                        handleShowModal={() =>
                                                            history.push(
                                                                `/consumer/update-application?id=${update?.estimation_id}`,
                                                            )
                                                        }
                                                        btnText="unified_workspace"
                                                        note=""
                                                        v2
                                                    />
                                                ) : [
                                                      categories?.energyInformation,
                                                  ]?.includes(
                                                      update?.category,
                                                  ) ? (
                                                    <RecommendedSolutionUpdate
                                                        page="energyInformation"
                                                        handleShowModal={() =>
                                                            history.push(
                                                                `/consumer/update-application?id=${update?.estimation_id}`,
                                                            )
                                                        }
                                                        btnText="unified_workspace"
                                                        note=""
                                                        v2
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}

RecentUpdates.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array.isRequired,
    activePlans: PropTypes.array.isRequired,
    handleShowPaymentPlan: PropTypes.func.isRequired,
    handleSubscriptionPlan: PropTypes.func.isRequired,
    handlePaystackModal: PropTypes.func.isRequired,
    handleLeaseRepaymentSetup: PropTypes.func.isRequired,
    handleSendAgreementReminder: PropTypes.func.isRequired,
    btnLoading: PropTypes.bool,
}

export default RecentUpdates
