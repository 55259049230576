/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from 'react'
import { Route, Link } from 'react-router-dom'
import Navigation from '../Navigation'
import FloatingBox from '../FloatingBox'
import QuestionMarkIcon from '../../assets/images/questionMark.svg'
import ConsumerHelpCenter from '../HelpCenter/ConsumerHelpCenter/index'
import styles from './appcontainerroute.module.scss'
import SignInNavigation from '../Navigation/SignInNavigation'
import AccountSetupFooter from '../ConsumerFooter/AccountSetupFooter'

/**
 * Renders the component
 *
 * @param {Component} Component
 *
 * @returns {JSX}
 */

/**
 * This Component returns a new component
 * @returns {JSX}
 */
const AppContainerRoute = props => {
    const [showText, setShowText] = useState(false)
    const [navHeight, setNavHeight] = useState(null)
    const navRef = useRef()

    useEffect(() => {
        if (navRef?.current?.clientHeight) {
            setNavHeight(navRef?.current?.clientHeight)
        }
    }, [navRef])

    const updateShowText = (value = true) => {
        setShowText(value)
    }

    const minHeight = navHeight ? `calc(100vh - ${navHeight}px)` : `100%`

    const { component: Component, ...rest } = props
    const {
        Container,
        NoBgContainer,
        TextSection,
        SubText,
        PageText,
        LeftSectionClass,
        RightSectionClass,
        MaxWidthContainer,
        FullWidthContainer,
        MinHeightContainer,
        LeftSectionContainer,
        ConsumerAppNavHeader,
        ConsumerFloatBox,
        ConsumerFooter,
    } = styles

    const Text = () => (
        <div className={LeftSectionClass}>
            <div className={TextSection}>
                <div className={PageText}>
                    Your Reliable Solar Partner 
                </div>
                <div className={SubText}>
                    This is your space to manage your deals effortlessly.
                    Together, we’re powering a brighter future!
                </div>
            </div>
        </div>
    )

    return (
        <div className={MinHeightContainer}>
            {props.type === 'consumer' ? (
                <div ref={navRef}>
                    <Navigation
                        bgColor="var(--white)"
                        textColor="var(--lightblue)"
                        page="ConsumerAppHome"
                        className={ConsumerAppNavHeader}
                        fontSize="12px"
                    />
                </div>
            ) : props.type === 'sign-in' ? (
                <SignInNavigation />
            ) : (
                <div ref={navRef}>
                    <Navigation
                        bgColor="var(--white)"
                        textColor="var(--white)"
                        page="appHome"
                    />
                </div>
            )}
            <div
                className={
                    ['sign-in', 'consumer-sse'].includes(props.type)
                        ? NoBgContainer
                        : Container
                }
                style={{
                    minHeight,
                    backgroundPosition: showText ? ' 0% 130%' : ' 0% 170%',
                }}
            >
                {['sign-in', 'consumer-sse'].includes(props.type) ? (
                    <div className={FullWidthContainer}>
                        <Route
                            {...rest}
                            render={props =>
                                Component === undefined ? (
                                    <></>
                                ) : (
                                    <Component
                                        showTextHandler={updateShowText}
                                        {...props}
                                    />
                                )
                            }
                        />
                    </div>
                ) : (
                    <div className={MaxWidthContainer}>
                        <div className={LeftSectionContainer}>
                            {!props.noText ? (
                                <Text />
                            ) : showText ? (
                                <Text />
                            ) : (
                                ''
                            )}

                            <div className={RightSectionClass}>
                                <Route
                                    {...rest}
                                    render={props =>
                                        Component === undefined ? (
                                            <></>
                                        ) : (
                                            <Component
                                                showTextHandler={updateShowText}
                                                {...props}
                                            />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {props.type === 'consumer' && (
                    <div>
                        <FloatingBox
                            floatIcon={QuestionMarkIcon}
                            floatBgColor="#011A3C"
                            floatBoxClass={ConsumerFloatBox}
                            renderComponent={props => (
                                <ConsumerHelpCenter {...props} />
                            )}
                        />
                    </div>
                )}
            </div>
            {['sign-in', 'consumer-sse'].includes(props.type) ? (
                <AccountSetupFooter />
            ) : (
                <footer className={ConsumerFooter}>
                    <div>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                    <div>
                        <Link to="/privacy-policy">Terms of Service</Link>
                    </div>
                </footer>
            )}
        </div>
    )
}

export default AppContainerRoute
