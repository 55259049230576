import { useState } from 'react'
import { getAdminCreditVariablesApi } from 'src/api/admin/credit-variables/listing'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { useQuery } from 'react-query'
import { formatCreditVariablesSummaryData } from '../data'

const useGetCreditVariables = ({ filterParams = {}, onSuccess }) => {
    const [creditVariablesData, setCreditVariablesData] = useState([])
    const [creditVariablesSummaryData, setCreditVariablesSummaryData] =
        useState([])
    const { errorAlert } = useCustomToast()

    const {
        refetch: creditVariablesRefetch,
        isFetching: creditVariablesFetching,
    } = useQuery({
        queryKey: 'admin-credit-variables-list',
        queryFn: () =>
            getAdminCreditVariablesApi(
                filterParams?.currentPage || 1,
                filterParams?.paymentModel || '',
                filterParams?.searchText || '',
            ),
        retry: false,
        enabled: false,
        refetchOnMount: true,
        onSuccess: data => {
            const variablesData = data?.data?.data
            const summaryData = formatCreditVariablesSummaryData(variablesData)
            setCreditVariablesData(variablesData)
            setCreditVariablesSummaryData(summaryData)
            onSuccess?.(data)
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch plan details. please try again`,
            )
        },
    })

    return {
        creditVariablesData,
        creditVariablesRefetch,
        creditVariablesFetching,
        creditVariablesSummaryData,
    }
}

export default useGetCreditVariables
