import { useMutation, useQuery } from 'react-query'
import {
    sseRetrieveBasicInfo,
    sseSubmitEmploymentInfo,
    sseSubmitPersonalInfo,
    sseVerifyConsumerBusiness,
} from './api'
import { decodeUserInfo } from 'src/utils/auth'
import { useParams, useHistory } from 'react-router-dom'
import { getConsumerKYCInfoForAdmin } from '../account-overview'

export const useSSERetrieveBasicInfo = ({ enabled, onSuccess, onError }) => {
    // estimation_id from params used by admin
    const { id } = useParams()
    const history = useHistory()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const isDynamicKycUser = history?.location?.state?.isDynamicKycUser
    const estimationId = history?.location?.state?.estimationId

    const { data, refetch, isFetching } = useQuery(
        'sse-basic-info',
        () => {
            let estimationID = ''

            if (isAdminUser) {
                return getConsumerKYCInfoForAdmin(
                    isDynamicKycUser ? estimationId : id,
                )
            }

            if (
                process.env
                    .REACT_APP_FLAG_SHOW_KYC_DETAILS_FOR_ASSOCIATED_ACTIVE_PLAN ===
                    'true' &&
                estimationId
            ) {
                estimationID = estimationId
            }

            return sseRetrieveBasicInfo(estimationID)
        },
        {
            enabled: isAdminUser ? enabled && id !== null : enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
            },
        },
    )

    return {
        sseBasicInfoRefetch: refetch,
        sseBasicInfoFetching: isFetching,
        sseBasicInfoData: data,
    }
}

export const useSSESubmitPersonalInfo = (options = {}) => {
    const { onSuccess, onError } = options
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseSubmitPersonalInfo(payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data?.errors)
            }
        },
    })

    return {
        submitPersonalInfo: mutate,
        personalInfoLoading: isLoading,
        personalInfoData: data,
        personalInfoError: error,
        personalInfoSuccess: isSuccess,
    }
}

export const useSSESubmitEmploymentInfo = (options = {}) => {
    const { onSuccess, onError } = options
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseSubmitEmploymentInfo(payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        submitEmploymentInfo: mutate,
        employmentInfoLoading: isLoading,
        employmentInfoData: data,
        employmentInfoError: error,
        employmentInfoSuccess: isSuccess,
    }
}

export const useSSEVerifyConsumerBusiness = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseVerifyConsumerBusiness(payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        verifyConsumerBusiness: mutate,
        consumerBusinessVerificationLoading: isLoading,
        consumerBusinessVerificationData: data,
        consumerBusinessVerificationError: error,
        consumerBusinessVerificationSuccess: isSuccess,
    }
}
