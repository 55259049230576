import Label from '../../../../PersonalInformation/_components/Label'
import { InputFields } from 'src/components/InputFields'
import { sharedFormComponentPropTypes } from '../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import s from '../styles.module.scss'

const FullName = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
    page,
}) => {
    const isOnePageDynamicKyc = page === 'one-page-dynamic-kyc'
    const fieldMarginBottom = isOnePageDynamicKyc ? '16px' : '20px'
    const disableFieldForAdmins = isAdminUser && page !== 'one-page-dynamic-kyc'
    return (
        <>
            {!isOnePageDynamicKyc && (
                <Label
                    title={"What's your name?"}
                    subTitle={
                        'Please provide your first name and surname as it appears on your ID card'
                    }
                />
            )}
            <div className={isOnePageDynamicKyc ? s.fullName : ''}>
                <InputFields
                    name="firstName"
                    title="First Name"
                    value={inputs?.firstName}
                    handleChange={handleChange}
                    errorMessage={errors?.firstName}
                    onBlur={handleOnBlur}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />

                <InputFields
                    name="lastName"
                    title={isOnePageDynamicKyc ? 'Last Name' : 'Surname'}
                    value={inputs?.lastName}
                    handleChange={handleChange}
                    errorMessage={errors?.lastName}
                    onBlur={handleOnBlur}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />
            </div>
            {!isOnePageDynamicKyc && <VerticalSpacer top={12} />}
        </>
    )
}

FullName.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default FullName
