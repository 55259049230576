import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import s from '../styles.module.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { ConsumerDateField, SelectField } from 'src/components/InputFields'
import { format } from 'date-fns'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'
import { pensionManagers } from 'src/utils/pensionManagers'

const PensionDetails = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    isAdminUser,
    showCalendar,
    setShowCalendar,
    isCustomerApproved,
    v2 = false,
    onePageKyc,
    page,
}) => {
    const isOnePageDynamicKyc = page === 'one-page-dynamic-kyc'
    const fieldMarginBottom = isOnePageDynamicKyc ? '16px' : '20px'
    const disableFieldForAdmins = isAdminUser && page !== 'one-page-dynamic-kyc'

    return (
        <>
            {!v2 && !isOnePageDynamicKyc && (
                <>
                    <Label
                        title={'Employment details'}
                        subTitle={
                            'Provide us with more details about your work'
                        }
                    />

                    <>
                        <IconInputField
                            icon={LinkIcon}
                            name="linkedinPage"
                            title="Linkedin Page"
                            width={'100%'}
                            value={inputs?.linkedinPage}
                            handleChange={handleChange}
                            errorMessage={errors?.linkedinPage}
                            disabled={isAdminUser || isCustomerApproved}
                        />
                        {!errors?.linkedinPage && (
                            <p className="OptionalTypoGraphy">Optional</p>
                        )}
                    </>
                </>
            )}
            <div
                className={
                    isOnePageDynamicKyc || onePageKyc ? s.pensionDetails : ''
                }
            >
                <SelectField
                    floatLabelOnSelectedValue
                    floatingLabel="Pension Manager"
                    name="pensionManager"
                    initialOption="Pension Manager"
                    selectWidth="100%"
                    values={pensionManagers.map(option => ({
                        value: option,
                    }))}
                    value={inputs.pensionManager}
                    errorMessage={errors.pensionManager}
                    currentSelected={inputs?.pensionManager}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('pensionManager')}
                    withCheckBox
                    dropdownPositionRelative
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                />

                <div className="DynamicInfoDateFieldWrapper">
                    <ConsumerDateField
                        title={
                            v2 || isOnePageDynamicKyc
                                ? 'Date of Retirement'
                                : 'When did you retire?'
                        }
                        width={'100%'}
                        name="retirementDate"
                        caretStyle="caretStyle"
                        dateValue={
                            inputs?.retirementDate?.length === 0
                                ? null
                                : new Date(inputs?.retirementDate)
                        }
                        value={inputs?.retirementDate}
                        errorMessage={errors?.retirementDate}
                        handleDateChange={(name, date) => {
                            handleChange({
                                target: {
                                    name: 'retirementDate',
                                    value: format(
                                        new Date(date),
                                        onePageKyc || isOnePageDynamicKyc
                                            ? 'yyyy-MM-dd'
                                            : 'MMM dd, yyyy',
                                    ),
                                },
                            })
                        }}
                        closeCalendarHandler={() => {
                            setShowCalendar(false)
                            if (!inputs.retirementDate) {
                                handleOnBlur({
                                    target: {
                                        name: 'retirementDate',
                                        value: inputs.retirementDate,
                                    },
                                })
                            }
                        }}
                        openCalendar={showCalendar}
                        openCalendarHandler={() => {
                            setShowCalendar(true)
                        }}
                        disabled={disableFieldForAdmins || isCustomerApproved}
                        marginBottom={fieldMarginBottom}
                    />
                </div>
            </div>
        </>
    )
}

PensionDetails.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default PensionDetails
