import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Upload, message } from 'antd'
import Button from '../../../components/Button'
import Toast from '../../../components/Toast'
import GetModal from '../../../components/Modal'
import { InlineLoader } from '../../../components/Loader'
import ImageEditor from '../../Onboarding/Steps/ImageEditor'
import { WebCamCapture } from '../../Onboarding/Steps/Webcam'
import {
    InputFields,
    SelectField,
    TextArea,
} from '../../../components/InputFields'
import { SelectCountryCode } from '../../../components/InputFields/SelectCountryCode'
import { stateData } from '../../../utils/stateData'
import {
    updateAiInformationApi,
    getAiInformationApi,
} from '../../../api/associateinstaller/index.js'
import { uploadImageApi } from '../../../api/verificationIdentity'
import { decodeUserInfo, updateUserInfo } from '../../../utils/auth'
import { AIupdateInfoValidationSchema } from '../../../utils/validationSchema'
import { errorHandler } from '../../../utils/errorHandler'
import EmptyProfilePic from '../../../components/EmptyProfilePic'
import { formatInputValue, formatPhoneNumber } from '../../../utils/formatting'
import { getProfileInformationApi } from '../../../api/profile'

import styles from './asipersonalinfo.module.scss'

const ASIPersonalInfo = () => {
    const {
        Wrapper,
        Row,
        LeftWrapper,
        Header,
        SubHeader,
        ProfileImg,
        SelfieWrapper,
        FullName,
        States,
        SaveBtn,
        editorStyle,
        MobileFieldWrapper,
        MobileImageWrapper,
        BioWrapper,
    } = styles

    const userInfo = decodeUserInfo()

    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errors, setErrors] = useState({})
    const [fileList, setFileList] = useState([])
    const [fileValid, setFileValid] = useState(true)
    const [showEditor, setShowEditor] = useState(false)
    const [pictureUrl, setPictureUrl] = useState('')
    const [type, setType] = useState('')
    const [editedPicture, setEditedPicture] = useState('')
    const [uploading, setUploading] = useState(false)
    const [enableCamera, setEnableCamera] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [verifyPhone, setVerifyPhone] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [countryCode] = useState('+234')
    const [avatarUrl, setAvatarUrl] = useState(null)

    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        phoneNumber: '',
        email: '',
        bio: '',
        street: '',
    })

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: formatInputValue(name, value),
        }))
    }

    const handleBlur = event => {
        const { name, value } = event.target

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const props = {
        name: 'file',

        onDrop() {
            setUploading(false)
        },
    }

    const onChange = ({ fileList: newFileList }) => {
        onPreview(newFileList[0])
        setFileList(newFileList)
        setFileValid(true)
    }

    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        if (fileValid) {
            setShowEditor(true)
            setPictureUrl(src)
            setType('upload')
        }
    }

    const uploadSelfie = img => {
        setType('selfie')
        setPictureUrl(img)
        setShowEditor(true)
    }

    const beforeUpload = file => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        const isLt2M = file.size / 1000 / 1000 < 3

        if (!isJPG) {
            message.error('You can only upload JPG or PNG file!')
            setFileValid(false)
            return false
        } else if (!isLt2M) {
            message.error('Image should be less than 3mb')
            setFileValid(false)
            return false
        } else {
            return true
        }
    }

    // eslint-disable-next-line no-unused-vars
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const handleUpdateSuccess = () => {
        setToastError(false)
        setErrors({})
        AIupdateInfoValidationSchema.validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                refetch()
                if (pictureUrl !== '') {
                    avatarRefetch()
                }
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { refetch: refetchUserProfile } = useQuery(
        'getAsiUserProfile',
        () => getProfileInformationApi(),
        {
            enabled: false,
            retry: false,
        },
    )

    const {
        isFetching: profileLoading,
        data,
        refetch,
    } = useQuery(
        'updateAiInformation',
        () =>
            updateAiInformationApi({
                street_address: inputs.street,
                city: inputs.city,
                state_of_residence: inputs.state,
                bio: inputs.bio,
                first_name: inputs.firstName,
                last_name: inputs.lastName,
                phone_number: formatPhoneNumber(
                    inputs.phoneNumber,
                    countryCode,
                ),
                email: inputs.email,
            }),
        {
            enabled: false,
            retry: false,
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to update your information. Try Again')
            },
            onSuccess: data => {
                updateUserInfo(data?.data?.data)
                !avatarLoading &&
                    setSuccessMessage('Your profile has been updated')
            },
        },
    )

    const { isFetching: avatarLoading, refetch: avatarRefetch } = useQuery(
        'uploadImage',
        () => uploadImageApi(pictureUrl),
        {
            retry: false,
            enabled: false,
            onSuccess: () => {
                setSuccessMessage('Your profile has been updated')
                refetchUserProfile()
            },
            onError: error => setErrorMessage(errorHandler(error)),
        },
    )
    const { refetch: getInfoRefetch } = useQuery(
        'getAiInformation',
        () => getAiInformationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setInputs(preState => ({
                    ...preState,
                    firstName: data?.data?.data?.first_name,
                    lastName: data?.data?.data?.last_name,
                    city: data?.data?.data?.city,
                    state: data?.data?.data?.state_of_residence,
                    street: data?.data?.data?.street_address,
                    bio: data?.data?.data?.bio,
                    phoneNumber: data?.data?.data?.phone_number.replace(
                        countryCode,
                        '',
                    ),
                    email: data?.data?.data?.email,
                }))
                setAvatarUrl(data?.data?.data?.avatar_url)
                setVerifyEmail(data?.data?.data?.email_verified)
                setVerifyPhone(data?.data?.data?.phone_verified)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to get your information. Refresh Page')
            },
        },
    )

    useEffect(() => {
        setToastError(false)
        getInfoRefetch()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        successMessage.length > 0 &&
            setTimeout(function () {
                setSuccessMessage('')
            }, 5000)
    }, [successMessage])
    return (
        <>
            {(enableCamera || showEditor) && (
                <GetModal
                    showModal={modalOpen}
                    closable={false}
                    modalHeight="auto"
                    modalBorderRadius="small"
                    app="workspace"
                    modalWidth="624px"
                    noPaddingBoth
                    content={
                        <>
                            {showEditor && (
                                <ImageEditor
                                    setShowEditor={setShowEditor}
                                    pictureUrl={pictureUrl}
                                    setEditedPicture={setEditedPicture}
                                    setEnableCamera={setEnableCamera}
                                    type={
                                        type === 'selfie' ? 'selfie' : 'upload'
                                    }
                                    imageEditorStyle={editorStyle}
                                />
                            )}

                            {enableCamera && (
                                <WebCamCapture
                                    uploadSelfie={uploadSelfie}
                                    setEnableCamera={setEnableCamera}
                                    mediumWidth="624"
                                />
                            )}
                        </>
                    }
                />
            )}
            <>
                <div className={Wrapper}>
                    {toastError ? (
                        <Toast messageType="error" message={errorMessage} />
                    ) : data ? (
                        <Toast messageType="success" message={successMessage} />
                    ) : (
                        ''
                    )}
                    <div className={Row}>
                        <div className={LeftWrapper}>
                            <p className={Header}>Profile Picture</p>
                            <p className={SubHeader}>
                                Upload or change your profile picture
                            </p>
                        </div>

                        <div className={MobileImageWrapper}>
                            {editedPicture ||
                            (avatarUrl !== null && avatarUrl !== 'null') ? (
                                <img
                                    src={
                                        editedPicture
                                            ? editedPicture
                                            : avatarUrl
                                    }
                                    className={ProfileImg}
                                    alt="avatar"
                                />
                            ) : (
                                <EmptyProfilePic
                                    firstLetter={
                                        userInfo?.companyName === 'none' ||
                                        userInfo?.workspaceMember === 'true'
                                            ? userInfo?.firstName
                                                  ?.substring(0, 1)
                                                  ?.toUpperCase()
                                            : userInfo?.companyName
                                                  ?.substring(0, 1)
                                                  ?.toUpperCase()
                                    }
                                    secondLetter={
                                        userInfo?.companyName === 'none' ||
                                        userInfo?.workspaceMember === 'true'
                                            ? userInfo?.lastName
                                                  ?.substring(0, 1)
                                                  ?.toUpperCase()
                                            : userInfo?.companyName
                                                  ?.substring(1, 2)
                                                  ?.toUpperCase()
                                    }
                                    profilePage={true}
                                />
                            )}
                            {uploading && <p> Uploading Picture... </p>}
                            <Upload
                                fileList={fileList}
                                beforeUpload={beforeUpload}
                                showUploadList={false}
                                maxCount={1}
                                onChange={onChange}
                                customRequest={dummyRequest}
                                accept="image/jpeg,image/png"
                                {...props}
                            >
                                <Button
                                    btnBgColor="var(--purple-light)"
                                    btnTextColor="var(--blue)"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="105px"
                                    btnHeight="44px"
                                    handleClick={() => setModalOpen(true)}
                                >
                                    Change
                                </Button>
                            </Upload>
                            <span className={SelfieWrapper}>
                                OR
                                <span
                                    className="Selfie"
                                    onClick={() => {
                                        setEnableCamera(true)
                                        setModalOpen(true)
                                    }}
                                >
                                    Take a Selfie
                                </span>
                            </span>
                        </div>
                    </div>

                    <div className={Row}>
                        <div className={LeftWrapper}>
                            <p className={Header}>Full Name</p>
                            <p className={SubHeader}>
                                Enter your first and last name
                            </p>
                        </div>

                        <div className={FullName}>
                            <InputFields
                                inputWidth="185px"
                                title="First Name"
                                name="firstName"
                                value={inputs.firstName}
                                inputValue={inputs.firstName}
                                handleChange={handleInputChange}
                                errorMessage={errors?.firstName}
                                onBlur={handleBlur}
                            />
                            <InputFields
                                inputWidth="185px"
                                title="Last Name"
                                name="lastName"
                                value={inputs.lastName}
                                inputValue={inputs.lastName}
                                handleChange={handleInputChange}
                                errorMessage={errors?.lastName}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <div className={Row}>
                        <div className={LeftWrapper}>
                            <p className={Header}>
                                {verifyPhone && 'Verified'} Phone Number
                            </p>
                            <p className={SubHeader}>
                                Provide your phone number
                            </p>
                        </div>

                        <div
                            className={MobileFieldWrapper}
                            style={{ width: '389px' }}
                        >
                            <SelectCountryCode
                                inputWidth="100%"
                                title="Phone Number"
                                name="phoneNumber"
                                value={inputs.phoneNumber}
                                inputValue={inputs.phoneNumber}
                                handleChange={handleInputChange}
                                disabled={verifyPhone}
                                prefilled={verifyPhone}
                                errorMessage={errors.phoneNumber}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <div className={Row}>
                        <div className={LeftWrapper}>
                            <p className={Header}>
                                {verifyEmail && 'Verified'} Email Address
                            </p>
                            <p className={SubHeader}>
                                Enter your email address
                            </p>
                        </div>

                        <div
                            className={MobileFieldWrapper}
                            style={{ width: '389px' }}
                        >
                            <InputFields
                                inputWidth="100%"
                                title="Email Address"
                                prefilled={verifyEmail}
                                name="email"
                                disabled={verifyEmail}
                                value={inputs.email}
                                inputValue={inputs.email}
                                handleChange={handleInputChange}
                                errorMessage={errors?.email}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <div className={Row}>
                        <div className={LeftWrapper}>
                            <p className={Header}>Address</p>
                            <p className={SubHeader}>Enter your address</p>
                        </div>

                        <div
                            style={{ width: '389px' }}
                            className={MobileFieldWrapper}
                        >
                            <InputFields
                                inputWidth="100%"
                                title="Street Address"
                                value={inputs.street}
                                inputValue={inputs.street}
                                handleChange={handleInputChange}
                                name="street"
                                errorMessage={errors?.street}
                                onBlur={handleBlur}
                            />
                            <div className={States}>
                                <InputFields
                                    inputWidth="185px"
                                    title="City"
                                    value={inputs.city}
                                    inputValue={inputs.city}
                                    handleChange={handleInputChange}
                                    name="city"
                                    errorMessage={errors?.city}
                                    onBlur={handleBlur}
                                />
                                <SelectField
                                    values={stateData.map(option => ({
                                        value: option,
                                    }))}
                                    name="state"
                                    withCheckBox={true}
                                    showSearch
                                    selectWidth="185px"
                                    currentSelected={inputs.state}
                                    handleChange={handleSelectChange}
                                    initialOption={inputs.state || 'State'}
                                    selectedValue={inputs.state}
                                    errorMessage={errors?.state}
                                    dropdownPositionRelative
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`${BioWrapper} ${Row}`}>
                        <div className={LeftWrapper}>
                            <p className={Header}>Short Bio</p>
                            <p className={SubHeader}>
                                Provide a short bio of yourself
                            </p>
                        </div>

                        <div>
                            <TextArea
                                inputWidth="185px"
                                title="Describe yourself briefly"
                                width="389px"
                                height="128px"
                                name="bio"
                                inputValue={inputs.bio}
                                value={inputs.bio}
                                rows={10}
                                handleChange={handleInputChange}
                                errorMessage={errors?.bio}
                            />
                        </div>
                    </div>

                    <div className={SaveBtn}>
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnWidth="160px"
                            btnHeight="56px"
                            handleClick={handleUpdateSuccess}
                        >
                            {profileLoading || avatarLoading ? (
                                <InlineLoader />
                            ) : (
                                'Save Changes'
                            )}
                        </Button>
                    </div>
                </div>
            </>
        </>
    )
}

export default ASIPersonalInfo
