import PropTypes from 'prop-types'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
import { ReactComponent as DeleteIcon } from 'src/assets/images/deletemodal-icon.svg'

import styles from './cardinstructions.module.scss'
import { InfoIcon } from './data'

const CardInstructions = ({
    title,
    subTitle,
    showCardInstructionError = false,
    accountDetails,
    showRepaymentScheduleWarning = true,
}) => {
    const {
        ActionContainer,
        ActionHeader,
        ActionText,
        BankDetailsSection,
        BankDetailsWrapper,
        BankDetailsHeadings,
        ActionWarning,
    } = styles

    const {
        account_name: accountName,
        account_number: accountNumber,
        bank_name: bankName,
    } = accountDetails || {}

    return (
        <div
            className={ActionContainer}
            style={{ minHeight: showCardInstructionError ? '374px' : '450px' }}
        >
            {showCardInstructionError ? <DeleteIcon /> : <WarningIcon />}
            <h2
                className={ActionHeader}
                style={{ maxWidth: showCardInstructionError && '236px' }}
            >
                {title}
            </h2>
            <p
                className={ActionText}
                style={{ minWidth: showRepaymentScheduleWarning && '345px' }}
            >
                {subTitle}
            </p>
            {!showCardInstructionError && (
                <div className={ActionWarning}>
                    <InfoIcon />
                    <p>
                        A non-refundable fee of ₦50 will be charged for every
                        attempt to set up your card for repayment.
                    </p>
                </div>
            )}
            {!showCardInstructionError && !showRepaymentScheduleWarning && (
                <section className={BankDetailsSection}>
                    <p>Your submitted primary bank account</p>
                    <div className={BankDetailsWrapper}>
                        <div>
                            <p className={BankDetailsHeadings}>
                                Account Number
                            </p>
                            <p>{accountNumber}</p>
                        </div>
                        <div>
                            <p className={BankDetailsHeadings}>Bank Name</p>
                            <p>{bankName}</p>
                        </div>
                        <div>
                            <p className={BankDetailsHeadings}>Account name</p>
                            <p>{accountName}</p>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}

CardInstructions.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    showCardInstructionError: PropTypes.bool,
    accountDetails: PropTypes.object,
    showRepaymentScheduleWarning: PropTypes.bool,
}

export default CardInstructions
