import { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
import { ReactComponent as Save } from 'src/assets/images/save.svg'
import '../accountsetupoverview.scss'
import { ConsumerButton } from 'src/components/Button'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { decodeUserInfo } from 'src/utils/auth'
import { ReactComponent as DropdownIcon } from 'src/assets/images/dropdown.svg'
import LogOutIcon from 'src/assets/images/logout-icon.svg'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { useAppContext } from 'src/appContext/AppContext'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import styles from 'src/components/ActionModalV2/actionmodalv2.module.scss'
import { SelectField } from 'src/components/InputFields'
import ChatCTA from 'src/components/ChatCTA'
import { ReactComponent as CircleWhiteIcon } from 'src/assets/images/circle-right-white.svg'
import useRunCreditDecision from '../hooks/useRunCreditDecision'
import Toast from 'src/components/Toast'
import AdminSubmitCreditDecisionModal from './AdminSubmitCreditDecisionModal'
import { useNotifyConsumerToSubmitKyc } from '../hooks/useNotifyConsumerToSubmitKyc'
import BackNav from 'src/components/BackNav'
import { InlineLoader } from 'src/components/Loader'
import { onePageSubmitAdminInfo } from 'src/api/shoppingExperience/kyc/api'
import {
    formatKYCPayload,
    handleMixPanelTracking,
} from '../../ConsumerKYC/utils'
import { adminActionSaveModalInitialData } from '../../CreateAccount/_components/Header/data'
import ActionModal from 'src/components/ActionModalV2'
import { useNotifyConsumerOfKycUpdate } from '../../CreateAccount/_components/Header/hooks/useNotifyConsumerOfKycUpdate'
import { formatPayload } from '../../CreateAccount/DynamicInformationV2/utils'

const { ActionHeader, ActionContainer, ActionText } = styles
const data = [
    'Credit decision is Yes',
    'Credit decision is Yes With Condition',
    'Credit decision is Maybe',
    'Credit decision is No',
    'Credit decision is Undecided due to errors',
]

const AccountSetupNav = ({
    handleSaveProgress,
    handleSubmit,
    completed,
    hasPaidOutrightly,
    showSimulateCreditDecision,
    setShowSimulateCreditDecision,
    estimationId,
    consumerName,
    ranCreditDecision,
    page,
    canSubmit,
    isSaving = false,
    isSubmitting = false,
    paymentPlanType,
    disablePageButtons = false,
    inputs,
    useType,
    estimation,
    kycInfoFormik,
    requiredKycSections = [],
    onAdminKycSaveSuccessCallback,
}) => {
    const userInfo = decodeUserInfo()
    const history = useHistory()
    const { id } = useParams()
    const [isAdminSavingOnePageKYC, setIsAdminSavingOnePageKYC] =
        useState(false)
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const [showAdminSaveActionModal, setShowAdminSaveActionModal] =
        useState(false)
    const [adminSaveActionModalData, setAdminSaveActionModalData] = useState(
        adminActionSaveModalInitialData,
    )
    const initials =
        userInfo?.firstName?.charAt(0) + userInfo?.lastName?.charAt(0)
    const { logOut, logoutLoading } = useLogOut()
    const { isCustomerApproved, setSimulatedCreditDecision } = useAppContext()
    const handleGoBackToProfile = () => {
        history.push('/consumer/workspace')
    }
    const pathname = useLocation().pathname
    const [type, setType] = useState('')
    const { notifyConsumerOfUpdate } = useNotifyConsumerOfKycUpdate()
    const [showAdminSubmitKycModal, setShowAdminSubmitKycModal] =
        useState(false)
    const [adminSubmitKycModalType, setAdminSubmitKycModalType] =
        useState('submit-kyc')
    const [adminSubmitKycModalAction, setAdminSubmitKycModalAction] =
        useState('')
    const { isMobile } = useMediaQueries()

    const abTestInBoundRequest =
        history?.location?.state?.abTestInBoundRequest ?? false
    const showBackNavToConsumer =
        completed &&
        (page === 'one-page-kyc' || page === 'one-page-dynamic-kyc') &&
        isMobile &&
        !abTestInBoundRequest

    const openAdminKycModal = () => {
        setAdminSubmitKycModalType('submit-kyc')
        setShowAdminSubmitKycModal(true)
    }

    const closeAdminKycModal = () => {
        setShowAdminSubmitKycModal(false)
        setAdminSubmitKycModalType('submit-kyc')
        setAdminSubmitKycModalAction('')
    }

    const { notifyConsumerToSubmitKycForCreditDecision } =
        useNotifyConsumerToSubmitKyc()

    const onRunCreditDecisionSuccess = () => {
        handleMixPanelTracking({
            eventActionAndCategory: 'Assisted KYC Data submission',
            estimation,
            inputs,
        })
        setAdminSubmitKycModalType('submitted-kyc')
    }

    const dropdownMenu = (
        <Menu className="ASONavWrapper_Admin_NavBar_Menu">
            <Menu.Item onClick={() => logOut()}>
                <span
                    style={{ display: 'flex', alignItems: 'center', gap: 16 }}
                >
                    <img src={LogOutIcon} alt="arrow" />
                    <span>Log Out</span>
                </span>
            </Menu.Item>
        </Menu>
    )

    const payloadFormat = type.includes('With Condition')
        ? 'YES_WITH_CONDITION'
        : type.includes('Maybe')
        ? 'MAYBE'
        : type.includes('No')
        ? 'NO'
        : type.includes('Undecided')
        ? 'UNDECIDED'
        : 'YES'

    const {
        runCreditDecisionMutate,
        runCreditDecisionLoading,
        runCreditDecisionToast,
    } = useRunCreditDecision({
        estimationId,
        onSuccess: onRunCreditDecisionSuccess,
        selectedCreditDecision: payloadFormat,
    })

    const adminCanRunCreditDecision =
        isAdminUser &&
        completed &&
        !isCustomerApproved &&
        !hasPaidOutrightly &&
        !ranCreditDecision

    const handleCreditDecisionPrimaryButtonClick = () => {
        switch (adminSubmitKycModalType) {
            case 'submit-kyc':
                if (adminSubmitKycModalAction === 'kyc-submission') {
                    runCreditDecisionMutate()
                } else if (adminSubmitKycModalAction === 'kyc-notification') {
                    notifyConsumerToSubmitKycForCreditDecision.mutate({
                        estimationId: id,
                        handleSuccess: () => {
                            handleMixPanelTracking({
                                eventActionAndCategory:
                                    'Assisted KYC Data submission',
                                estimation,
                                inputs,
                            })
                            setAdminSubmitKycModalType('notified-consumer')
                        },
                    })
                }
                break
            case 'submitted-kyc':
                history.push(`/admin/plans/${id}?tab=credit-review`)
                break
            case 'notified-consumer':
                closeAdminKycModal()
                break
            default:
                break
        }
    }

    const handleCreditDecisionModalCancel = () => {
        if (adminSubmitKycModalType === 'submitted-kyc') {
            history.push(`/admin/plans/${id}?tab=credit-review`)
        } else {
            closeAdminKycModal()
        }
    }

    const getAdminUpdateSaveSuccessfulData = () => {
        return {
            modalType: 'update-successful',
            title: 'Changes saved successfully',
            actionType: 'success',
            heading: 'Changes saved successfully',
            subHeading: `You have successfully updated the basic information for has been saved successfully`,
            leftContent: 'button',
            leftButtonText: 'Notify Consumer',
            rightButtonText: 'More changes',
        }
    }

    const onKYCSaveSuccess = () => {
        setShowAdminSaveActionModal(true)
        setIsAdminSavingOnePageKYC(false)
        if (typeof onAdminKycSaveSuccessCallback !== 'function') {
            handleMixPanelTracking({
                eventActionAndCategory: 'Assisted KYC Data submission',
                estimation,
                inputs,
            })
        }
        onAdminKycSaveSuccessCallback?.()
        updateAdmiSaveActionModalData(getAdminUpdateSaveSuccessfulData())
        kycInfoFormik?.resetForm()
    }

    const handleCreditDecisionSecondaryButtonClick = () => {
        closeAdminKycModal()
    }

    const formattedData = () => {
        let data
        if (page === 'one-page-dynamic-kyc') {
            data = formatPayload(inputs, estimation, requiredKycSections, false)
        } else {
            data = {
                ...formatKYCPayload({ ...inputs }, estimation, useType),
                submit: false,
            }
        }

        return data
    }

    const handleAdminSaveOnePageKyc = () => {
        setIsAdminSavingOnePageKYC(true)
        onePageSubmitAdminInfo({
            data: {
                ...formattedData(),
            },
            type: useType,
            estimationID: estimationId,
        }).then(() => onKYCSaveSuccess())
    }
    const updateAdmiSaveActionModalData = update => {
        setAdminSaveActionModalData(prev => ({ ...prev, ...update }))
    }

    const handleSendUpdateNotification = () => {
        const getAdminSendUpdateNotificationSuccessfulData = () => {
            return {
                modalType: 'notification-successful',
                title: 'Notified Consumer',
                actionType: 'success',
                heading: 'Consumer has been notified',
                subHeading: `Great work! we’ve notified ${consumerName} about the updates that have been made`,
                leftContent: 'chat',
                rightButtonText: 'Great, Thank you',
            }
        }

        notifyConsumerOfUpdate.mutate({
            estimationId: id,
            handleSuccess: () => {
                setShowAdminSaveActionModal(true)
                updateAdmiSaveActionModalData(
                    getAdminSendUpdateNotificationSuccessfulData(),
                )
            },
        })
    }

    return (
        <>
            {isAdminUser ? (
                <AdminSubmitCreditDecisionModal
                    showModal={showAdminSubmitKycModal}
                    onCancel={handleCreditDecisionModalCancel}
                    modalType={adminSubmitKycModalType}
                    consumerName={consumerName}
                    kycAction={adminSubmitKycModalAction}
                    setKycAction={setAdminSubmitKycModalAction}
                    handlePrimaryButtonClick={
                        handleCreditDecisionPrimaryButtonClick
                    }
                    showToast={runCreditDecisionToast?.showToast}
                    toastMessageType={runCreditDecisionToast?.messageType}
                    toastMessage={runCreditDecisionToast?.toastMessage}
                    primaryBtnLoading={
                        runCreditDecisionLoading ||
                        notifyConsumerToSubmitKycForCreditDecision.isLoading
                    }
                    handleSecondaryButtonClick={
                        handleCreditDecisionSecondaryButtonClick
                    }
                />
            ) : (
                <SecondaryModal
                    showModal={showSimulateCreditDecision}
                    onCancel={() => setShowSimulateCreditDecision(false)}
                    modalWidth={'475px'}
                    title={isAdminUser ? 'Submit info' : 'Submit your info'}
                    v2
                    content={
                        <div style={{ position: 'relative' }}>
                            {runCreditDecisionToast?.showToast && (
                                <Toast
                                    position="absolute"
                                    top="30px"
                                    messageType={
                                        runCreditDecisionToast?.messageType
                                    }
                                    message={
                                        runCreditDecisionToast?.toastMessage
                                    }
                                />
                            )}
                            <div className={ActionContainer}>
                                <WarningIcon />
                                <h2 className={ActionHeader}>
                                    {isAdminUser
                                        ? "Submit customer's info"
                                        : 'Submit info and select payment plan'}
                                </h2>
                                <p className={ActionText}>
                                    {isAdminUser
                                        ? 'The next step is to verify if the customer is eligible to get their solar system financed by SunFi.'
                                        : 'Great work! The next step is to verify if you&#39;re eligible of getting your solar system finance by SunFi'}
                                </p>
                                <div className="AsoNav">
                                    <SelectField
                                        initialOption="Select Credit Decision Result"
                                        floatingLabel="Select Credit Decision Result"
                                        floatLabelOnSelectedValue
                                        values={data.map(option => ({
                                            value: option,
                                        }))}
                                        selectWidth={'100%'}
                                        name="type"
                                        handleChange={(name, value) => {
                                            setType(value)
                                        }}
                                        currentSelected={type}
                                        selectedValue={type}
                                        marginTop="24px"
                                        withCheckBox
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    showFooter
                    footerLeftContent={isAdminUser ? null : 'chat'}
                    primaryBtnText="Confirm"
                    handlePrimaryBtnClick={() => {
                        if (isAdminUser) {
                            runCreditDecisionMutate()
                        } else {
                            history.push({
                                pathname:
                                    '/consumer/account-setup/credit-decision',
                                state: {
                                    estimationId,
                                    paymentPlanType,
                                },
                            })
                            setSimulatedCreditDecision(payloadFormat)
                        }
                    }}
                    primaryBtnDisabled={type?.length === 0}
                    primaryBtnWidth={'160px'}
                    primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                    primaryBtnLoading={runCreditDecisionLoading}
                />
            )}

            {isAdminUser && (
                <ActionModal
                    title={adminSaveActionModalData.title}
                    actionModalOpen={showAdminSaveActionModal}
                    actionType={adminSaveActionModalData.actionType}
                    headerText={adminSaveActionModalData.heading}
                    subTitle={adminSaveActionModalData.subHeading}
                    primaryBtnLoading={
                        adminSaveActionModalData.modalType === 'navigate-back'
                    }
                    primaryBtnText={adminSaveActionModalData.rightButtonText}
                    handlePrimaryBtnClick={() => {
                        if (
                            adminSaveActionModalData.modalType ===
                                'update-successful' ||
                            adminSaveActionModalData.modalType ===
                                'notification-successful'
                        ) {
                            setShowAdminSaveActionModal(false)
                            setAdminSaveActionModalData(
                                adminActionSaveModalInitialData,
                            )
                        }
                    }}
                    v2
                    onCancel={() => {
                        setShowAdminSaveActionModal(false)
                        setAdminSaveActionModalData(
                            adminActionSaveModalInitialData,
                        )
                    }}
                    footerLeftContent={adminSaveActionModalData.leftContent}
                    secondaryBtnText={adminSaveActionModalData.leftButtonText}
                    handleSecondaryBtnClick={() => {
                        if (
                            adminSaveActionModalData.modalType ===
                            'update-successful'
                        ) {
                            handleSendUpdateNotification()
                        } else if (
                            adminSaveActionModalData.modalType ===
                            'navigate-back'
                        ) {
                            history.goBack()
                        }
                    }}
                    kycSecondaryButtonStopLoading
                />
            )}

            {isAdminUser ? (
                <nav className={'ASONavWrapper_Admin'}>
                    <img
                        src={
                            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png'
                        }
                        alt={'SunFi'}
                    />
                    <div className={'ASONavWrapper_Admin_Right'}>
                        {isAdminUser &&
                            !isCustomerApproved &&
                            pathname.includes('v2') &&
                            kycInfoFormik.dirty && (
                                <ConsumerButton
                                    btnTextColor="#004AAD"
                                    btnBgColor="#FFFFFF"
                                    borderOutlineColor="#004AAD"
                                    btnHeight={isMobile ? '44px' : '50px'}
                                    btnWidth={isMobile ? '94px' : '117px'}
                                    handleClick={handleAdminSaveOnePageKyc}
                                    loading={isAdminSavingOnePageKYC}
                                    hover
                                >
                                    Save
                                </ConsumerButton>
                            )}
                        {adminCanRunCreditDecision && (
                            <ConsumerButton
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                btnHeight={isMobile ? '44px' : '50px'}
                                btnWidth={isMobile ? '94px' : '117px'}
                                handleClick={() => {
                                    openAdminKycModal()
                                }}
                            >
                                Submit
                            </ConsumerButton>
                        )}
                        <div className={'ASONavWrapper_Admin_Avatar'}>
                            <span>{initials?.toUpperCase()}</span>
                        </div>
                        <Dropdown
                            trigger={['click']}
                            overlay={dropdownMenu}
                            placement="bottomRight"
                        >
                            <DropdownIcon style={{ cursor: 'pointer' }} />
                        </Dropdown>
                    </div>
                </nav>
            ) : (
                <nav
                    className={`ASONavWrapper ${
                        showBackNavToConsumer ? 'ASONavWrapper_RowOnMobile' : ''
                    }`}
                    data-testid="account-setup-nav"
                >
                    <div className={'ASONavLeft'}>
                        {showBackNavToConsumer ? (
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() => history.push('/')}
                            />
                        ) : hasPaidOutrightly ? (
                            <ChatCTA />
                        ) : (
                            <>
                                <h1>Set up your SunFi account</h1>
                                <p>This takes approximately 10mins</p>
                            </>
                        )}
                    </div>
                    {hasPaidOutrightly ? (
                        <ConsumerButton
                            btnWidth={'116px'}
                            btnHeight={'50px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            hoverNoFill
                            type={logoutLoading ? undefined : 'iconBtn'}
                            handleClick={() => logOut()}
                            loading={logoutLoading}
                        >
                            Logout
                            <CircleWhiteIcon />
                        </ConsumerButton>
                    ) : (
                        <div className="ASONavButtons">
                            {!isCustomerApproved && !isAdminUser && (
                                <div
                                    className={`ASONavRight ${
                                        disablePageButtons
                                            ? 'ASONavRight_Disabled'
                                            : ''
                                    }`}
                                    role={'button'}
                                    onClick={
                                        disablePageButtons
                                            ? () => {}
                                            : () => handleSaveProgress()
                                    }
                                >
                                    {isSaving ? (
                                        <InlineLoader color="primary" />
                                    ) : (
                                        <>
                                            <div
                                                className={`ASONavButtons_IconBtn ${
                                                    completed ? '' : 'SmallIcon'
                                                } ${
                                                    disablePageButtons
                                                        ? 'ASONavButtons_IconBtn_Disabled'
                                                        : ''
                                                }`}
                                            >
                                                <Save role={'presentation'} />
                                            </div>
                                            {!completed && (
                                                <p
                                                    className={
                                                        disablePageButtons
                                                            ? 'ASONav_SaveText_Disabled'
                                                            : ''
                                                    }
                                                >
                                                    I want to continue later
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            {(completed || canSubmit) && (
                                <>
                                    <div className="ASONavButtons_Divider"></div>
                                    {isCustomerApproved ? (
                                        <ConsumerButton
                                            btnBgColor="#004AAD"
                                            btnTextColor="#FFFFFF"
                                            btnHeight={
                                                isMobile ? '44px' : '50px'
                                            }
                                            btnWidth={
                                                isMobile ? '94px' : '117px'
                                            }
                                            handleClick={handleGoBackToProfile}
                                        >
                                            Back to profile
                                        </ConsumerButton>
                                    ) : (
                                        <ConsumerButton
                                            btnBgColor="#004AAD"
                                            btnTextColor="#FFFFFF"
                                            btnHeight={
                                                isMobile ? '44px' : '50px'
                                            }
                                            btnWidth={
                                                isMobile ? '94px' : '117px'
                                            }
                                            handleClick={() => {
                                                page === 'one-page-kyc' ||
                                                page === 'one-page-dynamic-kyc'
                                                    ? handleSubmit()
                                                    : process.env
                                                          .REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE ===
                                                      'true'
                                                    ? setShowSimulateCreditDecision(
                                                          true,
                                                      )
                                                    : handleSubmit()
                                            }}
                                            loading={isSubmitting}
                                            disabled={disablePageButtons}
                                        >
                                            Submit
                                        </ConsumerButton>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </nav>
            )}
        </>
    )
}

AccountSetupNav.propTypes = {
    handleSaveProgress: PropTypes.func,
    handleSubmit: PropTypes.func,
    completed: PropTypes.bool,
    hasPaidOutrightly: PropTypes.bool,
    showSimulateCreditDecision: PropTypes.bool,
    setShowSimulateCreditDecision: PropTypes.func,
    estimationId: PropTypes.string,
    openActionModal: PropTypes.func,
    consumerName: PropTypes.string,
    paymentPlanType: PropTypes.string,
    ranCreditDecision: PropTypes.bool,
    page: PropTypes.string,
    canSubmit: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    disablePageButtons: PropTypes.bool,
    inputs: PropTypes.object,
    useType: PropTypes.string,
    estimation: PropTypes.object,
    kycInfoFormik: PropTypes.object,
    requiredKycSections: PropTypes.array,
    onAdminKycSaveSuccessCallback: PropTypes.func,
}

export default AccountSetupNav
