export const creditVariableTypeOptions = [
    {
        label: 'Debt Cost (%)',
        value: 'debtCost',
    },
    {
        label: 'Insurance Rate (%)',
        value: 'insuranceRate',
    },
    {
        label: 'Minimum Upfront Deposit (%)',
        value: 'minimumUpfrontDeposit',
    },
    {
        label: 'Debt to Equity in Project (%)',
        value: 'debtToEquityInProject',
    },
    {
        label: 'Lowest Tenure (Months)',
        value: 'lowestTenure',
    },
    {
        label: 'Monthly Rate Jump (%)',
        value: 'monthlyRateJump',
    },
    {
        label: 'Start Declining Balance Rate (%)',
        value: 'startDecliningBalanceRate',
    },
    {
        label: 'Number of Payment Plans',
        value: 'paymentPlans',
    },
    {
        label: 'Cost of Capital (%)',
        value: 'costOfCapital',
    },
    {
        label: 'Re-Investment Rate (%)',
        value: 'reInvestmentRate',
    },
    {
        label: 'Tenure Increment (%)',
        value: 'tenureIncrement',
    },
    {
        label: 'Debt Months (Months)',
        value: 'debtMonths',
    },
]
