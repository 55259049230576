import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { sharedFormComponentPropTypes } from '../../../DynamicInformation/components/Form/utils'
import s from './formfields.module.scss'

const DirectorContactInfo = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
}) => {
    return (
        <>
            <div className={s.directorContactInfo}>
                <InputFields
                    name="directorEmail"
                    title="Director's Email Address"
                    value={inputs.directorEmail}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={isAdminUser || isCustomerApproved}
                    marginBottom={'16px'}
                    errorMessage={errors?.directorEmail}
                    inputWidth={'100%'}
                />

                <SelectCountryCode
                    name="directorPhone"
                    title="Director's Phone Number"
                    inputType={'tel'}
                    inputValue={inputs.directorPhone}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={isAdminUser || isCustomerApproved}
                    selectWidth="114px"
                    inputStyle={{ width: '100%' }}
                    errorMessage={errors?.directorPhone}
                    marginBottom={'16px'}
                />
            </div>
        </>
    )
}

DirectorContactInfo.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default DirectorContactInfo
