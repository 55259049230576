/* eslint-disable react/prop-types */

import styles from './formtitlebar.module.scss'

const { HeaderWrapper, Title, SubTitle, TitleM20, SubTitleM20 } = styles

const FormTitleBar = ({
    title,
    subtitle,
    alignLeft,
    marginTop,
    marginBottom,
    marginLeft,
    fontFamily,
    titleFontSize,
    subtitleFontSize,
    textAlignCenter,
    subtitleWidth,
    subtitleLineHeight,
    subtitleColor,
    type = 'default',
}) => {
    const classTypes = {
        M20: {
            title: TitleM20,
            subtitle: SubTitleM20,
        },
        default: {
            title: Title,
            subtitle: SubTitle,
        },
    }
    return (
        <div
            className={HeaderWrapper}
            style={{
                alignItems: alignLeft && 'flex-start',
                textAlign: textAlignCenter && 'center',
                marginTop: marginTop,
                marginBottom: marginBottom,
                marginLeft: marginLeft,
            }}
        >
            <h2
                className={classTypes[type].title}
                style={{ fontFamily: fontFamily, fontSize: titleFontSize }}
            >
                {title}
            </h2>
            <p
                className={classTypes[type].subtitle}
                style={{
                    fontSize: subtitleFontSize,
                    width: subtitleWidth,
                    lineHeight: subtitleLineHeight,
                    color: subtitleColor,
                }}
            >
                {subtitle}
            </p>
        </div>
    )
}

export default FormTitleBar
