const paymentModelMap = {
    LEASE_TO_OWN: 'Lease to Own',
    SUBSCRIPTION: 'Subscription',
    OUTRIGHT_SALE: 'Outright Sale',
}

export const creditVariablePaymentModels = ['Lease to Own', 'Subscription']

export const mapPaymentModel = value => {
    return paymentModelMap[value] || ''
}

export const reverseMapPaymentModel = displayValue => {
    const reverseMap = Object.fromEntries(
        Object.entries(paymentModelMap).map(([k, v]) => [v, k]),
    )
    return reverseMap[displayValue] || ''
}
