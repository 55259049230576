import PropTypes from 'prop-types'
import FormTitleBar from 'src/components/FormTitleBar'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from './selectedittype.module.scss'
import s2 from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import { useState } from 'react'
import { variableEditMethods } from './data'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import ButtonComponent from 'src/components/Button'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const SelectEditTypeModal = ({
    showModal,
    openVariablesDrawer,
    closeModal,
    setEditVariablesMethod,
    openOneToMultipleBulkEditModal,
}) => {
    const [method, setMethod] = useState('')

    const { isMobile } = useMediaQueries()

    const onCancel = () => {
        setMethod('')
        closeModal()
    }

    const handleSelectMethod = key => {
        setMethod(key)
    }

    const handleContinue = () => {
        if (method === 'multiple-variables-individually') {
            openVariablesDrawer()
        } else if (method === 'one-change-to-multiple') {
            openOneToMultipleBulkEditModal()
        }
        setEditVariablesMethod(method)
        onCancel()
    }

    return (
        <SecondaryModal
            closable
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'459px'}
            modalHeight={isMobile ? '100%' : '547px'}
            content={
                <div className={s.wrapper}>
                    <FormTitleBar
                        type={'M20'}
                        title={'Edit credit variables'}
                        subtitle={
                            'Select how you want to edit credit variables'
                        }
                    />

                    <div>
                        {variableEditMethods?.map((each, i) => (
                            <div
                                key={i}
                                role="button"
                                className={`${s2.methodDiv} ${
                                    s2.methodDivCreditVariable
                                } ${
                                    method === each?.key
                                        ? s2.methodDivActive
                                        : ''
                                }`}
                                onClick={() => handleSelectMethod(each?.key)}
                            >
                                <div className={s2.methodIcon}>
                                    {each?.icon}
                                </div>
                                <div className={s.methodText}>
                                    <h4>{each?.title}</h4>
                                    <p>{each?.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <VerticalSpacer top={isMobile ? '143px' : '63px'} />
                    <div className={s.btn}>
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            disabled={method === ''}
                            handleClick={handleContinue}
                        >
                            Continue
                        </ButtonComponent>
                    </div>
                </div>
            }
        />
    )
}

SelectEditTypeModal.propTypes = {
    showModal: PropTypes.bool,
    openVariablesDrawer: PropTypes.func,
    closeModal: PropTypes.func,
    setEditVariablesMethod: PropTypes.func,
    openOneToMultipleBulkEditModal: PropTypes.func,
}

export default SelectEditTypeModal
