import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/BusinessForm/businessform.scss'
import s from './formfields.module.scss'
import { sharedFormComponentPropTypes } from '../../../DynamicInformation/components/Form/utils'
import { SelectButtons } from 'src/components/SelectButtons'
import employmentStatus, {
    fullEmploymentOptions,
    retiredOptions,
    selfEmployedOptions,
} from 'src/utils/employmentStatus'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'

const EmploymentType = ({
    section,
    inputs,
    errors,
    handleSelectChange,
    isCustomerApproved,
}) => {
    const employmentOptions =
        section?.value === 'full_employment'
            ? fullEmploymentOptions
            : section?.value === 'pension'
            ? retiredOptions
            : section?.value === 'business'
            ? selfEmployedOptions
            : employmentStatus

    return (
        <>
            <p className={s.sectionTitle}>Select Your Employment Type</p>
            <SelectButtons
                selectedValue={inputs?.employmentType}
                values={employmentOptions}
                handleChange={value =>
                    handleSelectChange('employmentType', value)
                }
                width={'fit-content'}
                height={41}
                padding={'10px 12px'}
                disabled={isCustomerApproved}
                type="secondary"
            />
            {errors?.employmentType && (
                <span className="fieldError">{errors?.employmentType}</span>
            )}
            <VerticalSpacer top={16} />
        </>
    )
}

EmploymentType.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default EmploymentType
