import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import PageHeading from '../../../_components/PageHeading'
import { useDynamicInformationV2Context } from '../../context'
import SelectedSolutionSection from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/components/SelectedSolution'

const SideContent = () => {
    const {
        goBackToSystem,
        inputs,
        offer,
        offerFetching,
        offerLoadingOrRefetchError,
        disablePageButtons,
        upfrontDepositPaid,
        handleChangePackage,
    } = useDynamicInformationV2Context()
    const { isMobile } = useMediaQueries()
    return (
        <>
            <div className="LeftContainer">
                {isMobile ? (
                    <PageHeading
                        goBackToSystem={goBackToSystem}
                        // TODO: implement when one page kyc video is ready
                        // handleShowVideo={handleShowVideo}
                    />
                ) : (
                    <SelectedSolutionSection
                        estimation={offer}
                        goBackToSystem={goBackToSystem}
                        useType={offer?.use_type}
                        handlePackageChange={handleChangePackage}
                        showPageHeading={true}
                        inputs={inputs}
                        estimationFetching={offerFetching}
                        estimationLoadingOrRefetchError={
                            offerLoadingOrRefetchError
                        }
                        disablePageButtons={disablePageButtons}
                        isCustomerApproved={offer?.isCustomerApproved}
                        upfrontDepositPaid={upfrontDepositPaid}
                    />
                )}
            </div>
        </>
    )
}

export default SideContent
