import './onepagekycloaders.scss'
import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { DotsLineLoader } from 'src/components/Loader'

const MixedLengthsLoader = () => {
    return (
        <div className="OnePageKYC_SectionLoader_MixedLengths">
            {[...Array(9)].map((_, idx) => {
                const gridAreaNames = [
                    'full1',
                    'full2',
                    'item3',
                    'item4',
                    'item5',
                    'item6',
                    'item7',
                    'item8',
                    'item9',
                ]

                const style = { gridArea: gridAreaNames[idx] }

                return (
                    <div key={idx} style={style}>
                        <Skeleton.Input active />
                    </div>
                )
            })}
        </div>
    )
}

const EqualLengthsLoader = () => {
    return (
        <div className="OnePageKYC_SectionLoader_EqualLengths">
            {[...Array(4)].map((_, idx) => {
                return (
                    <div key={idx}>
                        <Skeleton.Input active />
                    </div>
                )
            })}
        </div>
    )
}

export const SectionLoader = ({ index }) => {
    return <>{index === 0 ? <MixedLengthsLoader /> : <EqualLengthsLoader />}</>
}

SectionLoader.propTypes = {
    index: PropTypes.number,
}

export const SelectedSolutionLoader = () => {
    return (
        <div className="OnePageKYC_SelectedSolutionLoader">
            {[...Array(2)].map((_, idx) => {
                return (
                    <div key={idx}>
                        <Skeleton.Input active />
                    </div>
                )
            })}
        </div>
    )
}

export const RetrievingStatementLoader = () => {
    return (
        <div role={'presentation'} className="KYC_RetrievingStatementLoader">
            <DotsLineLoader dotsSize="4.53px" />
            Retrieving statement
        </div>
    )
}

export const SingleInputLoader = () => {
    return (
        <div className="OnePageKYC_SingleInputLoader">
            <Skeleton.Input active />
        </div>
    )
}
