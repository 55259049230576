import '../consumerworkspace.scss'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ConsumerButton } from 'src/components/Button'
import { ReactComponent as CircleDown } from 'src/assets/images/circle-down-no-stroke.svg'
import { Dropdown, Menu } from 'antd'
import { isMobileOrTablet } from 'src/utils/mediaQueries'
import Card from 'src/components/Card'
import NairaSymbol from 'src/components/NairaSymbol'
import { appTracking } from 'src/utils/appTracker'
import { offerStatusMap } from 'src/utils/Data/status'
import { decodeUserInfo } from 'src/utils/auth'

const ActivePlansTable = ({ activePlans, consumerType }) => {
    const history = useHistory()
    const userInfo = decodeUserInfo()
    const allowReturningCustomers =
        process.env.REACT_APP_FLAG_RETURNING_DTC_CUSTOMERS === 'true'

    const tableHeadTitles = [
        'Plan',
        'Package Type',
        ...(allowReturningCustomers ? ['Status'] : []),
        'Monthly Payment',
        'Payment Duration',
        'Action',
    ]

    const pageTitle =
        'Consumer Workspace | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CONSUMER_WORKSPACE_ACTIVE_PLANS'

    const MenuComp = ({ id, name }) => {
        return (
            <Menu className="ConsumerWorkspace_Table_Menu">
                <Menu.Item
                    key="1"
                    onClick={() => {
                        history.push(userInfo?.consumerKYCHome, {
                            from: '/consumer/workspace',
                            currentTab: '2',
                            estimationId: id,
                        })

                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'VIEW_CONSUMER_APPLICATION',
                            'KYC_CONSUMER_WORKSPACE_ACTIVE_PLANS_MENU',
                            'KYC_CONSUMER_WORKSPACE',
                        )
                    }}
                >
                    My Application
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => {
                        history.push(
                            `/consumer/repayment-schedule?id=${id}&name=${name}`,
                        )

                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'VIEW_CONSUMER_REPAYMENT_SCHEDULE',
                            'KYC_CONSUMER_WORKSPACE_ACTIVE_PLANS_MENU',
                            'KYC_CONSUMER_WORKSPACE',
                        )
                    }}
                >
                    Repayment Schedule
                </Menu.Item>
            </Menu>
        )
    }
    MenuComp.propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
    }

    const flagStyleProps = plan => {
        return {
            color: plan?.applicationStatus === 'ACTIVE' ? '#32CC79' : '#004AAD',
            backgroundColor:
                plan?.applicationStatus === 'ACTIVE' ? '#32CC7926' : '#E2EEFF',
            textTransform: 'uppercase',
        }
    }

    return (
        <>
            {isMobileOrTablet ? (
                activePlans?.map((plan, i) => (
                    <Card
                        key={i}
                        topLeftHeading="PLAN"
                        topLeftContent={plan?.plan}
                        bottomLeftHeading="PACKAGE TYPE"
                        bottomLeftContent={plan?.packageType}
                        bottomRightHeading="TENURE DURATION"
                        bottomRightContent={plan?.tenureDuration}
                        topLeftContentSize={15}
                        buttonType={
                            consumerType === 'DTC' ? 'dropdown' : 'button'
                        }
                        dropdownMenu={
                            <MenuComp id={plan?.id} name={plan?.plan} />
                        }
                        includeFlag={allowReturningCustomers}
                        flagText={offerStatusMap[plan?.applicationStatus]}
                        flagStyleProps={flagStyleProps(plan)}
                    />
                ))
            ) : (
                <table className="ConsumerWorkspace_Table">
                    <thead>
                        <tr>
                            {tableHeadTitles.map(title => (
                                <th key={title}>{title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {activePlans?.map((plan, i) => (
                            <tr
                                key={i}
                                className="ConsumerWorkspace_Table_Rows"
                            >
                                <td>{plan?.plan}</td>
                                <td>{plan?.packageType}</td>
                                {allowReturningCustomers && (
                                    <td>
                                        <span
                                            className="ConsumerWorkspace_TableStatus"
                                            style={flagStyleProps(plan)}
                                        >
                                            {
                                                offerStatusMap[
                                                    plan?.applicationStatus
                                                ]
                                            }
                                        </span>
                                    </td>
                                )}
                                <td>
                                    {' '}
                                    {plan?.monthlyPayment !== '-' && (
                                        <NairaSymbol />
                                    )}
                                    {plan?.monthlyPayment}
                                </td>
                                <td>{plan?.tenureDuration}</td>
                                <td>
                                    {consumerType === 'DTC' ? (
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={
                                                <MenuComp
                                                    id={plan?.id}
                                                    name={plan?.plan}
                                                />
                                            }
                                            placement="bottomRight"
                                        >
                                            <a
                                                onClick={e => {
                                                    e.preventDefault()
                                                    appTracking(
                                                        pageUrlName,
                                                        pageTracker,
                                                        pageTitle,
                                                        'CONSUMER_CLICKS_VIEW_MENU_CTA',
                                                        'KYC_CONSUMER_WORKSPACE_ACTIVE_PLANS',
                                                        'KYC_CONSUMER_WORKSPACE',
                                                    )
                                                }}
                                            >
                                                <ConsumerButton
                                                    btnWidth={'83px'}
                                                    btnHeight={'34px'}
                                                    btnBgColor={'#F9FCFF'}
                                                    btnTextColor={'#004AAD'}
                                                    hoverNoFill
                                                    type={'iconBtn'}
                                                    border={
                                                        '0.5px solid #004AAD'
                                                    }
                                                >
                                                    View
                                                    <CircleDown />
                                                </ConsumerButton>
                                            </a>
                                        </Dropdown>
                                    ) : (
                                        <ConsumerButton
                                            btnWidth={'75px'}
                                            btnHeight={'37px'}
                                            btnBgColor={'#E2EEFF'}
                                            btnTextColor={'#004AAD'}
                                        >
                                            View
                                        </ConsumerButton>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    )
}
ActivePlansTable.propTypes = {
    activePlans: PropTypes.array,
    consumerType: PropTypes.oneOf(['DEP', 'DTC']),
}

export default ActivePlansTable
