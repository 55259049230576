export const InfoIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 21.291C16.9706 21.291 21 17.2616 21 12.291C21 7.32045 16.9706 3.29102 12 3.29102C7.02944 3.29102 3 7.32045 3 12.291C3 17.2616 7.02944 21.291 12 21.291Z"
                stroke="#004AAD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 11.291V16.291"
                stroke="#004AAD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9502 8.29102H12.0502V8.39102H11.9502V8.29102Z"
                stroke="#004AAD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
