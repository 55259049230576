// react libraries
import { Route, Switch, Redirect } from 'react-router-dom'
import Partners from 'src/pages/Partners'
import NotFound from 'src/pages/NotFound'
import Learn from 'src/pages/Learn'
// import Projects from 'src/pages/Projects'
// import ProjectDetailsPage from 'src/pages/ProjectDetailsPage'
import PrivacyPolicy from 'src/pages/PrivacyPolicy'
import TermsOfService from 'src/pages/TermsOfService'
import LearnContentOne from 'src/pages/LearnContent/ContentOne'
import LearnContentTwo from 'src/pages/LearnContent/ContentTwo'
import LearnContentThree from 'src/pages/LearnContent/ContentThree'
import LearnContentFour from 'src/pages/LearnContent/ContentFour'
import LearnContentFive from 'src/pages/LearnContent/ContentFive'
import LearnContentSix from 'src/pages/LearnContent/ContentSix'
import LearnContentEight from 'src/pages/LearnContent/ContentEight'
import LearnContentNine from 'src/pages/LearnContent/ContentNine'
import LearnContentTen from 'src/pages/LearnContent/ContentTen'
import ConsumerHome from 'src/pages/Consumer/SmartShoppingExperience/Home'
import ConsumerSolarSystemListings from 'src/pages/Consumer/SmartShoppingExperience/SolarSystemsListings'
import ConsumerSolarSystemListingsV2 from 'src/pages/Consumer/SmartShoppingExperience/SolarSystemsListingsV2'
import AccountSetupOverview from '../pages/Consumer/SmartShoppingExperience/AccountSetupOverview'
import Verification from '../pages/Consumer/SmartShoppingExperience/CreateAccount/Verification'
import CreditDecision from 'src/pages/Consumer/SmartShoppingExperience/CreditDecision'
import ProfileListings from 'src/pages/Consumer/ProfileListings'

// SunFi App Routes
import AppRoutes from './AppRoutes'
import LearnContentSeven from '../pages/LearnContent/ContentSeven'
import ViewSystem from 'src/pages/Consumer/SmartShoppingExperience/ViewSystem'
import PersonalInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation'
import FinancialInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/FinancialInformation'
import {
    CREATE_BUSINESS_ACCOUNT,
    CREATE_RESIDENTIAL_ACCOUNT,
    LEARN_ROUTES,
} from './pathnames'
import IdentityInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/IdentityInformation'
import CSSEPromoListings from 'src/pages/Consumer/SmartShoppingExperience/Home/PromoListings'
import ConsumerGetStarted from 'src/pages/Consumer/SmartShoppingExperience/Home/GetStarted'
import MultiplePackagesPromoListings from 'src/pages/Consumer/SmartShoppingExperience/Home/MultiplePackagePromoListings'
import LearnContentEleven from 'src/pages/LearnContent/ContentEleven'
import LearnContentTwelve from 'src/pages/LearnContent/ContentTwelve'
import LearnContentThirteen from 'src/pages/LearnContent/ContentThirteen'
import OutrightSaleInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/OutrightSaleInformation'
import DynamicInformation from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/DynamicInformation'
import ErrorNotice from 'src/components/ErrorNotice'
import ConsumerKYC from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC'
import MaintenancePage from 'src/components/MaintenancePage'
import { useVerifyAstronautAddress } from 'src/utils/verifyAstronautAddress'
import DynamicInformationV2 from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/DynamicInformationV2'

const showNewErrorNotice =
    process.env.REACT_APP_FLAG_SHOW_NEW_ERROR_AND_BROKEN_LINK_PAGE === 'true'

const Routes = () => {
    const isMaintenanceMode =
        process.env.REACT_APP_REDIRECT_TO_MAINTENANCE_PAGE === 'true'
    const { verifiedAstronautAddress } = useVerifyAstronautAddress()

    return (
        <>
            {isMaintenanceMode && !verifiedAstronautAddress ? (
                <>
                    <Redirect to="/" />

                    <Switch>
                        <Route exact path="/">
                            <MaintenancePage verifiedAstronautAddress={false} />
                        </Route>
                    </Switch>
                </>
            ) : (
                <Switch>
                    <Route
                        path="/consumer/get-started"
                        component={ConsumerGetStarted}
                    />
                    <Route
                        exact
                        path="/consumer/promos"
                        component={CSSEPromoListings}
                    />
                    <Route
                        exact
                        path="/consumer/promos/:id/packages"
                        component={MultiplePackagesPromoListings}
                    />
                    <Route
                        path="/consumer/view-system/:id"
                        component={ViewSystem}
                    />
                    <Route
                        path="/consumer/view-system/:id/shared"
                        component={ViewSystem}
                    />
                    {process.env.REACT_APP_FLAG_SHOW_ONE_PAGE_KYC ===
                        'true' && (
                        <Route exact path="/consumer/account-setup/residential">
                            <ConsumerKYC useType={'residential'} />
                        </Route>
                    )}
                    {process.env.REACT_APP_FLAG_SHOW_ONE_PAGE_KYC ===
                        'true' && (
                        <Route exact path="/consumer/account-setup/business">
                            <ConsumerKYC useType={'business'} />
                        </Route>
                    )}
                    <Route path="/consumer/account-setup/business-verification">
                        <Verification userType={'business'} />
                    </Route>
                    <Route path="/consumer/account-setup/identity-information">
                        <IdentityInformation userType={'residential'} />
                    </Route>
                    <Route
                        path="/consumer/systems"
                        component={ConsumerSolarSystemListings}
                    />
                    {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                        'true' && (
                        <Route
                            path="/consumer/profile-details/:id"
                            component={ConsumerSolarSystemListingsV2}
                        />
                    )}
                    {process.env
                        .REACT_APP_FLAG_ENABLE_PUBLIC_PROMOTIONAL_CONSUMER_PROFILE ===
                        'true' && (
                        <Route
                            path="/consumer/promo-detail/:id"
                            component={ConsumerSolarSystemListingsV2}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                        'true' && (
                        <Route
                            path="/consumer/profile-details/:id/shared"
                            component={ConsumerSolarSystemListingsV2}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                        'true' &&
                        process.env.REACT_APP_FLAG_SHOW_AVAILABLE_SOLUTIONS ===
                            'true' && (
                            <Route
                                path="/consumer/all-systems"
                                component={ConsumerSolarSystemListingsV2}
                            />
                        )}
                    <Route
                        path={'/consumer/account-setup/overview'}
                        component={AccountSetupOverview}
                    />
                    <Route path="/consumer/account-setup/business-information">
                        <PersonalInformation userType="business" />
                    </Route>
                    <Route path="/consumer/account-setup/residential-information">
                        <PersonalInformation userType="residential" />
                    </Route>
                    <Route path={CREATE_BUSINESS_ACCOUNT.FINANCIAL_INFO}>
                        <FinancialInformation userType="business" />
                    </Route>
                    <Route path={CREATE_RESIDENTIAL_ACCOUNT.FINANCIAL_INFO}>
                        <FinancialInformation userType="residential" />
                    </Route>
                    <Route path="/consumer/account-setup/business-information">
                        <PersonalInformation userType="business" />
                    </Route>
                    <Route path="/consumer/account-setup/residential-information">
                        <PersonalInformation userType="residential" />
                    </Route>
                    <Route path="/consumer/account-setup/outright-sale-information">
                        <OutrightSaleInformation />
                    </Route>
                    <Route
                        exact
                        path="/consumer/account-setup/credit-decision"
                        component={CreditDecision}
                    />
                    {process.env.REACT_APP_FLAG_SHOW_DYNAMIC_ONE_PAGE_KYC ===
                        'true' && (
                        <Route
                            path="/consumer/account-setup/kyc"
                            component={DynamicInformationV2}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_SHOW_DYNAMIC_KYC === 'true' && (
                        <Route
                            path="/admin/account-setup/:id"
                            component={DynamicInformation}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_SHOW_DYNAMIC_KYC === 'true' && (
                        <Route
                            path="/consumer/account-setup/:id"
                            component={DynamicInformation}
                        />
                    )}

                    {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                        'true' && (
                        <Route path="/consumer/individual-profiles">
                            <ProfileListings />
                        </Route>
                    )}
                    {process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW ===
                        'true' && (
                        <Route path="/consumer/business-profiles">
                            <ProfileListings />
                        </Route>
                    )}
                    {/* admin - consumer KYC routes */}
                    <Route
                        path={`/admin/plans/:id/kyc/residential-information`}
                    >
                        <PersonalInformation userType="residential" />
                    </Route>
                    <Route path={`/admin/plans/:id/kyc/identity-information`}>
                        <IdentityInformation userType={'residential'} />
                    </Route>
                    <Route
                        path={`/admin/plans/:id/kyc/residential-financial-information`}
                    >
                        <FinancialInformation userType="residential" />
                    </Route>
                    <Route path={`/admin/plans/:id/kyc/business-information`}>
                        <PersonalInformation userType="business" />
                    </Route>
                    <Route path={`/admin/plans/:id/kyc/business-verification`}>
                        <Verification userType={'business'} />
                    </Route>
                    <Route
                        path={`/admin/plans/:id/kyc/business-financial-information`}
                    >
                        <FinancialInformation userType="business" />
                    </Route>
                    <Route
                        exact
                        path={`/admin/plans/:id/kyc`}
                        component={AccountSetupOverview}
                    />
                    <Route exact path={`/admin/plans/:id/kyc/v2/residential`}>
                        <ConsumerKYC useType="residential" />
                    </Route>
                    <Route exact path={`/admin/plans/:id/kyc/v2/business`}>
                        <ConsumerKYC useType="business" />
                    </Route>
                    {process.env.REACT_APP_FLAG_SHOW_DYNAMIC_ONE_PAGE_KYC ===
                        'true' && (
                        <Route
                            path="/admin/plans/:id/kyc/v2/information"
                            component={DynamicInformationV2}
                        />
                    )}
                    {/* KYC routes for outright sale*/}
                    <Route path="/admin/outright-sale/:id/kyc/information">
                        <OutrightSaleInformation />
                    </Route>
                    {/* end admin KYC routes */}

                    {/* website routes */}
                    {process.env.REACT_APP_FLAG_HIDE_WEBSITE_PAGES !==
                        'true' && (
                        <Route exact path="/" component={ConsumerHome} />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_WEBSITE_PAGES !==
                        'true' && (
                        <Route exact path="/" component={ConsumerHome} />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_WEBSITE_PAGES !==
                        'true' && (
                        <Route path="/partners" component={Partners} />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_WEBSITE_PAGES !==
                        'true' && (
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_WEBSITE_PAGES !==
                        'true' && (
                        <Route
                            path="/terms-of-service"
                            component={TermsOfService}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route exact path="/learn" component={Learn} />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentOne}
                            component={LearnContentOne}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentTwo}
                            component={LearnContentTwo}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentFour}
                            component={LearnContentFour}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentThree}
                            component={LearnContentThree}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentFive}
                            component={LearnContentFive}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentSix}
                            component={LearnContentSix}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentSeven}
                            component={LearnContentSeven}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentEight}
                            component={LearnContentEight}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentNine}
                            component={LearnContentNine}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentTen}
                            component={LearnContentTen}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentEleven}
                            component={LearnContentEleven}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentTwelve}
                            component={LearnContentTwelve}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentThirteenOldUrl}
                            component={() => (
                                <Redirect
                                    to={LEARN_ROUTES.learnContentThirteen}
                                />
                            )}
                        />
                    )}
                    {process.env.REACT_APP_FLAG_HIDE_LEARN_PAGE !== 'true' && (
                        <Route
                            exact
                            path={LEARN_ROUTES.learnContentThirteen}
                            component={LearnContentThirteen}
                        />
                    )}

                    {/* <Route path="/projects" exact component={Projects} /> */}
                    {/* <Route path="/projects/:id" exact component={ProjectDetailsPage} /> */}

                    <Route
                        exact
                        path="/error/404"
                        component={showNewErrorNotice ? ErrorNotice : NotFound}
                    />
                    <Route
                        path="/not-found"
                        component={showNewErrorNotice ? ErrorNotice : NotFound}
                    />
                    <AppRoutes />
                    <Route
                        path="*"
                        component={showNewErrorNotice ? ErrorNotice : NotFound}
                    />
                </Switch>
            )}
        </>
    )
}

export default Routes
