import Avatar from '../../../CreateAccount/_components/Avatar'
import { useConsumerKYCContext } from '../../context'

const IdentityInformationSection = () => {
    const { inputs, errors, removeErrors, kycRefetch, isCustomerApproved } =
        useConsumerKYCContext()

    return (
        <Avatar
            inputs={inputs}
            errors={errors}
            removeErrors={removeErrors}
            kycRefetch={kycRefetch}
            isCustomerApproved={isCustomerApproved}
        />
    )
}

export default IdentityInformationSection
