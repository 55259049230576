import { mapPaymentModel } from 'src/utils/Data/paymentModel'

export const actionModalValues = action => {
    switch (action) {
        case 'bulk-edit-variables-successful':
            return {
                actionModalHeaderText: 'Changes Applied Successfully',
                actionModalSubtitle:
                    'You have successfully applied changes to selected credit variables',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Okay, Got it',
            }
        default:
            return {
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalShowCancelBtn: true,
                actionModalActionText: '',
            }
    }
}

export const formatPercentage = value => {
    if (value === undefined || value === null || isNaN(parseFloat(value)))
        return ''
    return `${parseFloat(value)}%`
}

export const formatMonths = value => {
    if (value === undefined || value === null || isNaN(parseInt(value)))
        return ''
    return `${parseInt(value)} months`
}

export const parsePercentage = value => {
    if (!value) return 0
    return parseFloat(value.toString().replace('%', '').trim())
}

export const parseMonths = value => {
    if (!value) return 0
    return parseInt(value.toString().replace('months', '').trim())
}

// TODO: create the camelCase name here so the implementation is not broken if we relabel in future
export const formatCreditVariablesSummaryData = data => {
    return data.map(item => ({
        id: item?.id,
        name:
            item?.cluster_info?.providers?.length > 0
                ? `DEP Cluster: ${item?.cluster_info?.code}`
                : (item?.cluster_info?.providers?.length == 0 ||
                      item?.cluster_info?.providers === undefined) &&
                  item?.cluster_info?.packages
                ? `DTC Cluster: ${item?.cluster_info?.code}`
                : item?.provider?.display_name,
        details: [
            {
                label: 'Payment Model',
                value: mapPaymentModel(item?.payment_model),
            },
            { label: 'Debt Cost', value: formatPercentage(item?.debt_cost) },
            {
                label: 'Insurance Rate',
                value: formatPercentage(
                    item?.sunfi_insurance || item?.insurance,
                ),
            },
            {
                label: 'Minimum Upfront Deposit',
                value: formatPercentage(item?.upfront_deposit),
            },
            {
                label: 'Debt to Equity in Project',
                value: formatPercentage(item?.debt_to_equity),
            },
            {
                label: 'Lowest Tenure',
                value: formatMonths(item?.lowest_tenure),
            },
            {
                label: 'Monthly Rate Jump',
                value: formatPercentage(item?.monthly_interest_jump),
            },
            {
                label: 'Start Declining Balance Rate',
                value: formatPercentage(item?.lowest_tenure_annual_interest),
            },
            {
                label: 'Payment Plans',
                value: item?.payment_plans_count || 0,
            },
            {
                label: 'Cost of Capital',
                value: formatPercentage(item?.cost_of_capital),
            },
            {
                label: 'Re-Investment Rate',
                value: formatPercentage(item?.reinvestment_rate),
            },
            {
                label: 'Tenure Increment',
                value: formatPercentage(item?.tenure_increment),
            },
            { label: 'Debt Months', value: formatMonths(item?.debt_months) },
        ],
    }))
}

export const formatCreditVariablesInputData = (selectedItems, bulkUpdate) => {
    return selectedItems.map(item => ({
        id: item?.id,
        name: item?.name,
        details: getCreditVariableDetails(item, bulkUpdate),
    }))
}

export const getCreditVariableDetails = (item, bulkUpdate) => {
    return [
        {
            label: 'Payment Model',
            value:
                bulkUpdate?.paymentModel ||
                item?.details?.find(d => d.label === 'Payment Model')?.value ||
                '',
            name: 'paymentModel',
        },
        {
            label: 'Debt Cost (%)',
            value: parsePercentage(
                bulkUpdate?.debtCost ||
                    item?.details?.find(d => d.label === 'Debt Cost')?.value,
            ),
            name: 'debtCost',
        },
        {
            label: 'Insurance Rate (%)',
            value: parsePercentage(
                bulkUpdate?.insuranceRate ||
                    item?.details?.find(d => d.label === 'Insurance Rate')
                        ?.value,
            ),
            name: 'insuranceRate',
        },
        {
            label: 'Minimum Upfront Deposit (%)',
            value: parsePercentage(
                bulkUpdate?.minimumUpfrontDeposit ||
                    item?.details?.find(
                        d => d.label === 'Minimum Upfront Deposit',
                    )?.value,
            ),
            name: 'minimumUpfrontDeposit',
        },
        {
            label: 'Debt to Equity in Project (%)',
            value: parsePercentage(
                bulkUpdate?.debtToEquityInProject ||
                    item?.details?.find(
                        d => d.label === 'Debt to Equity in Project',
                    )?.value,
            ),
            name: 'debtToEquityInProject',
        },
        {
            label: 'Lowest Tenure (Months)',
            value: parseMonths(
                bulkUpdate?.lowestTenure ||
                    item?.details?.find(d => d.label === 'Lowest Tenure')
                        ?.value,
            ),
            name: 'lowestTenure',
        },
        {
            label: 'Monthly Rate Jump (%)',
            value: parsePercentage(
                bulkUpdate?.monthlyRateJump ||
                    item?.details?.find(d => d.label === 'Monthly Rate Jump')
                        ?.value,
            ),
            name: 'monthlyRateJump',
        },
        {
            label: 'Start Declining Balance Rate (%)',
            value: parsePercentage(
                bulkUpdate?.startDecliningBalanceRate ||
                    item?.details?.find(
                        d => d.label === 'Start Declining Balance Rate',
                    )?.value,
            ),
            name: 'startDecliningBalanceRate',
        },
        {
            label: 'Payment Plans',
            value:
                parseInt(
                    bulkUpdate?.paymentPlans ||
                        item.details.find(d => d.label === 'Payment Plans')
                            ?.value,
                ) || 0,
            name: 'paymentPlans',
        },
        {
            label: 'Cost of Capital (%)',
            value: parsePercentage(
                bulkUpdate?.costOfCapital ||
                    item?.details?.find(d => d.label === 'Cost of Capital')
                        ?.value,
            ),
            name: 'costOfCapital',
        },
        {
            label: 'Re-Investment Rate (%)',
            value: parsePercentage(
                bulkUpdate?.reInvestmentRate ||
                    item?.details?.find(d => d.label === 'Re-Investment Rate')
                        ?.value,
            ),
            name: 'reInvestmentRate',
        },
        {
            label: 'Tenure Increment (%)',
            value: parsePercentage(
                bulkUpdate?.tenureIncrement ||
                    item?.details?.find(d => d.label === 'Tenure Increment')
                        ?.value,
            ),
            name: 'tenureIncrement',
        },
        {
            label: 'Debt Months (Months)',
            value: parseMonths(
                bulkUpdate?.debtMonths ||
                    item?.details?.find(d => d.label === 'Debt Months')?.value,
            ),
            name: 'debtMonths',
        },
    ].filter(detail => {
        if (bulkUpdate && bulkUpdate[detail.name] === undefined) {
            return false
        }

        return true
    })
}
