import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'
import {
    getRequiredKeys,
    registeredBusinessOptionsDynamicKYC,
} from '../DynamicInformation/utils'
import { sameAddressOptions } from '../OutrightSaleInformation/utils'
import useFetchKycInfo from '../DynamicInformation/hooks/useFetchKycInfo'
import useOfferInfo from '../OutrightSaleInformation/hooks/useOfferInfo'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import { useCreditDecisionStatus } from '../../AccountSetupOverview/hooks/useCreditDecisionStatus'
import { formatAccountOverviewData } from '../../AccountSetupOverview/data'
import { decodeUserInfo } from 'src/utils/auth'
import { useFetchKycModules } from '../../AccountSetupOverview/hooks/useFetchKycModules'
import { encrypt } from 'src/utils/cryptography'
import {
    extractFileNameFromUrl,
    formatInputValue,
    formatPhoneNumber,
} from 'src/utils/formatting'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateDynamicKycInfoV2 } from 'src/utils/validationSchema'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { isObjectEmpty, removeEmptyValues } from 'src/utils/removeEmptyValues'
import { useTypeMapping } from '../../ConsumerKYC/data'
import { formatBusinessType } from 'src/utils/business'
import { getPrimaryStatementType } from '../../ConsumerKYC/utils'
import { appTracking } from 'src/utils/appTracker'
import {
    formatPayload,
    getRequiredModules,
    pageTitle,
    pageTracker,
    pageUrlName,
} from './utils'
import {
    onePageSubmitAdminInfo,
    onePageSubmitConsumerInfo,
} from 'src/api/shoppingExperience/kyc/api'
import { intiateOnePageVerification } from 'src/api/consumer'
import { errorHandler } from 'src/utils/errorHandler'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { useAppContext } from 'src/appContext/AppContext'
import { useCustomFormik } from 'src/utils/Hooks/useCustomFormik'
import { useNotifyConsumerOfKycUpdate } from '../_components/Header/hooks/useNotifyConsumerOfKycUpdate'

const contextDefaultValues = {
    handleSubmit: () => {},
    showSimulateCreditDecision: false,
    setShowSimulateCreditDecision: () => {},
    isSaving: false,
    isSubmitting: false,
    submit: false,
    handleSaveProgress: () => {},
    kycStatus: {},
    paymentPlanType: '',
    disablePageButtons: false,
    inputs: {},
    useType: '',
    offer: {},
    offerFetching: false,
    isCustomerApproved: false,
    ranCreditDecision: false,
    upfrontDepositPaid: false,
    goBackToSystem: () => {},
    kycData: {},
    offerLoadingOrRefetchError: false,
    kycModules: [],
    isOpen: false,
    handleToggle: () => {},
    modulesSectionFetching: false,
    modulesLoadingOrRefetchError: false,
    handleChangePackage: () => {},
    errors: {},
    handleOnBlur: () => {},
    handleSelectOnBlur: () => {},
    handleChange: () => {},
    handleSelectChange: () => {},
    removeErrors: () => {},
    handleSameAddressOptionChange: () => {},
    isAdminUser: false,
    showRemoveFileModal: false,
    fileToBeRemoved: {},
    closeRemoveFileModal: () => {},
    closeRemoveFileModalAfterSuccess: () => {},
    showCalendar: false,
    setShowCalendar: () => {},
    setShowUploadModal: () => {},
    handleRemoveFile: () => {},
    employmentContract: [],
    setEmploymentContract: () => {},
    showUploadModal: () => {},
    handleCloseUploadModal: () => {},
    successEmploymentContractUpload: [],
    setSuccessEmploymentContractUpload: () => {},
    kycRefetch: () => {},
    showBVNModal: false,
    openBVNModal: () => {},
    closeBVNModal: () => {},
    bvnVerifiedSuccessfully: () => {},
    handleBvnVerifyLater: () => {},
    bvnVerificationInProgress: false,
    setBvnVerificationInProgress: () => {},
    startMonoLoading: () => {},
    stopMonoLoading: () => {},
    connectToMonoLoading: false,
    action: '',
    actionModalOpen: () => {},
    closeActionModal: () => {},
    handleActionPrimaryBtnClick: () => {},
    handleActionSecondaryBtnClick: () => {},
    actionModalPrimaryBtnLoading: false,
    actionModalSecondaryBtnLoading: false,
    showLoginModal: false,
    setShowLoginModal: () => {},
    closeLoginModal: () => {},
    loginModalContent: '',
    setLoginModalContent: () => {},
    loginInputs: {},
    otpMethod: '',
    setOtpMethod: () => {},
    initiateOTPVerificationMutation: () => {},
    initiateOTPVerificationLoading: false,
    onVerifySuccess: () => {},
    modalToast: {},
    handleBusinessRegisteredChange: () => {},
    showCacVerifyModal: false,
    toggleShowCacVerifyModal: () => {},
    cacVerificationInProgress: false,
    setCacVerificationInProgress: () => {},
    cacHelpPayload: {},
    requiredKycModules: [],
    moa: [],
    setMoa: () => {},
    successMOAUpload: [],
    setSuccessMOAUpload: () => {},
    requiredKycSections: [],
    kycInfoFormik: {},
    page: '',
    allRequiredFieldsPersisted: false,
    onAdminKycSaveSuccessCallback: () => {},
}

export const DynamicInformationV2Context = createContext(contextDefaultValues)

const DynamicInformationV2ContextProvider = ({ children }) => {
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        phoneVerified: false,
        email: '',
        emailVerified: false,
        contactAddress: '',
        contactState: '',
        contactCity: '',
        contactCountry: '',
        sameAddress: sameAddressOptions[0],
        installationAddress: '',
        installationCountry: '',
        installationState: '',
        installationCity: '',
        employmentType: '',
        companyName: '',
        jobTitle: '',
        jobStartDate: '',
        employmentContractUrl: '',
        employmentSuccessUpload: { name: '', originFileObj: '', type: '' },
        linkedinPage: '',
        netMonthlySalary: '',
        employeeId: '',
        registeredBusiness: registeredBusinessOptionsDynamicKYC[0],
        businessName: '',
        businessIndustry: '',
        cacNumber: '',
        cacNumberVerified: false,
        numberOfYearsInOperation: '',
        businessWebsite: '',
        businessDescription: '',
        pensionManager: '',
        retirementDate: '',
        selfieImageUrl: '',
        identityType: '',
        identityNumber: '',
        identityVerificationId: '',
        identityVerified: false,
        bvn: '',
        bvnVerified: false,
        bankStatements: [],
        bankStatementId: '',
        businessType: '',
        moaUrl: '',
        moaSuccessUpload: { name: '', originFileObj: '', type: '' },
        directorFirstName: '',
        directorLastName: '',
        directorEmail: '',
        directorPhone: '',
        auditedFinancialReportUrl: '',
    })
    const [isOpen, setIsOpen] = useState(Array(3).fill(true))
    const [showSimulateCreditDecision, setShowSimulateCreditDecision] =
        useState(false)
    const [errors, setErrors] = useState({})
    const [requiredKycSections, setRequiredKycSections] = useState([])
    const [requiredKycModules, setRequiredKycModules] = useState([])
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        key: '',
        title: '',
        url: '',
        fileType: '',
    })
    const [showCalendar, setShowCalendar] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [employmentContract, setEmploymentContract] = useState([])
    const [
        successEmploymentContractUpload,
        setSuccessEmploymentContractUpload,
    ] = useState([])
    const [showBVNModal, setShowBVNModal] = useState(false)
    const [bvnVerificationInProgress, setBvnVerificationInProgress] =
        useState(false)
    const [connectToMonoLoading, setConnectToMonoLoading] = useState(false)
    const [action, setAction] = useState('')
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [showStatementReviewInProgress, setShowStatementReviewInProgress] =
        useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [otpMethod, setOtpMethod] = useState('') // email | phone
    const [loginModalContent, setLoginModalContent] =
        useState('select-otp-method') // select-otp-method | enter-otp
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [modalToast, setModalToast] = useState(toastData)
    const [showCacVerifyModal, setShowCacVerifyModal] = useState(false)
    const [cacVerificationInProgress, setCacVerificationInProgress] =
        useState(false)
    const [successMOAUpload, setSuccessMOAUpload] = useState([])
    const [moa, setMoa] = useState([])
    const [initialValues, setInitialValues] = useState(inputs)
    const [persistedValues, setPersistedValues] = useState({})
    const [allRequiredFieldsPersisted, setAllRequiredFieldsPersisted] =
        useState(false)

    const history = useHistory()
    const queryClient = useQueryClient()
    const { id } = useParams()
    const backAction = useRef('')

    const userInfo = decodeUserInfo()
    const { successAlert, BVNAlert, errorAlert } = useCustomToast()
    const { state } = useAppContext()
    const { notifyConsumerOfUpdate } = useNotifyConsumerOfKycUpdate()

    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const { kycData, kycInfoFetching, kycRefetch } = useFetchKycInfo(
        inputs,
        setInputs,
        id,
        true,
        (data, generalInfo) => {
            const auditedFinancialReportObj = removeEmptyValues({
                url: data?.consumer?.audited_financial_reports[0]?.url,
                name: extractFileNameFromUrl(
                    data?.consumer?.audited_financial_reports[0]?.url,
                ),
            })
            setInitialValues(prev => ({
                ...prev,
                ...generalInfo,
                ...(isObjectEmpty(auditedFinancialReportObj)
                    ? { auditedFinancialReportUrl: '' }
                    : { auditedFinancialReportUrl: auditedFinancialReportObj }),
            }))
            setPersistedValues(prev => ({
                ...prev,
                ...generalInfo,
                ...(isObjectEmpty(auditedFinancialReportObj)
                    ? { auditedFinancialReportUrl: '' }
                    : { auditedFinancialReportUrl: auditedFinancialReportObj }),
            }))
        },
    )
    const { offer, offerFetching, offerFetchStatus } = useOfferInfo(
        formatAccountOverviewData,
    )
    const { kycStatus, statusRefetch } = useKYCCompletionStatus(
        offer?.id,
        offer?.use_type,
        true,
    )
    const validateField = useValidateOnBlur()
    const { logOut, logoutLoading } = useLogOut('/')
    const bankStatementNotReviewed =
        requiredKycSections?.includes('bank_statement') &&
        !kycData?.financial_verification?.bank_statements?.[0]
            ?.analysis_complete
    const canSubmitKYC = inputs?.emailVerified || inputs?.phoneVerified
    const loginInputs = {
        email: inputs?.email,
        phone: formatPhoneNumber(inputs?.phone),
    }
    const showSimulateCreditEngineDecision =
        process.env.REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE === 'true'

    const resetToast = () => {
        setTimeout(() => {
            setModalToast(toastData)
        }, 2000)
    }

    const onModulesFetchSuccess = data => {
        const requiredKeys = getRequiredKeys(data)
        const requiredModules = getRequiredModules(data?.modules)
        const modulesLength = requiredModules?.length
        setRequiredKycSections(requiredKeys)
        setRequiredKycModules(requiredModules)
        setIsOpen(Array(modulesLength).fill(true))
    }
    const { kycModules, kycModulesFetching, kycModulesFetchStatus } =
        useFetchKycModules(offer?.id, isAdminUser, true, onModulesFetchSuccess)

    const { ranCreditDecision, upfrontDepositPaid } = useCreditDecisionStatus(
        offer?.id,
    )

    const kycInfoFormik = useCustomFormik({
        initialValues: initialValues,
        validationSchema: validateDynamicKycInfoV2,
    })

    const handleToggle = index => {
        const updatedOpenState = isOpen.map((open, i) =>
            i === index ? !open : open,
        )
        setIsOpen(updatedOpenState)
    }

    const openBVNModal = () => {
        setShowBVNModal(true)
    }

    const closeBVNModal = () => {
        setShowBVNModal(false)
    }

    const bvnVerifiedSuccessfully = () => {
        queryClient.invalidateQueries('fetch-dynamic-kyc-info')
        closeBVNModal()
        successAlert('Your BVN has been verified successfully')
        removeErrors('bvn')
        removeErrors('bvnVerified')
    }

    const handleBvnVerifyLater = () => {
        closeBVNModal()
        BVNAlert('Please, go ahead to provide your other details', '511px')
    }

    const startMonoLoading = () => {
        setConnectToMonoLoading(true)
    }

    const stopMonoLoading = () => {
        setConnectToMonoLoading(false)
    }

    const closeLoginModal = () => {
        setShowLoginModal(false)
        setLoginModalContent('')
    }

    const abTestInBoundRequest =
        history?.location?.state?.abTestInBoundRequest ?? false
    const convertedOnboardingType =
        offer?.onboardingType === 'ALL_SOLUTIONS' ||
        offer?.onboardingType === 'ENERGY_PROFILE'
            ? 'profiles-or-all-systems'
            : 'custom-build'
    const onboardingType =
        history?.location?.state?.onboardingType ?? convertedOnboardingType
    const page = 'one-page-dynamic-kyc'

    const goBackToSystem = () => {
        if (kycInfoFormik.dirty) {
            openActionModal('save-and-continue-later')
        } else {
            history.push({
                pathname: `/consumer/view-system/${offer?.solution_id}`,
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: offer?.id,
                    action: 'view_from_account_setup',
                    auth_user_id: kycData?.consumer?.user?.id,
                    page: offer?.isPromo && 'promo',
                    clusterCode: offer?.clusterCode,
                    payment_model: offer?.payment_plan,
                    onboardingType,
                    profile_id:
                        kycData?.data?.data?.consumer_form_questions
                            ?.energy_profile_id,
                    returnToKyc: offer?.isDynamicKycUser,
                    solution_id: offer?.solution_id,
                    previous_payment_model: offer?.payment_plan,
                    returnToWorkspace: history.location?.state?.from?.includes(
                        '/consumer/workspace',
                    ),
                    abTestInBoundRequest,
                },
            })
        }
    }

    const handleChangePackage = () => {
        const state = {
            isAuthenticatedUser: true,
            estimation_id: offer?.id,
            auth_user_id: kycData?.consumer?.user?.id,
            action: 'view_from_one_page_kyc_form',
            solution_id: offer?.solution_id,
            payment_type: offer?.payment_plan,
            phone_number: inputs?.phone,
            email: inputs?.email,
        }
        const allSolutions = offer?.onboardingType === 'ALL_SOLUTIONS'
        const profile = offer?.onboardingType === 'ENERGY_PROFILE'
        const profile_id = offer?.profileId
        localStorage.setItem(
            'sunfiUserSSEConsumerKYC',
            encrypt(JSON.stringify(inputs)),
        )
        if (allSolutions) {
            history.push({
                pathname: '/consumer/all-systems',
                state,
            })
        } else if (profile) {
            history.push({
                pathname: `/consumer/profile-details/${profile_id}`,
                state,
            })
        } else {
            history.push({
                pathname: offer?.isPromo
                    ? '/consumer/promos'
                    : '/consumer/systems',
                state,
            })
        }
    }

    const openActionModal = useCallback(action => {
        setAction(action)
        setActionModalOpen(true)
    }, [])

    const closeActionModal = () => {
        setAction('')
        setActionModalOpen(false)
    }

    const handleActionPrimaryBtnClick = () => {
        switch (action) {
            case 'save-and-continue-later':
                if (canSubmitKYC) {
                    setActionModalOpen(false)
                    handleSaveOrSubmitCallback()
                } else {
                    setActionModalOpen(false)
                    setLoginModalContent('select-otp-method')
                    setShowLoginModal(true)
                }
                break
            case 'progress-saved':
                logOut()
                break
            case 'bank-statement-in-review':
                handleSaveOrSubmitCallback()
                break
            case 'progress-saved-by-admin':
            case 'consumer-notified-of-save-by-admin':
                closeActionModal()
                break
        }
    }

    const handleActionSecondaryBtnClick = () => {
        switch (action) {
            case 'save-and-continue-later':
            case 'progress-saved':
                closeActionModal()
                break
            case 'progress-saved-by-admin':
                notifyConsumerOfUpdate.mutate({
                    estimationId: id,
                    handleSuccess: () => {
                        openActionModal('consumer-notified-of-save-by-admin')
                    },
                })
                break
        }
    }

    const handleSaveProgress = useCallback(() => {
        if (!isObjectEmpty(errors)) {
            errorAlert(
                'Please resolve the errors in the form before saving your progress.',
            )
        } else {
            openActionModal('save-and-continue-later')
        }
    }, [errors, errorAlert, openActionModal])

    const onVerifySuccess = () => {
        setShowLoginModal(false)
        if (bankStatementNotReviewed && showStatementReviewInProgress) {
            setSubmit(false)
            openActionModal('bank-statement-in-review')
        } else {
            handleSaveOrSubmitCallback()
        }
    }

    const submitKYCMutation = useMutation({
        mutationFn: inputs =>
            isAdminUser
                ? onePageSubmitAdminInfo({
                      type: offer?.use_type,
                      data: formatPayload(
                          inputs,
                          offer,
                          requiredKycSections,
                          false,
                      ),
                      estimationID: offer.id,
                  })
                : onePageSubmitConsumerInfo({
                      type: offer?.use_type,
                      data: formatPayload(
                          inputs,
                          offer,
                          requiredKycSections,
                          submit,
                      ),
                  }),

        onSuccess: () => {
            if (!isAdminUser) {
                const mixpanelSubmitPayload = {
                    'Use type': useTypeMapping[offer?.use_type],
                    ...(inputs?.employmentType && {
                        'Employment type': inputs?.employmentType,
                    }),
                    ...(inputs?.businessType && {
                        'Business type': formatBusinessType(
                            inputs?.businessType,
                        ),
                    }),
                    'Self employed registration status':
                        inputs?.employmentType === 'Self Employed'
                            ? 'Yes'
                            : 'No',
                    'Multiple address state': inputs?.sameAddress?.value,
                    'Plan Type': offer?.payment_plan,
                    'Customer Channel':
                        offer?.survey_type === 'DFS' ? 'DEP' : 'DTC',
                    'Package Cost': offer?.total_price,
                    'No of Appliance': offer?.appliances_count,
                    'Package Size': offer?.capacity,
                    'Monthly Repayment': offer?.price_per_month,
                    'Bank statement type': getPrimaryStatementType(
                        inputs?.bankStatements,
                    ),
                }
                const mixpanelSavePayload = {
                    'Use type': useTypeMapping[offer?.use_type],
                    'Package Size': offer?.capacity,
                    'Plan Type': offer?.payment_plan,
                    'No of Appliance': offer?.appliances_count,
                    'Monthly Repayment': offer?.price_per_month,
                    'Customer Channel':
                        offer?.survey_type === 'DFS' ? 'DEP' : 'DTC',
                }
                const eventActionAndCategory = submit
                    ? 'Submitted KYC'
                    : 'Saved KYC Data'

                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventActionAndCategory,
                    '1-page KYC',
                    eventActionAndCategory,
                    ['MP'],
                    'event',
                    {
                        event: submit
                            ? mixpanelSubmitPayload
                            : mixpanelSavePayload,
                    },
                )
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventActionAndCategory,
                    '1-page KYC',
                    eventActionAndCategory,
                    ['MP'],
                    'people_set',
                    {
                        people_set: submit
                            ? mixpanelSubmitPayload
                            : mixpanelSavePayload,
                    },
                )
            }

            if (!submit && !isAdminUser) {
                if (
                    action === 'save-and-continue-later' ||
                    action === 'bank-statement-in-review'
                ) {
                    kycRefetch()
                    statusRefetch()
                    openActionModal('progress-saved')
                    setShowStatementReviewInProgress(false)
                }
            } else if (submit && !isAdminUser) {
                kycRefetch()
                statusRefetch()
                successAlert('KYC submitted successfully')
                if (showSimulateCreditEngineDecision) {
                    setShowSimulateCreditDecision(true)
                } else {
                    history.push({
                        pathname: '/consumer/account-setup/credit-decision',
                        state: {
                            estimationId: offer?.id,
                            paymentPlanType: offer?.paymentType,
                        },
                    })
                }
            } else if (isAdminUser) {
                kycRefetch()
                statusRefetch()
                openActionModal('progress-saved-by-admin')
            }
            setSubmit(false)
        },
        onError: error => {
            const msg =
                errorHandler(error?.response?.data) ||
                `Unable to ${submit ? 'submit' : 'save'}, please try again`
            if (submit) {
                errorAlert(msg)
            } else {
                setModalToast({
                    showToast: true,
                    toastMessage: msg,
                    messageType: 'error-secondary',
                })
                resetToast()
            }
        },
    })

    const initiateOTPVerificationMutation = useMutation({
        mutationFn: () =>
            intiateOnePageVerification({
                ...(otpMethod === 'phone'
                    ? { phone_number: formatPhoneNumber(inputs?.phone) }
                    : { email: inputs?.email }),
            }),
        onSuccess: () => {
            setLoginModalContent('enter-otp')
            setErrors({})
        },
        onError: error => {
            setModalToast({
                showToast: true,
                toastMessage:
                    error?.[0] ||
                    errorHandler(error?.response?.data) ||
                    'Unable to verify your contact, please try again',
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    const disablePageButtons =
        kycInfoFetching || offerFetching || kycModulesFetching
    const modulesSectionFetching = kycInfoFetching || offerFetching
    const actionModalPrimaryBtnLoading =
        submitKYCMutation.isLoading || logoutLoading

    const actionModalSecondaryBtnLoading = notifyConsumerOfUpdate.isLoading

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const validationSchemaContext = {
        sameAddress: inputs?.sameAddress?.value,
        requiredKycSections: requiredKycSections,
        isRegisteredBusiness: inputs?.registeredBusiness?.value,
        identityType: inputs?.identityType,
        bvnVerified: inputs?.bvnVerified,
        hasSubmittedBankStatement:
            kycData?.financial_verification?.bank_statements?.length > 0,
        useType: offer?.use_type,
        moaRequired:
            offer?.use_type === 'BUSINESS' &&
            requiredKycSections?.includes('business') &&
            inputs?.businessType !== 'Non-governmental Organisation',
        moaEmpty:
            inputs?.moaSuccessUpload?.name === '' && inputs?.moaUrl === '',
    }

    const { uploadToS3Mutate, uploadToS3Loading } = useUploadToS3({
        onSuccess: data => {
            setInputs(prevInputs => {
                const newInputs = {
                    ...prevInputs,
                    employmentContractUrl: data,
                }
                submitKYCMutation.mutate(newInputs)
                return newInputs
            })
        },
    })

    const {
        uploadToS3Mutate: uploadMoaMutate,
        uploadToS3Loading: uploadMoaLoading,
    } = useUploadToS3({
        onSuccess: data => {
            setInputs(prevInputs => {
                const newInputs = {
                    ...prevInputs,
                    moaUrl: data,
                }
                submitKYCMutation.mutate(newInputs)
                return newInputs
            })
        },
    })

    const isSaving =
        (submitKYCMutation.isLoading ||
            uploadToS3Loading ||
            uploadMoaLoading) &&
        !submit &&
        !actionModalOpen
    const isSubmitting =
        (submitKYCMutation.isLoading ||
            uploadToS3Loading ||
            uploadMoaLoading) &&
        submit

    const handleSaveOrSubmitCallback = () => {
        if (
            offer?.use_type === 'RESIDENTIAL' &&
            requiredKycSections?.includes('employment_details') &&
            inputs?.employmentSuccessUpload?.name?.length
        ) {
            uploadToS3Mutate({
                category: 'EMPLOYMENT_CONTRACT',
                file: inputs?.employmentSuccessUpload,
            })
        }
        // registered business type user just uploaded moa
        else if (
            offer?.use_type === 'BUSINESS' &&
            requiredKycSections?.includes('business') &&
            inputs?.businessType !== 'Non-governmental Organisation' &&
            inputs?.moaSuccessUpload?.name?.length > 0
        ) {
            uploadMoaMutate({
                category: 'MEMORANDUM_OF_ASSOCIATION',
                file: inputs?.moaSuccessUpload,
            })
        } else {
            submitKYCMutation.mutate(inputs)
        }
    }

    const handleSubmit = () => {
        setSubmit(true)

        validateDynamicKycInfoV2
            .validate(inputs, {
                context: validationSchemaContext,
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                if (canSubmitKYC) {
                    if (bankStatementNotReviewed) {
                        setSubmit(false)
                        openActionModal('bank-statement-in-review')
                    } else {
                        handleSaveOrSubmitCallback()
                    }
                } else {
                    setShowStatementReviewInProgress(true)
                    setLoginModalContent('select-otp-method')
                    setShowLoginModal(true)
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
                setSubmit(false)
            })
    }

    const handleOnBlur = async e => {
        if (isAdminUser) return

        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateDynamicKycInfoV2,
            setErrors,
            context: validationSchemaContext,
        })
    }

    const handleSelectOnBlur = async name => {
        if (isAdminUser) return

        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateDynamicKycInfoV2,
            setErrors,
            context: validationSchemaContext,
        })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
        kycInfoFormik.setFieldValue(name, value)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)

        if (name === 'contactState') {
            setInputs(prev => ({
                ...prev,
                contactCity: '',
            }))
        }

        if (name === 'installationState') {
            setInputs(prev => ({
                ...prev,
                installationCity: '',
            }))
        }
        kycInfoFormik.setFieldValue(name, value)
    }

    const handleSameAddressOptionChange = option => {
        const selectedOption = sameAddressOptions.find(
            opt => opt.value === option,
        )

        setInputs(prev => ({
            ...prev,
            sameAddress: selectedOption,
            installationAddress: '',
            installationCountry: '',
            installationState: '',
            installationCity: '',
        }))

        if (selectedOption?.value) {
            removeErrors('installationAddress')
            removeErrors('installationCountry')
            removeErrors('installationState')
            removeErrors('installationCity')
        }
        kycInfoFormik.setFieldValue('sameAddress', selectedOption)
    }

    const handleBusinessRegisteredChange = option => {
        const selectedOption = registeredBusinessOptionsDynamicKYC.find(
            opt => opt.title === option,
        )

        setInputs(prev => ({
            ...prev,
            registeredBusiness: selectedOption,
        }))
        kycInfoFormik.setFieldValue('registeredBusiness', selectedOption)
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            key: '',
            title: '',
            url: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.key === 'employment-contract') {
            setEmploymentContract([])
            if (inputs?.employmentContractUrl?.length > 0) {
                setInputs(prev => ({
                    ...prev,
                    employmentContractUrl: '',
                    employmentSuccessUpload: {
                        name: '',
                        originFileObj: '',
                        type: '',
                    },
                }))
            }
        } else if (fileToBeRemoved.key === 'moa') {
            setMoa([])

            if (inputs?.moaUrl?.length > 0) {
                setInputs(prev => ({
                    ...prev,
                    moaUrl: '',
                    moaSuccessUpload: { name: '', originFileObj: '', type: '' },
                }))
            }
        }

        setShowRemoveFileModal(false)
    }

    const handleRemoveFile = (key, title, url) => {
        setFileToBeRemoved({
            key: key,
            title: title,
            url: url,
            fileType:
                key === 'employment-contract'
                    ? 'EMPLOYMENT_CONTRACT'
                    : key === 'moa'
                    ? 'MEMORANDUM_OF_ASSOCIATION'
                    : '',
        })
        setShowRemoveFileModal(true)
    }

    const handleCloseUploadModal = () => {
        setShowUploadModal(false)
        setSuccessEmploymentContractUpload([])
        setSuccessMOAUpload([])
    }

    const toggleShowCacVerifyModal = () => {
        setShowCacVerifyModal(prev => !prev)
    }

    const cacHelpPayload = {
        email: inputs?.email || 'support@sunfi.co',
        name: inputs?.businessName,
        message: 'CAC Verification failed and consumer is requesting for help',
        phone_number: formatPhoneNumber(inputs?.phone) || '+2347080639920',
    }

    const onAdminKycSaveSuccessCallback = () => {
        kycRefetch()
    }

    useEffect(() => {
        if (employmentContract[0]?.name?.length > 0) {
            setInputs(prev => ({
                ...prev,
                employmentSuccessUpload: employmentContract[0],
            }))
        }
    }, [employmentContract])

    useEffect(() => {
        if (moa[0]?.name?.length > 0) {
            setInputs(prev => ({
                ...prev,
                moaSuccessUpload: moa[0],
            }))
        }
    }, [moa])

    useEffect(() => {
        setInputs(prev => ({
            ...prev,
            auditedFinancialReportUrl: state?.auditFile?.url,
        }))
        removeErrors('auditedFinancialReportUrl')
    }, [state?.auditFile])

    useEffect(() => {
        const persistedValuesValidationSchemaContext = {
            sameAddress: persistedValues?.sameAddress?.value,
            requiredKycSections: requiredKycSections,
            isRegisteredBusiness: persistedValues?.registeredBusiness?.value,
            identityType: persistedValues?.identityType,
            bvnVerified: persistedValues?.bvnVerified,
            hasSubmittedBankStatement:
                kycData?.financial_verification?.bank_statements?.length > 0,
            useType: offer?.use_type,
            moaRequired:
                offer?.use_type === 'BUSINESS' &&
                requiredKycSections?.includes('business') &&
                persistedValues?.businessType !==
                    'Non-governmental Organisation',
            moaEmpty:
                persistedValues?.moaSuccessUpload?.name === '' &&
                persistedValues?.moaUrl === '',
        }

        validateDynamicKycInfoV2
            .validate(persistedValues, {
                context: persistedValuesValidationSchemaContext,
                abortEarly: false,
            })
            .then(() => {
                setAllRequiredFieldsPersisted(true)
            })
            .catch(() => {
                setAllRequiredFieldsPersisted(false)
            })
    }, [persistedValues, offer, kycData, requiredKycSections])

    useEffect(() => {
        const handleSaveBeforeUnload = event => {
            if (kycInfoFormik.dirty && !isAdminUser) {
                handleSaveProgress()
                event.preventDefault()
                event.returnValue = ''
            }
        }
        window.addEventListener('beforeunload', handleSaveBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleSaveBeforeUnload)
        }
        // eslint-disable-next-line
    }, [kycInfoFormik.dirty])

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                if (
                    kycInfoFormik.dirty &&
                    backAction.current !== 'discard-changes'
                ) {
                    backAction.current = 'unsaved-changes-prompt'
                    handleSaveProgress()
                    return false
                }

                backAction.current = ''
                return true
            }
            return true
        })
        return unblock
    }, [handleSaveProgress, history, kycInfoFormik.dirty])

    return (
        <DynamicInformationV2Context.Provider
            value={{
                handleSubmit,
                showSimulateCreditDecision,
                setShowSimulateCreditDecision,
                isSaving,
                isSubmitting,
                submit,
                handleSaveProgress,
                kycStatus,
                paymentPlanType: offer?.payment_plan,
                disablePageButtons,
                inputs,
                useType: offer?.use_type,
                offer,
                offerFetching,
                isCustomerApproved: offer?.isCustomerApproved,
                ranCreditDecision,
                upfrontDepositPaid,
                goBackToSystem,
                kycData,
                offerLoadingOrRefetchError: offerFetchStatus === 'error',
                kycModules,
                isOpen,
                handleToggle,
                modulesSectionFetching,
                modulesLoadingOrRefetchError: kycModulesFetchStatus === 'error',
                handleChangePackage,
                errors,
                handleOnBlur,
                handleSelectOnBlur,
                handleChange,
                handleSelectChange,
                removeErrors,
                handleSameAddressOptionChange,
                isAdminUser,
                showRemoveFileModal,
                fileToBeRemoved,
                closeRemoveFileModal,
                closeRemoveFileModalAfterSuccess,
                showCalendar,
                setShowCalendar,
                setShowUploadModal,
                handleRemoveFile,
                employmentContract,
                setEmploymentContract,
                showUploadModal,
                handleCloseUploadModal,
                successEmploymentContractUpload,
                setSuccessEmploymentContractUpload,
                kycRefetch,
                showBVNModal,
                openBVNModal,
                closeBVNModal,
                bvnVerifiedSuccessfully,
                handleBvnVerifyLater,
                bvnVerificationInProgress,
                setBvnVerificationInProgress,
                startMonoLoading,
                stopMonoLoading,
                connectToMonoLoading,
                action,
                actionModalOpen,
                closeActionModal,
                handleActionPrimaryBtnClick,
                handleActionSecondaryBtnClick,
                actionModalPrimaryBtnLoading,
                actionModalSecondaryBtnLoading,
                showLoginModal,
                setShowLoginModal,
                closeLoginModal,
                loginModalContent,
                setLoginModalContent,
                loginInputs,
                otpMethod,
                setOtpMethod,
                initiateOTPVerificationMutation,
                initiateOTPVerificationLoading:
                    initiateOTPVerificationMutation.isLoading,
                onVerifySuccess,
                modalToast,
                handleBusinessRegisteredChange,
                showCacVerifyModal,
                toggleShowCacVerifyModal,
                cacVerificationInProgress,
                setCacVerificationInProgress,
                cacHelpPayload,
                requiredKycModules,
                moa,
                setMoa,
                successMOAUpload,
                setSuccessMOAUpload,
                requiredKycSections,
                kycInfoFormik,
                page,
                allRequiredFieldsPersisted,
                onAdminKycSaveSuccessCallback,
            }}
        >
            {children}
        </DynamicInformationV2Context.Provider>
    )
}

DynamicInformationV2ContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const useDynamicInformationV2Context = () =>
    useContext(DynamicInformationV2Context)
export default DynamicInformationV2ContextProvider
