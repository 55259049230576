import { ConsumerButton } from 'src/components/Button'
import styles from './signinnavigation.module.scss'
import { ReactComponent as LightBulb } from 'src/assets/images/lightbulb.svg'

const SignInNavigation = () => {
    const { nav, logo } = styles
    return (
        <nav className={nav} data-testid="signin-nav">
            <img
                src={
                    'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png'
                }
                alt="SunFi logo"
                className={logo}
                onClick={() => (window.location.pathname = '/')}
            />

            <ConsumerButton
                type="iconBtn"
                btnBgColor="#004AAD"
                btnTextColor="#FFFFFF"
                btnHeight="50px"
                btnWidth="154.44px"
                btnFontSize={'14px'}
                handleClick={() => (window.location.pathname = '/')}
            >
                <LightBulb role="presentation" />
                <span
                    style={{
                        paddingLeft: '7px',
                        font: '14px/24px SF-Pro-Display-SemiBold',
                    }}
                >
                    Get Your Offer
                </span>
            </ConsumerButton>
        </nav>
    )
}

export default SignInNavigation
