import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { logoutApi } from 'src/api/logout'
import { getToken, logsOutUser } from '../auth'

function useLogOut(redirectPath = '') {
    const history = useHistory
    const { refetch, isFetching } = useQuery(
        'logoutConsumer',
        () => logoutApi({ access_token: getToken() }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                logsOutUser('', redirectPath)
            },
            onError: () => history.push('/'),
        },
    )

    return { logOut: refetch, logoutLoading: isFetching }
}

export default useLogOut
