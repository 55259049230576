import { useState } from 'react'

const useOneToMultipleBulkEditModal = (
    closeModal,
    openDrawer,
    setBulkUpdate,
) => {
    const [variableInputs, setVariableInputs] = useState([
        {
            type: '',
            value: '',
        },
    ])
    const [selectedVariables, setSelectedVariables] = useState({})

    const [errors, setErrors] = useState({})

    const onCancel = () => {
        setVariableInputs([
            {
                type: '',
                value: '',
            },
        ])
        setSelectedVariables({})
        closeModal()
    }

    const formatInputValue = (name, value) => {
        const formatters = {
            paymentModel: value => value,
            debtCost: formatNumberInput,
            insuranceRate: formatNumberInput,
            minimumUpfrontDeposit: formatNumberInput,
            debtToEquityInProject: formatNumberInput,
            lowestTenure: formatIntegerInput,
            monthlyRateJump: formatNumberInput,
            startDecliningBalanceRate: formatNumberInput,
            paymentPlans: formatIntegerInput,
            costOfCapital: formatNumberInput,
            reInvestmentRate: formatNumberInput,
            tenureIncrement: formatNumberInput,
            debtMonths: formatIntegerInput,
        }

        const formatter = formatters[name]
        return formatter ? formatter(value) : value
    }

    const formatNumberInput = value => {
        // allow numbers and decimal points
        return value.replace(/[^\d.]/g, '')
    }

    const formatIntegerInput = value => {
        // allow only digits
        return value.replace(/[^\d]/g, '')
    }

    const handleChange = (e, itemIndex) => {
        const { name, value } = e.target

        const clonedVariableInputs = [...variableInputs]

        const formattedValue = formatInputValue(
            clonedVariableInputs[itemIndex].type,
            value,
        )

        clonedVariableInputs[itemIndex].value = formattedValue

        setVariableInputs(clonedVariableInputs)
        updateSelectedVariables(clonedVariableInputs)

        setErrors(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleOnBlur = async (e, itemIndex) => {
        const { name } = e.target

        if (variableInputs[itemIndex].value == '') {
            setErrors(prev => ({
                ...prev,
                [name]: 'Please enter a value',
            }))
        }
    }

    const handleSelectOnBlur = async (name, itemIndex) => {
        if (variableInputs[itemIndex].type == '') {
            setErrors(prev => ({
                ...prev,
                [name]: 'Please select a value',
            }))
        }
    }

    const updateSelectedVariables = updatedVariableInputs => {
        const updatedSelectedVariables = {}

        for (const variableInput of updatedVariableInputs) {
            if (variableInput.type) {
                updatedSelectedVariables[variableInput.type] =
                    variableInput.value || ''
            }
        }

        setSelectedVariables(updatedSelectedVariables)
    }

    const handleSelectChange = (name, value, itemIndex) => {
        const clonedVariableInputs = [...variableInputs]

        clonedVariableInputs[itemIndex].type = value

        setVariableInputs(clonedVariableInputs)
        updateSelectedVariables(clonedVariableInputs)

        setErrors(prev => ({
            ...prev,
            [name]: '',
        }))
    }

    const handleAddVariableInput = () => {
        const clonedVariableInputs = [
            ...variableInputs,
            {
                type: '',
                value: '',
            },
        ]

        setVariableInputs(clonedVariableInputs)
    }

    const handleRemoveVariableInput = itemIndex => {
        const clonedVariableInputs = [...variableInputs]

        clonedVariableInputs.splice(itemIndex, 1)

        updateSelectedVariables(clonedVariableInputs)

        setVariableInputs(clonedVariableInputs)
    }

    const isFormValid = () => {
        let isValid = true

        for (const variableInput of variableInputs) {
            if (
                variableInput.type === '' ||
                variableInput.type === undefined ||
                variableInput.value === '' ||
                variableInput.value === undefined
            ) {
                isValid = false
                break
            }
        }

        return isValid
    }

    const handleContinue = async () => {
        setBulkUpdate(selectedVariables)
        onCancel()
        openDrawer()
    }

    return {
        onCancel,
        handleContinue,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
        variableInputs,
        selectedVariables,
        handleAddVariableInput,
        handleRemoveVariableInput,
        isFormValid,
    }
}

export default useOneToMultipleBulkEditModal
