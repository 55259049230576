import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'src/utils/mediaQueries'
import ActivePlansTable from './components/ActivePlansTable'
import './consumerworkspace.scss'
import { formattedActivePlans, formatCardsData } from './data'
import { Carousel, Skeleton, Tabs } from 'antd'
import RecentUpdates from './components/RecentUpdates'
import SeoComponent from 'src/components/Seo'
import DecisionIsYes from '../SmartShoppingExperience/CreditDecision/components/DecisionIsYes'
import {
    useSSERetrieveConsumerEstimation,
    useSSERetrieveConsumerEstimationSummary,
    useRetriveRepaymentMethodSetupType,
} from 'src/api/shoppingExperience/consumer-estimation/queries'
import { getSSERecentUpdatesApi } from 'src/api/consumer/requestUpdate'
import Toast from 'src/components/Toast'
import NairaSymbol from 'src/components/NairaSymbol'
import { getProfileInformationApi } from 'src/api/profile'
import { appTracking } from 'src/utils/appTracker'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { verifyPaystackDirectDebitApi } from 'src/api/consumer/directDebit'
import ActionModal from 'src/components/ActionModalV2'
import { eventTrackers } from 'src/utils/eventTrackers'
import { getGreetingTime, getKeyByValue } from 'src/utils/formatting'
import repaymentSetupMapping from 'src/utils/Data/repaymentSetupMapping'
import { ConsumerButton } from 'src/components/Button'
import RequestNewDealModal from './components/RequestNewDealModal'
import useGetReturningCustomerStatus from 'src/components/ConsumerLandingPageBody/hooks/useGetReturningCustomerStatus'
import { useSendAgreementReminderEmail } from '../SmartShoppingExperience/CreditDecision/utils/queries'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
} from 'src/utils/urlTracker'

const ConsumerWorkspace = () => {
    const consumerWorkspaceTabs = {
        1: 'recent-updates',
        2: 'active-plans',
    }
    const history = useHistory()
    const filterQuery = getSearchParamsFromUrl()

    const pageTitle =
        'Consumer Workspace | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CONSUMER_WORKSPACE_SSE'

    const [showSubTenure, setShowSubTenure] = useState(false)
    const [showSubscriptionPlan, setShowSubscriptionPlan] = useState({
        primary: false,
        secondary: false,
    })
    const [showisLeaseToOwnPlan, setShowisLeaseToOwnPlan] = useState({
        primary: false,
        secondary: false,
    })
    const [showPaystackModal, setShowPaystackModal] = useState(false)
    const [showRequestNewDealModal, setShowRequestNewDealModal] =
        useState(false)
    const [
        showAgreementSigningConfirmationModal,
        setShowAgreementSigningConfirmationModal,
    ] = useState(false)
    const [paystackLoading, setPaystackLoading] = useState(false)

    const handleShowSubscriptionPlan = () => {
        setShowSubscriptionPlan({ primary: false, secondary: true })
        setShowSubTenure(true)
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseTenureSetup'].action,
            eventTrackers['sseTenureSetup'].label,
            eventTrackers['sseTenureSetup'].category,
        )
    }

    const handleLeaseRepaymentSetup = () => {
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: true,
        })
    }
    const handleShowisLeaseToOwnPlan = () => {
        setShowisLeaseToOwnPlan({ primary: false, secondary: true })
    }
    const handlePaystackModal = () => {
        setShowPaystackModal(true)
    }
    const queryParams = new URLSearchParams(window.location.search)
    const pageTab = queryParams.get('tab')
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(consumerWorkspaceTabs, filterQuery.tab) || '1',
    )
    const [selectedEstimation, setSelectedEstimation] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [allUpdates, setAllUpdates] = useState([])
    const [pageSize] = useState(10)
    const isSubscriptionPlan = history?.location?.state?.isSubscription ?? false
    const isLeaseToOwnPlan = history?.location?.state?.isLeaseToOwn ?? false
    const isWithSuggestion = history?.location?.state?.isWithSuggestion ?? false
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        consumerEmail: '',
        phone: '',
        isPhoneNumberVerified: false,
        isEmailVerified: false,
    })
    const [repaymentMethodType, setRepaymentMethodType] = useState('')
    const [accountDetails, setAccountDetails] = useState({})
    const { errorAlert } = useCustomToast()
    const [showVerifyPaystackModal, setShowVerifyPaystackModal] =
        useState(false)
    const [verifyPaystackError, setVerifyPaystackError] = useState(false)
    const [returningCustomerStatus, setReturningCustomerStatus] = useState('')

    const allowReturningCustomers =
        process.env.REACT_APP_FLAG_RETURNING_DTC_CUSTOMERS === 'true'

    const { getReturningCustomerStatus } = useGetReturningCustomerStatus({
        email: userInfo?.email,
        phone: userInfo?.phone,
        onSuccess: data => {
            setReturningCustomerStatus(data?.status)
        },
    })

    useEffect(() => {
        if (
            allowReturningCustomers &&
            userInfo?.email !== '' &&
            userInfo?.phone !== ''
        ) {
            getReturningCustomerStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowReturningCustomers, userInfo?.email, userInfo?.phone])

    useEffect(() => {
        if (pageTab === '2') {
            setCurrentTab('2')
        }
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `VIEW_${pageTab === '1' ? 'RECENT_UPDATES' : 'ACTIVE_PLANS'}`,
            'KYC_CONSUMER_WORKSPACE_TABS',
            'KYC_CONSUMER_WORKSPACE',
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageTab])
    const { sseConsumerEstimationData, sseConsumerEstimationRefetch } =
        useSSERetrieveConsumerEstimation({
            onSuccess: data => {
                appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                    people_set: {
                        'Repayment setup type':
                            repaymentSetupMapping[
                                data[0]?.repayment_method_setup?.type
                            ],
                    },
                })
            },
        })
    const {
        sseConsumerEstimationSummaryLoading,
        sseConsumerEstimationSummaryData,
        sseConsumerEstimationSummaryRefetch,
    } = useSSERetrieveConsumerEstimationSummary({})

    const {
        isLoading: loadingUpdates,
        refetch: fetchAllUpdates,
        data: sseRecentUpdatesData,
    } = useQuery(
        ['getSSERecentUpdatesApi'],
        () => getSSERecentUpdatesApi(pageSize),
        {
            enabled: true,
            retry: false,
            refetchIntervalInBackground: true,
            onSuccess: data => {
                const filterRequiredUpdates = data?.data?.data?.filter(data => {
                    return data?.updated !== 'FULL'
                })
                setAllUpdates(filterRequiredUpdates)
                if (isSubscriptionPlan) {
                    setShowSubscriptionPlan({ primary: true, secondary: false })
                }
                if (isLeaseToOwnPlan) {
                    setShowisLeaseToOwnPlan({ primary: true, secondary: false })
                }
                if (isWithSuggestion) {
                    setShowisLeaseToOwnPlan({ primary: false, secondary: true })
                }
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(
                    'Failed to get recent updates. Try refreshing page',
                )
            },
        },
    )

    const { refetch: refetchUserProfile } = useQuery(
        'getUserProfile',
        () => getProfileInformationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setUserInfo(prev => ({
                    ...prev,
                    firstName: data?.data?.data?.first_name,
                    email: data?.data?.data?.email,
                    phone: data?.data?.data?.phone_number,
                    isEmailVerified: data?.data?.data?.email_verified,
                    isPhoneNumberVerified: data?.data?.data?.phone_verified,
                }))
            },
        },
    )

    const toggleRequestNewDealModal = () => {
        setShowRequestNewDealModal(prev => !prev)
    }

    useEffect(() => {
        if (history.location.state?.currentTab) {
            setCurrentTab(history.location.state.currentTab)

            // Clear the state
            history.replace({ ...history.location, state: undefined })
        }
    }, [history])

    useEffect(() => {
        fetchAllUpdates()
        sseConsumerEstimationRefetch()
        sseConsumerEstimationSummaryRefetch()
        refetchUserProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activePlans = formattedActivePlans(
        sseConsumerEstimationData?.data?.data,
    )

    // Use the estimation_id returned from the recent update to make necessary updates e.g. setting up a repayment method
    const estimationId =
        selectedEstimation ?? sseRecentUpdatesData?.data?.data[0]?.estimation_id

    const isPendingAgreementSigning =
        !!sseConsumerEstimationData?.data?.data?.find(
            data =>
                data.application_status === 'AGREEMENT_SIGNING' &&
                !data.agreement_signed_at,
        )

    const { refetchRepaymentMethodType } = useRetriveRepaymentMethodSetupType({
        estimationId: estimationId,
        enabled: !!estimationId,
        onSuccess: data => {
            setRepaymentMethodType(data?.repayment_method_type)
            setAccountDetails(data?.account_details)
        },
        onError: error => {
            errorAlert(error)
        },
    })

    const { mutateSendAgreementEmail } = useSendAgreementReminderEmail({})

    const handleSendAgreementReminder = () => {
        mutateSendAgreementEmail({
            estimationId,
        })
        setShowAgreementSigningConfirmationModal(true)
    }

    useEffect(() => {
        if (estimationId) {
            refetchRepaymentMethodType()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimationId])

    const CreditSummary = formatCardsData(
        sseConsumerEstimationSummaryData?.data?.data,
    )?.map((item, i) => (
        <div key={i} className={'ConsumerWorkspaceSummary_Card'}>
            <div>
                <div className={'ConsumerWorkspaceSummary_Card_Icon'}>
                    {item?.icon}
                </div>
                <h4>{item?.title}</h4>
                <p>
                    {sseConsumerEstimationSummaryLoading ? (
                        <Skeleton.Input active={true} style={{ height: 24 }} />
                    ) : (
                        <>
                            <NairaSymbol /> {item?.value}
                        </>
                    )}
                </p>
            </div>
        </div>
    ))

    // verify paystack direct debit
    const { refetch: verifyPaystackDirectDebitRefetch } = useQuery(
        'verify-paystack-direct-debit',
        () => verifyPaystackDirectDebitApi(estimationId),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setVerifyPaystackError(false)
                setShowVerifyPaystackModal(true)
            },
            onError: () => {
                setVerifyPaystackError(true)
                setShowVerifyPaystackModal(true)
            },
        },
    )

    // check if paystack direct debit has been initialized
    const paystackQueryParams = new URLSearchParams(history.location.search)
    const paystackInitialized = paystackQueryParams.get('paystack_initialized')

    useEffect(() => {
        if (paystackInitialized && estimationId?.length > 0)
            verifyPaystackDirectDebitRefetch()
        // eslint-disable-next-line
    }, [paystackInitialized, estimationId])

    return (
        <>
            <ActionModal
                actionModalOpen={showVerifyPaystackModal}
                title={`Repayment Setup ${
                    verifyPaystackError ? 'Failed' : 'Successful'
                }`}
                headerText={
                    verifyPaystackError
                        ? 'We couldn’t setup your repayment'
                        : "You've successfully setup your repayment"
                }
                subTitle={
                    verifyPaystackError
                        ? 'There was an error setting up your repayment. Please try again'
                        : "We're really excited to have you onboard. Your repayment schedule will be available within 24 hours"
                }
                onCancel={() => setShowVerifyPaystackModal(false)}
                handlePrimaryBtnClick={() => setShowVerifyPaystackModal(false)}
                actionType={verifyPaystackError ? 'delete' : 'success'}
                primaryBtnText={verifyPaystackError ? 'Okay, got it' : 'Done'}
                noCancelBtn={true}
                v2
                showFooter
            />

            <RequestNewDealModal
                showModal={showRequestNewDealModal}
                onCancel={toggleRequestNewDealModal}
            />

            <SeoComponent title="Consumer Workspace | SunFi | Simplifying and Scaling Clean Energy" />
            <DecisionIsYes
                showPaymentPlan={showisLeaseToOwnPlan}
                setShowPaymentPlan={setShowisLeaseToOwnPlan}
                showSubscriptionPlan={showSubscriptionPlan}
                setShowSubscriptionPlan={setShowSubscriptionPlan}
                updates={allUpdates}
                showPaystackModal={showPaystackModal}
                setShowPaystackModal={setShowPaystackModal}
                consumerFirstName={userInfo.firstName}
                isWithSuggestion={isWithSuggestion}
                estimationId={estimationId}
                refetchUpdates={() => {
                    if (isSubscriptionPlan) {
                        history.push({
                            pathname: '/consumer/workspace',
                            state: {
                                isSubscription: false,
                            },
                        })
                    }
                    fetchAllUpdates()
                    sseConsumerEstimationRefetch()
                    sseConsumerEstimationSummaryRefetch()
                }}
                consumerEmail={userInfo?.email}
                consumerPhoneNumber={userInfo?.phone}
                consumerEstimationData={sseConsumerEstimationData?.data?.data}
                accountDetails={accountDetails}
                repaymentMethodType={repaymentMethodType}
                showSubTenure={showSubTenure}
                setShowSubTenure={setShowSubTenure}
                isPendingAgreementSigning={isPendingAgreementSigning}
                showAgreementSigningConfirmationModal={
                    showAgreementSigningConfirmationModal
                }
                setShowAgreementSigningConfirmationModal={
                    setShowAgreementSigningConfirmationModal
                }
                setPaystackLoading={setPaystackLoading}
                isEmailVerified={userInfo.isEmailVerified}
                isPhoneNumberVerified={userInfo.isPhoneNumberVerified}
            />
            <div className={'ConsumerWorkspaceWrapper'}>
                <div className="ConsumerWorkspaceHeadingWrapper">
                    <h1 className={'ConsumerWorkspaceHeading'}>
                        Good {getGreetingTime()}
                        <span>, {userInfo.firstName}</span>
                    </h1>
                    {allowReturningCustomers &&
                        returningCustomerStatus === 'ACTIVE_OR_CLOSED_PLAN' && (
                            <ConsumerButton
                                btnTextColor={'#FFFFFF'}
                                btnBgColor={'#004AAD'}
                                btnWidth={'142px'}
                                btnHeight={'44px'}
                                handleClick={toggleRequestNewDealModal}
                            >
                                Request New Deal
                            </ConsumerButton>
                        )}
                </div>
                {isMobile ? (
                    <Carousel
                        className="ConsumerWorkspaceSummary_Mobile"
                        autoplay
                    >
                        {CreditSummary}
                    </Carousel>
                ) : (
                    <div className={'ConsumerWorkspaceSummary'}>
                        {CreditSummary}
                    </div>
                )}
                <div className={'ConsumerWorkspace_Tabs'}>
                    <Tabs
                        defaultActiveKey="1"
                        activeKey={currentTab}
                        onChange={values => {
                            setCurrentTab(values)
                            addQueryParamsToUrl({
                                tab: consumerWorkspaceTabs[values],
                            })
                        }}
                    >
                        <Tabs.TabPane tab="Recent Update" key="1">
                            {toastError && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}
                            <RecentUpdates
                                loading={loadingUpdates}
                                data={allUpdates}
                                activePlans={activePlans}
                                handleShowPaymentPlan={
                                    handleShowisLeaseToOwnPlan
                                }
                                handleSubscriptionPlan={
                                    handleShowSubscriptionPlan
                                }
                                handlePaystackModal={handlePaystackModal}
                                handleLeaseRepaymentSetup={
                                    handleLeaseRepaymentSetup
                                }
                                handleSendAgreementReminder={
                                    handleSendAgreementReminder
                                }
                                btnLoading={paystackLoading}
                                handleActiveEstimation={setSelectedEstimation}
                                activeEstimation={selectedEstimation}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Active Plans" key="2">
                            <ActivePlansTable
                                activePlans={activePlans}
                                consumerType="DTC"
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default ConsumerWorkspace
