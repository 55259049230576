import { useMutation, useQuery } from 'react-query'
import axios from 'src/config/axios'

export const updateUpfrontDeposit = async ({ payload, estimationId }) => {
    const res = await axios.post(
        `/consumers/estimations/${estimationId}/modify-upfront-deposit`,
        payload,
    )
    return res.data
}

export const adminUpdateUpfrontDeposit = async ({ payload, estimationId }) => {
    const res = await axios.post(
        `admin/sse-consumers/estimations/${estimationId}/upfront-deposit`,
        payload,
    )
    return res.data
}

export const getMinandMaxUpfrontDeposit = async estimationId => {
    const res = await axios.get(
        `/consumers/estimations/${estimationId}/max-min-upfront-deposit`,
    )
    return res.data
}

export const adminGetMinandMaxUpfrontDeposit = async estimationId => {
    const res = await axios.get(
        `/admin/sse-consumers/estimations/${estimationId}/max-min-upfront-deposit`,
    )
    return res.data
}

export const useGetMinMaxUpfront = (
    estimationId,
    options = {},
    admin = false,
) => {
    return useQuery({
        queryKey: ['min-max-deposit', estimationId],
        queryFn: () =>
            admin
                ? adminGetMinandMaxUpfrontDeposit(estimationId)
                : getMinandMaxUpfrontDeposit(estimationId),
        ...options,
    })
}

export const useUpdateUpfrontDeposit = (options = {}, admin = false) => {
    return useMutation({
        mutationFn: ({ payload, estimationId }) =>
            admin
                ? adminUpdateUpfrontDeposit({ payload, estimationId })
                : updateUpfrontDeposit({ payload, estimationId }),
        mutationKey: 'increase-upfront-deposit',
        ...options,
    })
}
