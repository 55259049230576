import axios from '../../../config/axios'

export const sseRetrieveBasicInfo = (estimationId = '') => {
    const request = axios.get(
        `/consumers/kyc/info?estimation_id=${estimationId}`,
    )
    return request
}

export const sseSubmitPersonalInfo = async payload => {
    const request = axios.post(`/consumers/kyc/personal-info`, payload)
    return request
}

export const sseSubmitEmploymentInfo = async payload => {
    const request = axios.post(`/consumers/kyc/employment-info`, payload)
    return request
}

export const sseVerifyConsumerBusiness = async payload => {
    const request = axios.post(`/consumers/kyc/verify-business`, payload)
    return request
}

export const getRequestedUpdatesApi = async () => {
    const request = axios.get(
        '/consumers/estimations/requested-updates?paginate=false',
    )
    return request
}

export const removeKYCFileApi = async payload => {
    const request = axios.post('/consumers/kyc/remove-file', payload)
    return request
}

export const onePageSubmitConsumerInfo = async ({ data, type }) => {
    const request = axios.post(
        `/consumers/onepagedkyc/${
            type?.toLowerCase() === 'business' ? 'business' : 'residential'
        }-information`,
        data,
    )
    return request
}

export const onePageSubmitAdminInfo = async ({ data, type, estimationID }) => {
    const request = axios.post(
        `/admin/one-page/sse-consumers/estimations/${estimationID}/${
            type?.toLowerCase() === 'business' ? 'business' : 'residential'
        }`,
        data,
    )
    return request
}
