import 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/consumerkyc.scss'
import DynamicInformationV2Nav from './components/Nav'
import DynamicInformationV2ContextProvider from './context'
import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import SideContent from './components/SideContent'
import MainContent from './components/MainContent'
import SeoComponent from 'src/components/Seo'
import { pageTitle } from './utils'

const DynamicInformationV2Content = () => {
    return (
        <div className="ConsumerKYC">
            <SeoComponent title={pageTitle} />
            <DynamicInformationV2Nav />
            <div className="KYCContainer">
                <div className="KYCContent">
                    <SideContent />
                    <MainContent />
                </div>
            </div>
            <AccountSetupFooter />
        </div>
    )
}

const DynamicInformationV2 = () => {
    return (
        <DynamicInformationV2ContextProvider>
            <DynamicInformationV2Content />
        </DynamicInformationV2ContextProvider>
    )
}

export default DynamicInformationV2
