/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
    InputFields,
    SelectField,
    TextArea,
} from '../../../../components/InputFields'
import LabelTitle from '../../../../components/LabelTitle'
import FormTitleBar from '../../../../components/FormTitleBar'
import { ActionSection } from '../../index'
import FormSubBox from '../../FormSubBox'
import { PersonalInfoValidationSchema } from '../../../../utils/validationSchema'
import {
    updateAiInformationApi,
    getAiInformationApi,
} from '../../../../api/associateinstaller/index.js'
import Toast from '../../../../components/Toast'

import { localGovtData, stateData } from '../../../../utils/stateData'
import { qualificationData } from './data'

import styles from './personalinfo.module.scss'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const { AddressBlock, SelectBox, FormBox } = styles

const PersonalInfo = ({ nextHandler, prevHandler }) => {
    const pageTitle = 'SunFi - Onboarding | Personal Information'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Onboarding'
    const [inputs, setInputs] = useState({
        address: '',
        city: '',
        state: '',
        bio: '',
        qualification: '',
    })
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [actionLoading, setActionLoading] = useState(false)

    const { isMobile } = useMediaQueries()

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({
                ...prev,
                [name]:
                    name === 'address'
                        ? 'Please fill in your street address'
                        : name === 'city'
                        ? 'Please select LGA'
                        : name === 'state'
                        ? 'Please select your state'
                        : name === 'bio'
                        ? 'Please tell us about yourself'
                        : 'Please select your highest qualification',
            }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleOnBlur = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({
                ...prev,
                [name]:
                    name === 'address'
                        ? 'Please fill in your street address'
                        : name === 'city'
                        ? 'Please select LGA'
                        : name === 'state'
                        ? 'Please select your state'
                        : name === 'bio'
                        ? 'Please tell us about yourself'
                        : 'Please select your highest qualification',
            }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectBlur = name => {
        const value = inputs[name]

        if (value === '') {
            setErrors(prev => ({
                ...prev,
                [name]:
                    name === 'city'
                        ? 'Please select LGA'
                        : name === 'city'
                        ? 'Please select your state'
                        : 'Please select your highest qualification',
            }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectChange = (name, value) => {
        const update = { ...inputs, [name]: value }
        const errorsUpdate = { ...errors, [name]: '' }

        if (name === 'state') {
            update.city = ''

            if (inputs.city) {
                errorsUpdate.city = 'Please select LGA'
            }
        }

        setInputs(update)
        setErrors(errorsUpdate)
    }

    const nextStageHandler = () => {
        setToastError(false)
        setErrors({})

        PersonalInfoValidationSchema.validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                setActionLoading(true)
                refetch()
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['onboardingPersonalInformation'].action,
                    eventTrackers['onboardingPersonalInformation'].label,
                    eventTrackers['onboardingPersonalInformation'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {
                            street_address: inputs.address,
                            city: inputs.city,
                            state_of_residence: inputs.state,
                            bio: inputs.bio,
                            education_level: inputs.qualification,
                        },
                    },
                )
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { refetch } = useQuery(
        'updateAiInformation',
        () =>
            updateAiInformationApi({
                street_address: inputs.address,
                city: inputs.city,
                state_of_residence: inputs.state,
                bio: inputs.bio,
                education_level: inputs.qualification,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                nextHandler()
                setActionLoading(false)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to update your information. Try Again')
                setActionLoading(false)
            },
        },
    )

    const { refetch: getInfoRefetch } = useQuery(
        'getAiInformation',
        () => getAiInformationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setInputs(preState => ({
                    ...preState,
                    address: data?.data?.data?.street_address ?? '',
                    city: data?.data?.data?.city ?? '',
                    state: data?.data?.data?.state_of_residence ?? '',
                    bio: data?.data?.data?.bio ?? '',
                    qualification: data?.data?.data?.education_level ?? '',
                }))
                setErrors({})
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to get your information. Refresh Page')
            },
        },
    )

    useEffect(() => {
        setToastError(false)
        getInfoRefetch()

        // eslint-disable-next-line
    }, [])

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            nextStageHandler()
        }
    }

    return (
        <>
            <div className={FormBox}>
                <FormSubBox>
                    <FormTitleBar
                        title="Personal Information"
                        subtitle="Fill your personal information below"
                    />
                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    <div>
                        <LabelTitle title="Full Address" />
                        <InputFields
                            inputValue={inputs.address}
                            value={inputs.address}
                            handleChange={handleInputChange}
                            name="address"
                            title="Street Address"
                            type="text"
                            errorMessage={errors?.address}
                            onBlur={handleOnBlur}
                        />
                        <div className={AddressBlock}>
                            <div className={SelectBox}>
                                <SelectField
                                    values={stateData.map(option => ({
                                        value: option,
                                    }))}
                                    value={inputs?.state}
                                    currentSelected={inputs.state}
                                    handleChange={handleSelectChange}
                                    onBlur={() => handleSelectBlur('state')}
                                    name="state"
                                    initialOption={inputs.state || 'State'}
                                    selectedValue={inputs.state}
                                    withCheckBox={true}
                                    errorMessage={errors?.state}
                                    showSearch
                                    floatingLabel={inputs.state ? 'State' : ''}
                                />
                            </div>
                            <span></span>
                            <div>
                                <SelectField
                                    initialOption="Local Government Area"
                                    values={localGovtData[inputs.state]?.map(
                                        option => ({
                                            value: option,
                                        }),
                                    )}
                                    value={inputs?.city}
                                    marginBottom={isMobile ? '16px' : ''}
                                    floatLabelOnSelectedValue
                                    name="city"
                                    selectWidth="100%"
                                    disabled={inputs.state?.length < 1}
                                    handleChange={handleSelectChange}
                                    onBlur={() => handleSelectBlur('city')}
                                    errorMessage={errors?.city}
                                    showSearch
                                    dropdownPositionRelative
                                    withCheckBox
                                    currentSelected={inputs.city}
                                    selectedValue={inputs.city}
                                    floatingLabel={
                                        inputs.city
                                            ? 'Local Government Area'
                                            : ''
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ margin: '20px 0' }}>
                        <LabelTitle title="Short Bio" />
                        <TextArea
                            value={inputs.bio}
                            name="bio"
                            title="Describe yourself briefly"
                            rows={10}
                            height="150px"
                            width="100%"
                            handleChange={handleInputChange}
                            errorMessage={errors?.bio}
                        />
                    </div>

                    <div style={{ margin: '20px 0' }}>
                        <LabelTitle title="Professional Info" />
                        <div className={SelectBox}>
                            <SelectField
                                values={qualificationData}
                                value={inputs.qualification}
                                currentSelected={inputs.qualification}
                                handleChange={handleSelectChange}
                                name="qualification"
                                initialOption={
                                    inputs.qualification ||
                                    'What’s your highest qualification'
                                }
                                selectedValue={inputs.qualification}
                                selectWidth="100%"
                                withCheckBox={true}
                                errorMessage={errors?.qualification}
                                floatingLabel={
                                    inputs.qualification
                                        ? 'What’s your highest qualification'
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </FormSubBox>
            </div>
            <ActionSection
                stage={1}
                nextHandler={nextStageHandler}
                prevHandler={prevHandler}
                loading={actionLoading}
                onKeyUp={handleKeyPress}
            />
        </>
    )
}

export default PersonalInfo
