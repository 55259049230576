import axios from '../../../config/axios'

export const getAdminCreditVariablesApi = async (
    page,
    paymentModel = '',
    searchText = '',
    startDate = '',
    endDate = '',
    startLowestTunure = '',
    endLowestTenure = '',
    isGlobal = false,
    paginate = true,
    variable_type = '',
) => {
    const request = await axios.get(
        !paginate
            ? `/credit/credit-variables?paginate=${paginate}&payment_model=${
                  paymentModel === 'All' ? '' : paymentModel
              }&is_global=${isGlobal}&variable_type=${variable_type}`
            : `/credit/credit-variables?page=${page}&payment_model=${
                  paymentModel === 'All' ? '' : paymentModel
              }&provider_name=${searchText}&start_date=${startDate}&end_date=${endDate}&start_lowest_tenure_annual_interest=${startLowestTunure}&end_lowest_tenure_annual_interest=${endLowestTenure}&is_global=${isGlobal}&variable_type=${variable_type}`,
    )
    return request
}

export const getGlobalCreditVariablesApi = async () => {
    const request = await axios.get(`/credit/credit-variables?is_global=true`)
    return request
}

export const getProvidersApi = async (
    paymentModelType,
    order_by = '',
    fields = '',
) => {
    const paymentModel = `${
        paymentModelType === '' ? '' : `&${paymentModelType}=false`
    }`
    const request = await axios.get(
        `/admin/provider?status=APPROVED${paymentModel}&order_by=${order_by}&paginate=false&fields=${fields}`,
    )
    return request
}

export const getSunFiPackagesApi = async () => {
    const request = await axios.get(
        `/admin/packages/sunfi-packages?paginate=false`,
    )
    return request
}

export const handleBulkUpdateCreditVariablesApi = async payload => {
    const request = await axios.patch(
        `credit/credit-variables/update-multiple-credit-variables`,
        payload,
    )
    return request
}
