import axios from '../../config/axios'

export const initializePaymentApi = async dataObj => {
    const request = await axios.post(`/payments/initialize-payment`, dataObj)

    return request
}

export const initializeProviderPaymentApi = async dataObj => {
    const request = await axios.post(
        `/provider/payments/initialize-payment`,
        dataObj,
    )

    return request
}

export const initializeConsumerOutrightSalePaymentApi = async dataObj => {
    const request = await axios.post(
        '/payments/initialize-outright-sale-payment',
        dataObj,
    )
    return request
}

export const verifyPaymentApi = async dataObj => {
    const request = await axios.post(`/payments/verify-deposit`, dataObj)

    return request
}

export const verifyOutrightSalePaymentApi = async dataObj => {
    const request = await axios.post(
        '/payments/verify-outright-sale-payment',
        dataObj,
    )

    return request
}

export const fetchCardDetailsApi = async id => {
    const request = await axios.post(`/payments/fetch-card-detail`, {
        estimation_id: id,
    })

    return request
}

export const payWithExistingCardApi = async (id, cardFeedbackType) => {
    const request = await axios.post(
        `/payments/repayment-method-setup${
            cardFeedbackType ? `?type=${cardFeedbackType}` : ''
        }`,
        {
            estimation_id: id,
        },
    )
    return request
}

export const cancelPaystackPaymentApi = async dataObj => {
    const request = await axios.post('/payments/cancel', dataObj)
    return request
}

export const saveCardApi = async ({ save_card, reference }) => {
    const request = await axios.post('/payments/save-card', {
        save_card,
        reference,
    })
    return request
}
