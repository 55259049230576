import { format, parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ButtonComponent from 'src/components/Button'
import styles from '../alternateRecommListings.module.scss'
import { getAltFullName, reverseStatusMap } from '../utils'
import { permissionsControl } from 'src/utils/permissionsControl'

const PlansTable = ({ data }) => {
    const history = useHistory()
    const columnHeaders = [
        'Consumer Name',
        'Status',
        'Email address/Phone number',
        'Date Created',
    ]

    const canViewAlternativeRecommendationDetails = permissionsControl([
        'can_view_consumer_alternative_detail',
    ])

    const handleNavigation = planId => {
        history.push(`/admin/alternative-recommendations/selected/${planId}`)
    }

    return (
        <>
            <div className={styles.alternate__mobile}>
                {data?.map((each, i) => (
                    <div className={styles.alternate__mobile__card} key={i}>
                        <div>
                            <div>
                                <h4>Email address/Phone number</h4>
                                <p>{`${each?.email ?? each?.phone_number}`}</p>
                            </div>
                            <ButtonComponent
                                type={'Xsmall'}
                                btnBgColor={'#e2eeff'}
                                btnTextColor={'#004AAD'}
                                btnHeight={'53px'}
                                btnWidth={'79px'}
                                handleClick={() => handleNavigation(each?.id)}
                                disabled={
                                    !canViewAlternativeRecommendationDetails
                                }
                                showTooltip={
                                    !canViewAlternativeRecommendationDetails
                                }
                                text="You are not authorised to perform this action"
                            >
                                View
                            </ButtonComponent>
                        </div>
                        <div>
                            <div>
                                <h4>STATUS</h4>
                                <p>{reverseStatusMap[each.medium].label}</p>
                            </div>
                            <div>
                                <h4>DATE CREATED</h4>
                                <p style={{ fontSize: '12px' }}>
                                    {each?.created_at !== undefined &&
                                        format(
                                            parseISO(each?.created_at),
                                            'dd MMM yyyy, HH:mm',
                                        )}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.planTable}>
                <table>
                    <thead>
                        <tr>
                            {columnHeaders?.map((each, i) => (
                                <th key={i}>{each}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((each, i) => (
                            <tr
                                className={styles.planTable__row}
                                key={i}
                                onClick={() =>
                                    canViewAlternativeRecommendationDetails &&
                                    handleNavigation(each?.id)
                                }
                                style={{
                                    pointerEvents:
                                        canViewAlternativeRecommendationDetails
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <td>{getAltFullName(each)}</td>

                                <td>
                                    <p
                                        className={
                                            styles[
                                                reverseStatusMap[each.medium]
                                                    .class
                                            ]
                                        }
                                    >
                                        {reverseStatusMap[each.medium].label}
                                    </p>
                                </td>

                                <td>
                                    {`${each?.email ?? each?.phone_number}`}
                                </td>

                                <td>
                                    {each?.created_at !== undefined &&
                                        format(
                                            parseISO(each?.created_at),
                                            'dd MMM yyyy, HH:mm',
                                        )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

PlansTable.propTypes = {
    data: PropTypes.array,
}

export default PlansTable
