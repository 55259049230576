import { useEffect, useState } from 'react'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateClusterMemberInfo } from 'src/utils/validationSchema'
import { scrollToById } from 'src/utils/scrollToById'
import { useMutation } from 'react-query'
import { downloadCSVSample } from 'src/api/representative/members'

const useAddMembersModal = (
    onCancel,
    handleHoldNewMember,
    storeActionAndOpenModal,
    storeActionAndOpenUploadModal,
) => {
    const [method, setMethod] = useState('') // single | multiple
    const [action, setAction] = useState('select-method') // select-method | single-member-personal-info | 'single-member-id-info'
    const [standardHeight, setStandardHeight] = useState(
        process.env.REACT_APP_FLAG_SHOW_ADD_MULTIPLE_MEMBERS_OPTIONS !== 'true'
            ? 400
            : 523,
    )
    const [modalTitle, setModalTitle] = useState(
        'Add all members in your cluster',
    )
    const [clusterMemberUpload, setClusterMemberUpload] = useState([])
    const inputsValue = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        idCardType: '',
        idCardNo: '',
    }
    const [inputs, setInputs] = useState(inputsValue)
    const [errors, setErrors] = useState({})
    const validateField = useValidateOnBlur()
    const [sampleCSVFile, setSampleCSVFile] = useState({})

    const schemaContext = {
        personalInfoForm: action === 'single-member-personal-info',
        idInfoForm: action === 'single-member-id-info',
        idCardType: inputs?.idCardType,
    }

    const handleSelectMethod = key => {
        setMethod(key)
    }

    const closeModal = () => {
        setAction('select-method')
        setMethod('')
        setStandardHeight(523)
        setModalTitle('Add all members in your cluster')
        setInputs(inputsValue)
        onCancel()
    }

    const handleCloseUploadModal = () => {
        setAction('select-method')
    }

    const handleSingleMemberInfoClick = () => {
        validateClusterMemberInfo
            .validate(inputs, {
                context: schemaContext,
                abortEarly: false,
            })
            .then(() => {
                if (
                    process.env
                        .REACT_APP_FLAG_HIDE_ADD_CLUSTER_MEMBER_ID_MODAL ===
                    'true'
                ) {
                    if (action === 'single-member-personal-info') {
                        handleHoldNewMember({
                            ...inputs,
                            phone_number: formatPhoneNumber(
                                inputs.phone_number,
                            ),
                        })
                        closeModal()
                    }
                } else {
                    if (action === 'single-member-personal-info') {
                        setAction('single-member-id-info')
                        setStandardHeight(430)
                    }
                    if (action === 'single-member-id-info') {
                        const { idCardNo, idCardType, ...allInputs } = inputs
                        handleHoldNewMember({
                            ...allInputs,
                            phone_number: formatPhoneNumber(
                                allInputs.phone_number,
                            ),
                            number: idCardNo,
                            type: idCardType,
                        })
                        closeModal()
                    }
                }

                scrollToById('Rep_AddMembersContainer')
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleClick = () => {
        switch (action) {
            case 'select-method':
                if (method === 'single') {
                    setAction('single-member-personal-info')
                    setStandardHeight(625)
                    setModalTitle('Add single members')
                } else if (method === 'multiple') {
                    setAction('show-multiple-members-csv-file')
                }
                break
            case 'single-member-personal-info':
                handleSingleMemberInfoClick()
                break
            case 'single-member-id-info':
                handleSingleMemberInfoClick()
                break
            case 'show-multiple-members-csv-file':
                onCancel()
                setAction('select-method')
                setMethod('')
                storeActionAndOpenUploadModal('upload-multiple-group-members')
                break
        }
    }

    const downloadSampleCSV = useMutation({
        mutationFn: () => downloadCSVSample(),
        onSuccess: res => {
            const data = res?.data?.data
            if (data) setSampleCSVFile(data)
        },
    })

    const handleSampleCSVdownload = () => {
        window.location.href = sampleCSVFile.url
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateClusterMemberInfo,
            setErrors,
            context: schemaContext,
        })
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateClusterMemberInfo,
            setErrors,
            context: schemaContext,
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    useEffect(() => {
        if (action === 'show-multiple-members-csv-file') {
            downloadSampleCSV.mutate()
        }
        return
        // eslint-disable-next-line
    }, [action])

    return {
        method,
        action,
        handleSelectMethod,
        handleClick,
        standardHeight,
        closeModal,
        modalTitle,
        inputs,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
        handleCloseUploadModal,
        clusterMemberUpload,
        setClusterMemberUpload,
        handleSampleCSVdownload,
        sampleCSVFile,
    }
}

export default useAddMembersModal
