/* eslint-disable react/prop-types */
import styles from '../recentupdate.module.scss'
import SelectEnergyIcon from 'src/assets/images/selectEnergyIcon.svg'
import { InlineLoader } from 'src/components/Loader'
import NairaSymbol from 'src/components/NairaSymbol'

const RecommendedSolutionUpdate = ({
    page,
    btnText,
    handleShowModal,
    note,
    isFetching,
    isAmount = false,
    v2 = false,
}) => {
    const {
        SelectSolution,
        BtnStyle,
        BtnStyleV2,
        SelectEnergy,
        SelectEnergyImg,
        SelectEnergyTextWrapper,
        SelectEnergyTextWrapperMobile,
        SelectEnergyBoldText,
        SelectEnergyText,
        SelectSolutionV2,
    } = styles

    return (
        <div className={`${SelectSolution} ${v2 ? SelectSolutionV2 : ''}`}>
            <div className={SelectEnergy}>
                {!v2 && (
                    <div className={SelectEnergyImg}>
                        <img src={SelectEnergyIcon} />
                    </div>
                )}
                <div className={SelectEnergyTextWrapper}>
                    <p className={SelectEnergyBoldText}>
                        {page === 'upfrontDeposit'
                            ? 'Upfront Deposit'
                            : page === 'agreementSigning'
                            ? 'Sign your agreement'
                            : page === 'personalInformation'
                            ? 'Personal Information'
                            : page === 'creditInformation'
                            ? 'Credit Information'
                            : page === 'energyInformation'
                            ? 'Energy Information'
                            : page === 'repaymentSetup'
                            ? 'Setup Repayment Method'
                            : page === 'select_payment_plan'
                            ? 'Select preferred payment plan'
                            : page === 'subscription_payment'
                            ? 'Setup Repayment Method'
                            : 'Select Energy Solutions'}
                    </p>
                    <p className={SelectEnergyText}>
                        {isAmount ? <NairaSymbol /> : null}
                        {note}
                    </p>
                </div>
                <div
                    className={SelectEnergyTextWrapperMobile}
                    onClick={handleShowModal}
                >
                    <p className={SelectEnergyBoldText}>
                        {page === 'upfrontDeposit'
                            ? 'Upfront Deposit'
                            : page === 'agreementSigning'
                            ? 'Sign your agreement'
                            : page === 'personalInformation'
                            ? 'Personal Information'
                            : page === 'creditInformation'
                            ? 'Credit Information'
                            : page === 'energyInformation'
                            ? 'Energy Information'
                            : page === 'repaymentSetup'
                            ? 'Setup Repayment Method'
                            : page === 'select_payment_plan'
                            ? 'Select preferred payment plan'
                            : page === 'subscription_payment'
                            ? 'Setup Repayment Method'
                            : 'Select Energy Solutions'}
                    </p>
                    <p className={SelectEnergyText}>
                        {isAmount ? <NairaSymbol /> : null}
                        {note}
                    </p>
                </div>
            </div>
            <button
                className={`${BtnStyle} ${v2 ? BtnStyleV2 : ''}`}
                onClick={handleShowModal}
                disabled={isFetching}
                style={{
                    cursor: isFetching ? 'not-allowed' : '',
                    background: isFetching ? '#e2eeff' : '',
                }}
            >
                {isFetching ? (
                    <InlineLoader />
                ) : btnText === 'unified_workspace' ? (
                    'Go to Plan Details'
                ) : page === 'request-details' ? (
                    'Review Selection'
                ) : page === 'upfrontDeposit' ? (
                    'Pay Now'
                ) : page === 'repaymentSetup' ? (
                    'Setup Repayment Method'
                ) : page === 'select_payment_plan' ? (
                    'Select Plan'
                ) : page === 'subscription_payment' ? (
                    'Setup now'
                ) : btnText === 'agreementSigning' ? (
                    'I already signed it'
                ) : (
                    'Select Solution'
                )}
            </button>
        </div>
    )
}

export default RecommendedSolutionUpdate
