import { appTracking } from 'src/utils/appTracker'
import { consumerBuildCustomSystemEvents } from 'src/utils/mp-event-tracker/consumer-build-custom-system'

export const pageUrlName = window.location.pathname
export const pageTitle =
    'View System | SunFi | Simplifying and Scaling Clean Energy'
export const pageTracker = 'VIEW_SOLAR_SYSTEM'

const handleAppTracking = event => {
    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        consumerBuildCustomSystemEvents[event].action,
        consumerBuildCustomSystemEvents[event].label,
        consumerBuildCustomSystemEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}

export const handleCustomBuildViewCountTracking = packageViewCount => {
    if (packageViewCount > 10) {
        handleAppTracking('viewedGreaterThanTenPackages')
    } else if (packageViewCount >= 9) {
        handleAppTracking('viewedTenPackages')
    } else if (packageViewCount >= 7) {
        handleAppTracking('viewedEightPackages')
    } else if (packageViewCount >= 5) {
        handleAppTracking('viewedSixPackages')
    } else if (packageViewCount >= 3) {
        handleAppTracking('viewedFourPackages')
    } else if (packageViewCount >= 1) {
        handleAppTracking('viewedTwoPackages')
    }
}

export const getStartedTrackingForCustomBuild = isCustomBuild => {
    if (isCustomBuild) {
        handleAppTracking('clicksGetStartedOnPackage')
    }
}

export const secondaryModalActions = (action, isSystemOverloaded) => {
    switch (action) {
        case 'system-confirmation':
            return {
                modalWidth: '475px',
                modalTitle: isSystemOverloaded
                    ? 'Reduce your added appliances'
                    : 'Powering essentials updated',
                modalHeight: '525px',
                primaryBtnText: isSystemOverloaded ? 'Go Back' : 'Get started',
                secondaryBtnText: isSystemOverloaded ? 'Undo Changes' : null,
                footerLeftContent: isSystemOverloaded ? 'button' : 'chat',
            }
        case 'edit-appliances':
            return {
                modalWidth: '624px',
                modalTitle: 'Tell us your energy needs',
                modalHeight: '687px',
                primaryBtnText: null,
                secondaryBtnText: null,
                footerLeftContent: null,
            }
        case 'view-aftercare-rating':
            return {
                modalWidth: '480px',
                modalTitle: 'Aftercare Rating',
                modalHeight: '590px',
                primaryBtnText: 'Great, Thank you',
                secondaryBtnText: null,
                footerLeftContent: 'chat',
            }
    }
}

export const getAfterCareRatingNotes = data => {
    if (!data || !data?.notes) return []

    const noteTypes = [
        {
            title: 'Quality Note',
            key: 'quality_note',
        },
        {
            title: 'Speed Note',
            key: 'speed_note',
        },
        {
            title: '5 year localization & capitalization likelihood note',
            key: 'local_support_likelihood_note',
        },
        {
            title: 'Remote Monitoring Score',

            key: 'remote_monitoring_note',
        },
    ]

    return noteTypes.map(({ title, key }) => ({
        name: title,
        note: data?.notes[key],
    }))
}
