import axios from '../../config/axios'

export const emailVerificationApi = async inputs => {
    const request = await axios.post(
        '/auth/email-verification/validate',
        inputs,
    )
    return request
}

export const resendEmailVerification = async inputs => {
    const request = await axios.post(
        '/auth/email-verification/initiate',
        inputs,
    )
    return request
}
export const emailOTPValidationApi = async inputs => {
    const request = await axios.post(
        '/auth/email-otp-verification/validate',
        inputs,
    )
    return request
}
